export namespace SharedConsts {
  export const requestRetryCount = 2;
  export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoidmFsZXJpaS1oIiwiYSI6ImNsNzRyYWxxMTAzNXczcGthazRzNjc1cjIifQ.5e0PWRdJWOqwijAaLTSY4w';
  export const mapStyle = 'mapbox://styles/mapbox/streets-v12';
  export const mliDashboardIframeUrl =
    'https://viewer-uk.smartsteps.telefonica.com/t/UK/views/MicroLocationv5_16467476828090/ExecutiveSummary?:embed=y&:display_count=n&:showAppBanner=false&:origin=viz_share_link&:showVizHome=n#2';

  export const railInsightsDashboardIframeUrl =
    'https://viewer-uk.smartsteps.telefonica.com/t/UK/views/si_prod-main/TripAnalytics?:embed=y&:display_count=n&:showAppBanner=false&:origin=viz_share_link&:showVizHome=n#2';

  export const roadInsightDashboardIframeUrl =
    'https://viewer-uk.smartsteps.telefonica.com/#/site/UK/views/ri_pro-link-main_16623936871250/TripsAnalytics?:embed=y&:display_count=n&:showAppBanner=false&:origin=viz_share_link&:showVizHome=n#2';

  export const roadInsightsRoutesDashboardIframeUrl =
    'https://viewer-uk.smartsteps.telefonica.com/#/site/UK/views/ri_pro-route-main_16673250087900/TripsAnalytics?:embed=y&:display_count=n&:showAppBanner=false&:origin=viz_share_link&:showVizHome=n#2';

  export const roadInsightsLinksDashboardIframeUrl =
    'https://viewer-uk.smartsteps.telefonica.com/#/site/UK/views/ri_pro-link-viewer/LinkViewer?:embed=y&:display_count=n&:showAppBanner=false&:origin=viz_share_link&:showVizHome=n#2';

  export const siDashboardIframeUrl = 'https://si.o2motion.co.uk/login?connection=O2-Motion';
}
