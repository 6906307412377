import { Navigate, Route, Routes } from 'react-router-dom';
import { Breadcrumb, PageHeader } from 'shared/ui';
import { links } from 'shared/constants';
import { Link } from '@mui/material';
import { useUserHasTableauAccess } from 'auth/lib/useUserHasTableauAccess';

import { MLIOverview } from './components/overview/MLIOverview';
import { MLIDashboard } from './components/dashboard/MLIDashboard';
import { MLISpecification } from './components/specifications/MLISpecification';

import { MicroLocationInsightsStyles } from './MicroLocationInsights.styles';
import headerImage from 'assets/images/mli/header-image.png';

const MicroLocationInsights = () => {
  const hasTableauAccess = useUserHasTableauAccess();
  const menu = [
    { label: 'Product Overview', link: 'overview' },
    { label: 'Data Dashboard', link: 'dashboard' },
    { label: 'Specifications', link: 'specifications' },
  ];

  return (
    <MicroLocationInsightsStyles>
      <PageHeader image={headerImage} menus={menu}>
        <h1>{links.products.pages.microLocationInsights.label}</h1>
        <p>
          Micro Location Insights gives you local, location-based insights on <br /> visitors so you can improve performance measurement.
        </p>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.products.link}>
          {links.products.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.products.pages.microLocationInsights.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <Routes>
        <Route path="overview" element={<MLIOverview />} />
        <Route path="dashboard/*" element={<MLIDashboard hasTableauAccess={hasTableauAccess} />}></Route>
        <Route path="specifications" element={<MLISpecification />} />
        <Route path="" element={<Navigate to="overview" replace={true} />} />
      </Routes>
    </MicroLocationInsightsStyles>
  );
};

export default MicroLocationInsights;
