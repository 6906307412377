import styled from 'styled-components';

export const DataPrivacyStyles = styled.section`
  padding-top: 10vh;

  hgroup {
    h1 {
      text-align: center;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.02em;
      color: #0019a5;
    }
  }

  article {
    width: 954px;
    margin: 100px auto;

    h2 {
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #0019a5;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #575757;
    }
  }
`;
