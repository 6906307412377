export namespace Helpers {
  export function preventEvent(e: { preventDefault: Function }) {
    e.preventDefault();
  }

  export function saveFile(fileName: string, content: Blob | ReadableStream): Promise<void> {
    if (content instanceof ReadableStream) {
      return new Promise((resolve, reject) => {
        const chunks: string[] = [];
        content.pipeTo(
          new WritableStream({
            write(chunk: string) {
              chunks.push(chunk);
            },
            close() {
              saveFile(fileName, new Blob(chunks));
              chunks.length = 0;
              resolve();
            },
            abort() {
              reject(new Error('Aborted'));
              chunks.length = 0;
            },
          }),
        );
      });
    } else {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = fileName;
      link.click();
      return Promise.resolve();
    }
  }

  export function flatStream<T>(stream: ReadableStream<T[]>): Promise<T[]> {
    const data: T[][] = [];
    return new Promise((resolve, reject) => {
      const writable = new WritableStream<T[]>({
        write: (chunk) => data.push(chunk) as any,
        close: () => resolve(data.flat()),
        abort: () => reject(new Error('Aborted)')),
      });
      stream.pipeTo(writable);
    });
  }
}
