import React, { HTMLAttributes } from 'react';
import { SpecificationStyles } from './Specification.styles';

interface SpecificationProps extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element[] | JSX.Element;
}

export const Specification = (props: SpecificationProps) => {
  return (
    <SpecificationStyles className={props.className} style={props.style}>
      {props.children}
    </SpecificationStyles>
  );
};
