import { useEffect } from 'react';
import { PageContent } from 'shared/ui';
import { DataPrivacyStyles } from './DataPrivacy.styles';

export const DataPrivacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DataPrivacyStyles>
      <PageContent>
        <hgroup>
          <h1>How we protect the privacy of our network users</h1>
        </hgroup>
        <article>
          <h2>
            Movement Insights, Rail Insights, Road Insights, Real-Time Location Insights, Real-Time Travel Insights, Origin & Destination
            Insights, Micro-Location Insights
          </h2>
          <p>
            We take the privacy and data protection rights of our mobile network users seriously. The data powering our O<sub>2</sub> Motion
            insights is collected as part of providing our network services and processed in compliance with data protection and privacy
            laws.
          </p>
          <p>
            All the insight provided within our data solutions is anonymised with the identity of the end user removed. We then aggregate
            any processed data and expand it to fairly represent the entire UK population before providing anonymised and aggregated outputs
            to you.
          </p>
          <p>
            And we go one step further by applying disclosure control rules whereby outputs below the number of 10 are not shared with you
            to exclude any remote opportunity of a user to be identified.
          </p>
          <p>
            These safeguards alongside additional controls mean that, by design, the O<sub>2</sub> Motion insights do not include personal
            data or breach the data protection and privacy rights of end users. It also means that our anonymised outputs cannot be
            reverse-engineered to identify individual users.
          </p>
        </article>
        <article>
          <h2>Spatial Insights</h2>
          <p>
            Spatial Insights uses your own CCTV cameras to provide insights on how your customers and personnel use the space in your
            venues. The process of analysing and providing Spatial Insights is compliant with applicable laws. We take security seriously,
            by processing the video footage on your premise rather than in a Cloud or remote server. We also respect the privacy of
            individuals and deliver anonymised insights with all faces blurred.
          </p>
        </article>
      </PageContent>
    </DataPrivacyStyles>
  );
};
