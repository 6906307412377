import { Link } from '@mui/material';
import { PageContent, PageHeader, IconCart, LandscapeCart, ButtonLink, Breadcrumb } from 'shared/ui';
import { InternationalPhone, Spend, API, Portal, Stand, Next, Search } from 'shared/icons';
import { links } from 'shared/constants';
import { TransportLogisticsStyles } from './TransportLogistics.styles';

import greyBackground from 'assets/images/transport-logistics/grey-background.png';
import headerImage from 'assets/images/transport-logistics/header-image.png';
import originDestinationInsights from 'assets/images/transport-logistics/origin-destination-insights.png';
import realTimeTravelInsights from 'assets/images/transport-logistics/real-time-travel-insights.png';
import roadInsights from 'assets/images/transport-logistics/road-travel-insights.png';
import spatialInsights from 'assets/images/retail/spatial-insights.png';
import railInsights from 'assets/images/transport-logistics/rail-insights.png';

export const TransportLogistics = () => {
  return (
    <TransportLogisticsStyles>
      <PageHeader image={headerImage}>
        <h1>{links.industries.pages.transportLogistics.label}</h1>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.industries.link}>
          {links.industries.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.industries.pages.transportLogistics.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <PageContent className="description">
        <hgroup>
          <h1>Improve transport planning and modelling with insights on the movements of people around the UK</h1>
        </hgroup>
        <article>
          <p>
            With O<sub>2</sub> Motion you can see how people move around the UK and how they behave. With this extensive movements data, you
            can make informed decisions on infrastructure, capacity planning and better predict passenger volumes. You can also optimise
            your network in real-time. You’ll be able to segment by specific demographics too, including affluence, interests and digital
            use. You can use these insights to better address the needs of a range of audiences, and benchmark performance against competing
            schemes.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Spend />}>
            <h1>Better ROI for infrastructure Initiatives</h1>
            <p>Benefit from a better understanding of how your transport network is used to improve modelling.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Easy to adopt</h1>
            <p>Data can be accessed via API, Blob storage or our Portal, for easy integration into your workflows.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>Our solutions are readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Large scale</h1>
            <p> Our data is obtained from 35% of the UK population and expanded to fairly represent the entirety of the UK’s population.</p>
          </IconCart>
          <IconCart icon={<Search />}>
            <h1>Granularity</h1>
            <p>Ability to see insights down to an individual road or rail link.</p>
          </IconCart>
          <IconCart icon={<Stand />}>
            <h1>Historical & real-time</h1>
            <p>We offer data as old as 6 years ago and as recent as 3 minutes ago to meet your exact needs.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Related Products</h1>
        </hgroup>
        <LandscapeCart>
          <img src={originDestinationInsights} />
          <article>
            <h2>{links.products.pages.originDestination.label}</h2>
            <p>Travel analysis to identify how people move around different regions of the UK.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.originDestination.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.railInsights.label}</h2>
            <p>Insight into how travellers in the UK move across the rail transport network.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.railInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={railInsights} />
        </LandscapeCart>
        <LandscapeCart>
          <img src={realTimeTravelInsights} />
          <article>
            <h2>{links.products.pages.realTimeTravelInsights.label}</h2>
            <p>Real-time traffic monitoring across road networks using mobile network data.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.realTimeTravelInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.roadInsights.label}</h2>
            <p>Insights into how travellers in the UK move across the road network.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.roadInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={roadInsights} />
        </LandscapeCart>
        <LandscapeCart>
          <img src={spatialInsights} />
          <article>
            <h2>{links.products.pages.spatialInsights.label}</h2>
            <p>Video analytics and AI to deliver insights around customer movement and flow in a venue.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.spatialInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
      </PageContent>
      <div className="grey-background" style={{ backgroundImage: `url(${greyBackground})` }}>
        <PageContent className="read-case-study">
          <hgroup>
            <h2>
              Learn how O<sub>2</sub> Motion data helps the Department for Transport monitor how mobility patterns are changing in near
              real-time during the pandemic.
            </h2>
            <ButtonLink>
              <a
                className="secondary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/1069754/O2_business_DfT2.pdf/0d06fa58-1956-e48c-87a7-6ca34da211d7?version=1.0&t=1615800553245"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </hgroup>
          <article>
            <p>
              “As COVID-19 has changed, so have the DfT insight requirements, and we’ve operated an agile way of working to support them in
              the best way we can.”
            </p>
            <div>
              <span> Ian Burrows</span>
              <br />
              <span>
                Digital Insights Director, O<sub>2</sub>
              </span>
            </div>
          </article>
        </PageContent>
      </div>
    </TransportLogisticsStyles>
  );
};
