import styled from 'styled-components';

export const FooterStyles = styled.section`
  background: #0019a5;
  min-width: var(--min-page-width);

  ul {
    display: flex;
    place-content: center;
    place-items: center;

    font-size: 14px;
    height: var(--footer-height);
    width: var(--page-width);
    margin: 0 auto;
  }

  a {
    color: white;
    text-decoration: none;
    padding: 0 2.5rem 0 2.4rem;
    border-right: 1px solid white;

    &:last-child {
      border: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;
