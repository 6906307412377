export const Cancel = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2048 11.952L23.6448 1.512C23.9904 1.1664 23.9904 0.6048 23.6448 0.2592C23.2992 -0.0864 22.7376 -0.0864 22.392 0.2592L11.952 10.6992L1.512 0.2592C1.1664 -0.0864 0.6048 -0.0864 0.2592 0.2592C-0.0864 0.6048 -0.0864 1.1664 0.2592 1.512L10.6992 11.952L0.2592 22.392C-0.0864 22.7376 -0.0864 23.2992 0.2592 23.6448C0.432 23.8176 0.6576 23.904 0.8832 23.904C1.1088 23.904 1.3344 23.8176 1.5072 23.6448L11.952 13.2048L22.392 23.6448C22.5648 23.8176 22.7904 23.904 23.016 23.904C23.2416 23.904 23.4672 23.8176 23.64 23.6448C23.9856 23.2992 23.9856 22.7376 23.64 22.392L13.2048 11.952Z"
        fill="#F65D7C"
      />
    </svg>
  );
};
