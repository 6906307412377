import styled from 'styled-components';

export const ButtonLinkStyles = styled.section`
  a {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    color: white;
    background: #0019a5;
    padding: 6px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border-radius: 3px;
    border: 1px solid #0019a5;
    box-sizing: border-box;
    letter-spacing: -0.02em;

    &:hover {
      background: #000066;
      transition: all 300ms ease-in-out;
    }

    &.primary {
      svg {
        stroke: white;
      }
    }

    &.secondary {
      border: 0;
      color: #0019a5;
      padding: 8px 24px;
      background: white;

      &:hover {
        opacity: 0.8;
        background: #808cd2;
      }
    }

    &.tertiary {
      color: white;
      padding: 8px 24px;
      background: transparent;
      border-color: white;

      &:hover {
        border-color: #808cd2;
      }
    }

    &.quaternary {
      color: #0019a5;
      background: white;
      border-color: #0019a5;

      &:hover {
        color: #000066;
        border-color: #000066;
      }
    }
    &.quinary {
      color: #0019a5;
      background: transparent;
      border-color: transparent;

      &:hover {
        border-color: #e5e8f6;
        box-shadow: 0px 0px 2px rgba(0, 25, 165, 0.15);
        background-color: #e5e8f6;
      }
    }
    .senary {
    }
    .septenary {
    }
    .octonary {
    }
    .nonary {
    }
    .denary {
    }
  }
`;
