import { SharedConsts } from 'shared/constants';
import { PageContent, Specification } from 'shared/ui';

import { SISpecificationStyles } from './SISpecificationStyles.styles';

export const SISpecification = () => {
  return (
    <SISpecificationStyles>
      <PageContent>
        <Specification>
          <hgroup>
            <h1>Data outputs</h1>
          </hgroup>
          <article>
            <h2>Purchase funnel KPIs</h2>
            <p>
              <ul>
                <li>Total number of passersby</li>
                <li>Capture rate (percentage of passersby that enter the venue)</li>
                <li>Number of people who enter the venue (visitors)</li>
                <li>Percentage of visitors who make a purchase</li>
                <li>Number, total value and average value of transactions</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>Conversion driver KPIs</h2>
            <p>
              <ul>
                <li>Average dwell time</li>
                <li>Entry rate in each product zone</li>
                <li>Average time spent in all and specific queues</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>Live occupancy</h2>
            <p>Total number of people and versus total capacity, in real-time</p>
          </article>
          <article>
            <h2>Demographic data</h2>
            <p>
              <ul>
                <li>Age</li>
                <li>Gender</li>
                <li>Customer vs. staff</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>Advanced insights</h2>
            <p>
              <ul>
                <li>Heatmaps of dwells (total or average time) throughout the venue</li>
                <li>Heatmaps of popular paths within the venue</li>
                <li>Headcount planner, based on venue occupancy</li>
              </ul>
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data features</h1>
          </hgroup>
          <article>
            <h2>Geographic coverage</h2>
            <p>Can be purchased for any venue</p>
          </article>
          <article>
            <h2>Temporal granularity</h2>
            <p>Day, week or month</p>
          </article>

          <article>
            <h2>Disclosure controls (privacy protection)</h2>
            <p>All insight is fully aggregated, anonymised and faces blurred</p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data collection</h1>
          </hgroup>
          <article>
            <h2>Collection equipment</h2>
            <p>A small computer vision device installed at your location, using your existing CCTV equipment</p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data delivery</h1>
          </hgroup>
          <article>
            <h2>Frequency and latency</h2>
            <p>Data comes into the dashboard in real time. Latency is 15 mins</p>
          </article>
          <article>
            <h2>Channels</h2>
            <p>
              Dashboard on the O<sub>2</sub> Motion Portal
            </p>
          </article>
          <article>
            <h2>Historical data</h2>
            <p>
              Data is available following live set up and test of solution, and can be queried retrospectively on the dashboard for the
              duration of the contract
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Fancy trying it for yourself?</h1>
          </hgroup>
          <p>
            See a demo of our dashboard <a href={SharedConsts.siDashboardIframeUrl}>here</a>. Please contact us at{' '}
            <a target="_blank" href="mailto:o2motion@o2.com" rel="noreferrer">
              o2motion@o2.com
            </a>{' '}
            to discuss your needs.
          </p>
        </Specification>
      </PageContent>
    </SISpecificationStyles>
  );
};
