import { PageContent, IconCart, LandscapeCart, ButtonLink } from 'shared/ui';
import { InternationalPhone, Stand, Next, Portal, CheckList, Clock, Search } from 'shared/icons';
import { ODOverviewStyles } from './ODOverview.styles';

import storiesImage1 from 'assets/images/od/stories-image-1.png';
import storiesImage2 from 'assets/images/od/stories-image-2.png';

export const ODOverview = () => {
  return (
    <ODOverviewStyles>
      <PageContent className="description">
        <hgroup>
          <h1>Analyse travel patterns to understand changing population connectivity and mobility</h1>
        </hgroup>
        <article>
          <p>
            Origin Destination provides insight into how travellers move around different regions of the UK by travel mode, purpose, time of
            the day, day of the week and month of the year.
          </p>
          <br />
          <p>
            Equipped with this insight, you can make informed decisions on transport infrastructure across the UK. With a more accurate and
            cost-effective picture of who is travelling where, you can ensure infrastructure investment decisions are going to provide real
            benefits.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<CheckList />}>
            <h1>Accurate</h1>
            <p>
              Insights on travel patterns are based on a considerably larger and more statistically significant sample than traditional
              survey techniques.
            </p>
          </IconCart>
          <IconCart icon={<Clock />}>
            <h1>Fast</h1>
            <p>
              Data is collected passively from the network, removing the need to install expensive equipment and enabling you to get
              insights in days.
            </p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Far-reaching</h1>
            <p>Our data is UK-wide covering all MSOA/LSOAs, with data expanded to fairly represent the entire population.</p>
          </IconCart>
          <IconCart icon={<Stand />}>
            <h1>Historical data</h1>
            <p>
              O<sub>2</sub> Motion data is available since 2016, enabling you to understand how travel patterns have changed over time.
            </p>
          </IconCart>
          <IconCart icon={<Search />}>
            <h1>Granular</h1>
            <p>Gain insights on people counts segmented in different forms, available down to OD level.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>The solution is readily available to be deployed.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Customer success stories</h1>
        </hgroup>
        <LandscapeCart>
          <img src={storiesImage1} />
          <article>
            <h2>Our data quantifies the demand for responsive transportation services in Bristol</h2>
            <p>
              “Activity chains powered by mobile network data is a new source of data which can be exploited further and will contribute in
              identifying the most sustainable combination of new mobility services during the place making process.”
            </p>
            <i>Dr Patrizia Franco, Connected Places Catapult</i>
            <br />
            <ButtonLink>
              <a
                className="quaternary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/1000458/O2_Motion_Catapult_Customer_Story_v5+%281%29.pdf/a98307da-29d1-7d44-e740-755aef6da1c8?version=1.0&t=1615798094136"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>
              O<sub>2</sub> Motion is helping Connected Places Catapult plan the transport systems of the future Underground.
            </h2>
            <p>
              “O<sub>2</sub>’s data gave us a solid base to support the development of a large scale demand model which can test innovative
              policy interventions to decarbonise road transport at regional level.”
            </p>
            <i>Dr Patrizia Franco, Connected Places Catapult</i>
            <br />
            <ButtonLink>
              <a
                className="quaternary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/0/O2+CPC+Urban+Customer+Story.pdf/f4cda3ab-6ec3-94f5-c66f-a9ae91fd13cf?t=1652189208828"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={storiesImage2} />
        </LandscapeCart>
      </PageContent>
    </ODOverviewStyles>
  );
};
