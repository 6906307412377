export const Pause = () => (
  <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.00385 1.14114V14.8612C3.00385 15.4902 2.32991 15.9948 1.50193 15.9948C0.673942 15.9948 0 15.4829 0 14.8539V1.14114C0 0.512186 0.673942 0.000244141 1.50193 0.000244141C2.32991 0.000244141 3.00385 0.512186 3.00385 1.14114Z"
      fill="#0019A5"
    />
    <path
      d="M11 1.14612V14.8662C11 15.4951 10.3261 15.9998 9.49808 15.9998C8.67009 15.9998 7.99615 15.4878 7.99615 14.8589V1.14612C7.99615 0.517159 8.67009 0.0052166 9.49808 0.0052166C10.3261 0.0052166 11 0.517159 11 1.14612Z"
      fill="#0019A5"
    />
  </svg>
);
