import styled from 'styled-components';

export const SignInStyles = styled.section`
  display: grid;
  place-content: center;
  place-items: center;
  grid-row-gap: 60px;

  min-height: 100vh;
  background: center no-repeat url('/b2clogin/login-background.png');
  background-size: cover;

  .logo {
    height: 109px;
    width: 100px;
  }

  .content {
    min-height: 300px;
    width: 770px;
    background: #ffffff24;
    box-shadow: 0px 10px 20px rgba(0, 25, 165, 0.2);
    padding: 60px 114px;
    color: white;
    text-align: center;
    border-radius: 5px;
    .intro {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #ffffff;
      font-size: 18px;

      text-align: left;
      margin: 54px 0;
      a {
        color: white;
        text-decoration: underline;
      }
    }
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      margin: 0;
    }
  }

  .errorSnack {
    background-color: ${(props) => props.theme.palette.error.dark};

    color: #ffffff;

    .message {
      display: flex;
      align-items: center;

      > .icon {
        font-size: 2rem;
        opacity: 0.9;
        margin-right: 1rem;
      }
    }
  }
`;
