import React from 'react';
import { PageContent } from 'shared/ui';
import { DataDownloadStyles } from './DataDownload.styles';
import { DataDownloadPanel } from './DataDownloadPanel';
import { DataDownloadTreeView } from './DataDownloadTreeView';
import { useDataDownload } from './useDataDownload';

export const DataDownload = () => {
  const {
    treeData,
    formData,
    checkboxes,
    toggle,
    loadingCvs,
    loadingTreeData,
    search,
    handleDownload,
    downloadProgress,
    selectedSize,
    products,
    setProduct,
  } = useDataDownload();

  return (
    <DataDownloadStyles>
      <PageContent className="data-download-wrapper">
        <DataDownloadPanel
          {...{
            loadingTreeData,
            formData,
            loadingCvs,
            search,
            handleDownload,
            downloadProgress,
            selectedSize,
            products,
            setProduct,
            checkboxes,
          }}
        />
        <DataDownloadTreeView {...{ treeData, checkboxes, toggle, loadingTreeData }} />
      </PageContent>
    </DataDownloadStyles>
  );
};
