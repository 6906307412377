import Button from '@mui/material/Button';
import { FormEvent, useState } from 'react';

import { AuthState, login } from '../lib/authAzure';

import Close from '@mui/icons-material/Close';
import Error from '@mui/icons-material/Error';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import { SignInStyles } from './SignIn.styles';

interface SignInProps {
  onSignIn: (authState: AuthState) => void;
}

export const SignIn = (props: SignInProps) => {
  const [error, setError] = useState<string | undefined>();

  const handleSignIn = async (e: FormEvent) => {
    e.preventDefault();

    const result = await login();

    switch (result.type) {
      case 'ERROR':
        setError(result.message);
        break;
      case 'SUCCESS':
        props.onSignIn({ isAuthenticated: true, username: result.user });
        break;
    }
  };

  return (
    <SignInStyles>
      <img src="/b2clogin/o2-business.png" className="logo" />
      <article className="content">
        <h1>
          O<sub>2</sub> Motion Portal
        </h1>
        <div className="intro">
          By accessing the O2 Motion Portal, you agree to keep your log-in credentials and any information accessed via the O2 Motion Portal
          confidential. You must notify Telefonica UK Limited immediately upon discovery of any unauthorized use of your log-in information
          or account. By using this service, you understand how Telefonica UK Limited uses your personal information in accordance with
          our&nbsp;
          <a href="https://www.o2.co.uk/termsandconditions/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </div>
        <div className="buttons">
          <Button variant="contained" color="primary" onClick={handleSignIn} size="large">
            Agree and Sign In
          </Button>
        </div>
      </article>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={error !== undefined}
        autoHideDuration={6000}
        onClose={() => setError(undefined)}
      >
        <SnackbarContent
          className="errorSnack"
          message={
            <span className="message">
              <Error className="icon" />
              <span>{error}</span>
            </span>
          }
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={() => setError(undefined)}>
              <Close />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </SignInStyles>
  );
};
