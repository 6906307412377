import { NavLink } from 'react-router-dom';
import { links } from 'shared/constants';
import { PageContent, Specification } from 'shared/ui';
import { ODSpecificationStyles } from './ODSpecificationStyles.styles';

export const ODSpecification = () => {
  return (
    <ODSpecificationStyles>
      <PageContent>
        <Specification>
          <hgroup>
            <h1>Data outputs</h1>
          </hgroup>
          <article>
            <h2>OD matrix base</h2>
            <p>
              <span>Average number of trips for the selected time period:</span>
              <ul>
                <li>Start zone, end zone, mode, purpose, time period</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>OD matrix frequency</h2>
            <p>
              <span>Users:</span>
              <ul>
                <li>Start zone, end zone, mode, purpose, time period, frequency</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>OD matrix travel time</h2>
            <p>
              <span>Average travel time:</span>
              <ul>
                <li>Start zone, end zone, mode, time period</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>OD matrix demographics</h2>
            <p>
              <span>Users:</span>
              <ul>
                <li>Start zone, end zone, age band, gender</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>POI precision</h2>
            <p>
              <span>Home Point Of Interest precision score:</span>
              <ul>
                <li>Home zone</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>Demographics</h2>
            <p>
              <span>Users:</span>
              <ul>
                <li>Home zone, age band, gender, spend power</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>Trip chains</h2>
            <p>
              <span>Trips:</span>
              <ul>
                <li>Chain zone, time period</li>
              </ul>
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data features</h1>
          </hgroup>
          <article>
            <h2>Geographic coverage</h2>
            <p>Can be purchased for specific areas or the whole of the UK</p>
          </article>
          <article>
            <h2>Spatial granularity</h2>
            <p>
              <ul>
                <li>MSOA for England and Wales, Intermediate Zones for Scotland and SOA for Northern Ireland</li>
                <li>LSOA</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>Expansion to the whole population</h2>
            <p>All outputs are expanded to fairly represent the entire UK population</p>
          </article>
          <article>
            <h2>Disclosure controls (privacy protection)</h2>
            <p>Outputs below 10 are replaced with a number between 0 and 10 derived using a &quot;stochastic rounding&quot; method</p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data delivery</h1>
          </hgroup>
          <article>
            <h2>Frequency and latency</h2>
            <p>1 month latency</p>
          </article>
          <article>
            <h2>Channels</h2>
            <p>Delivered as an OD Insights matrix report</p>
          </article>
          <article>
            <h2>Historical data</h2>
            <p>Data is available from January 2016 to present</p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Fancy trying it for yourself?</h1>
          </hgroup>
          <p>
            See sample data <NavLink to={`${links.products.pages.originDestination.link}/datasets`}>here</NavLink>.
          </p>
        </Specification>
      </PageContent>
    </ODSpecificationStyles>
  );
};
