import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeItem, TreeView } from '@mui/lab';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Folder } from 'shared/icons/Folder';
import { Loader } from 'shared/ui';
import { TreeStructure } from '../../../shared/models/TreeStructure';
import { DataDownloadItem } from '../../models/DataDownloadItemDto';
import { UseMovementInsightResultType } from './useDataDownload';

type DataDownloadTreeViewProps = Pick<UseMovementInsightResultType, 'treeData' | 'toggle' | 'checkboxes' | 'loadingTreeData'>;

const getKey = (node: TreeStructure<DataDownloadItem>) => node.element?.filePath || node.label;

function getAllLabels(treeData?: TreeStructure<DataDownloadItem>[] | null): string[] {
  return (
    treeData
      ?.filter((n) => n.elements)
      .reduce((a, node) => {
        a.push(getKey(node));
        a.push(...getAllLabels(node.elements));
        return a;
      }, [] as string[]) || []
  );
}

export const DataDownloadTreeView = ({ treeData, toggle, checkboxes, loadingTreeData }: DataDownloadTreeViewProps) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  useEffect(() => {
    if (treeData?.length === 1) {
      setExpandedItems(getAllLabels(treeData));
    } else {
      setExpandedItems([]);
    }
  }, [treeData]);
  function toggleItem(event: React.SyntheticEvent, elements: string[]) {
    setExpandedItems(elements);
  }
  const renderTree = (nodes: TreeStructure<DataDownloadItem>[] | undefined) =>
    Array.isArray(nodes)
      ? nodes.map((node) => {
          const key = node.key;
          const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => toggle({ checked, node, name: 'Toggle' });
          const label = node.elements?.length ? (
            <>
              <div className="folder-icon">
                <Folder />
              </div>
              <Checkbox checked={checkboxes.has(node)} onChange={onChangeHandler} onClick={(e) => e.stopPropagation()} size="medium" />
              {node.label}
            </>
          ) : (
            <FormControlLabel control={<Checkbox checked={checkboxes.has(node)} onChange={onChangeHandler} />} label={node.label} />
          );
          return (
            <TreeItem key={key} nodeId={key} label={label}>
              {renderTree(node.elements!)}
            </TreeItem>
          );
        })
      : '';

  const tree = loadingTreeData ? (
    <Loader />
  ) : treeData?.length ? (
    <TreeView
      disableSelection={true}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      expanded={expandedItems}
      onNodeToggle={(e, i) => toggleItem(e, i)}
    >
      {renderTree(treeData)}
    </TreeView>
  ) : (
    <div className="tree-loader-wrapper">No access to files for selected criteria</div>
  );
  return tree;
};
