export const Charger = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 12.4402C32 8.52975 28.8 5.32975 24.8896 5.32975C20.9792 5.32975 17.7792 8.52975 17.7792 12.4402V24.293C17.7792 26.8402 15.4048 29.0354 12.7424 29.0354C10.1376 29.0354 8 26.9042 8 24.293V21.3298C11.2576 20.7346 13.632 17.893 13.632 14.5138V6.51375H10.9696V1.77775C10.9696 1.12495 10.432 0.59375 9.7856 0.59375C9.1264 0.59375 8.5952 1.12495 8.5952 1.77775V6.52015H5.0368V1.77775C5.0368 1.12495 4.4992 0.59375 3.8528 0.59375C3.2064 0.59375 2.6624 1.12495 2.6624 1.77775V6.52015H0V14.5202C0 17.8994 2.3744 20.741 5.632 21.3362V24.2994C5.632 28.2098 8.832 31.4098 12.7424 31.4098C16.7744 31.4098 20.1536 28.1522 20.1536 24.2994V12.4402C20.1536 9.83535 22.2848 7.69775 24.896 7.69775C27.5072 7.69775 29.6384 9.82895 29.6384 12.4402V23.6978C29.6384 24.3506 30.176 24.8818 30.8224 24.8818C31.4752 24.8818 32.0064 24.3442 32.0064 23.6978L32 12.4402ZM2.3744 14.5138V8.88175H11.264V14.5138C11.264 16.9458 9.248 18.9554 6.8224 18.9554C4.3968 18.9554 2.3744 16.9458 2.3744 14.5138Z"
        fill="#0019A5"
      />
    </svg>
  );
};
