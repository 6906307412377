export const Clock = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_211_846)">
        <path
          d="M15.9999 17.6127L7.76951 9.38231C7.31511 8.92791 7.31511 8.21111 7.76951 7.76951C8.22391 7.31511 8.94071 7.31511 9.38231 7.76951L15.9999 14.3871L19.7695 10.6175C20.2239 10.1631 20.9407 10.1631 21.3823 10.6175C21.8367 11.0719 21.8367 11.7887 21.3823 12.2303L15.9999 17.6127Z"
          fill="#0019A5"
        />
        <path
          d="M16 32C7.1744 32 0 24.8256 0 16C0 7.1744 7.1744 0 16 0C24.8256 0 32 7.1744 32 16C32 24.8256 24.8256 32 16 32ZM16 2.2848C8.4416 2.2848 2.2848 8.4416 2.2848 16C2.2848 23.5584 8.4416 29.7152 16 29.7152C23.5584 29.7152 29.7152 23.5584 29.7152 16C29.7152 8.4416 23.5584 2.2848 16 2.2848Z"
          fill="#0019A5"
        />
      </g>
      <defs>
        <clipPath id="clip0_211_846">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
