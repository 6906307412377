import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { PageHeaderStyles } from './PageHeader.styles';
import { links } from 'shared/constants';

interface PageHeaderProps {
  image: string;
  menus?: { label: string; link: string }[];
  children: JSX.Element[] | JSX.Element;
}

export const PageHeader = (props: PageHeaderProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const body = document.querySelector('body')!;
    const roadInsightsLink = links.products.pages.roadInsights.link;
    const railInsightsLink = links.products.pages.railInsights.link;
    const dashboardLinks = [
      `${roadInsightsLink}/dashboard/road`,
      `${roadInsightsLink}/dashboard/routes`,
      `${roadInsightsLink}/dashboard/link-viewer`,
      `${railInsightsLink}/dashboard`,
    ];

    if (dashboardLinks.includes(window.location.pathname)) {
      body.style.setProperty('--page-width', 'clamp(1350px, 75%, 1440px)');
      body.style.setProperty('--min-page-width', '1350px');
    } else {
      body.style.setProperty('--page-width', 'clamp(1196px, 75%, 1440px)');
      body.style.setProperty('--min-page-width', '1196px');
    }
  });

  return (
    <PageHeaderStyles style={{ backgroundImage: `url(${props.image})` }}>
      <div className="image-background-wrapper">
        <header>{props.children}</header>
        <article>
          <div className="menu">
            {props.menus?.map((e) =>
              e.link.startsWith('http') ? (
                <a href={e.link} key={e.link}>
                  {e.label}
                </a>
              ) : (
                <NavLink to={e.link} key={e.link}>
                  {e.label}
                </NavLink>
              ),
            )}
          </div>
        </article>
      </div>
    </PageHeaderStyles>
  );
};
