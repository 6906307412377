import { distinct } from '../../shared/lib/distinct';
import { groupBy } from '../../shared/lib/group';
import { TreeStructure } from '../../shared/models/TreeStructure';
import { DataDownloadItem } from '../models/DataDownloadItemDto';
import { OdConsts } from './OdConsts';

function getFolderKey(parent: TreeStructure<DataDownloadItem> | undefined): string {
  if (!parent) {
    return '';
  }
  return parent.parent ? `${getFolderKey(parent.parent)}_${parent.label}` : parent.label;
}

function groupByLevelAndAttr(
  data: DataDownloadItem[],
  level: number,
  pathToLevelsMap: Record<string, string[]>,
  parent?: TreeStructure<DataDownloadItem>,
): TreeStructure<DataDownloadItem>[] {
  const grouped = groupBy(data, (e) => pathToLevelsMap[e.filePath][level]);
  const groups = Array.from(grouped.keys());

  const tree: TreeStructure<DataDownloadItem>[] = groups
    .filter((g) => g)
    .map((p) => {
      const e: TreeStructure<DataDownloadItem> = { label: p, parent, key: `${getFolderKey(parent)}_${p}` };
      e.elements = groupByLevelAndAttr(grouped.get(p)!, level + 1, pathToLevelsMap, e);
      return e;
    });

  const curLevelElements: TreeStructure<DataDownloadItem>[] = (grouped.get(undefined as any) || []).map((e) => ({
    label: e.filePath.split('/').pop()!,
    key: e.filePath,
    element: e,
    parent,
  }));
  tree.push(...curLevelElements);
  return tree;
}

export function groupData(data: DataDownloadItem[]): TreeStructure<DataDownloadItem>[] {
  const uniqueData = distinct(data, (v) => v.filePath);
  if (uniqueData.length < data.length) {
    console.warn(`${data.length - uniqueData.length} duplicated elements in the tree`);
  }
  const clientData = uniqueData.filter((e) => e.client !== OdConsts.sampleClientName);
  const sampleData = uniqueData.filter((e) => e.client === OdConsts.sampleClientName);
  const validSampleData = sampleData.filter((e) => {
    const sampleProduct = e.filePath.split('/')[2];
    return clientData.every((c) => c.filePath.split('/')[2] !== sampleProduct);
  });
  clientData.push(...validSampleData);
  const pathToLevelsMap = clientData.reduce((map, element) => {
    map[element.filePath] = element.filePath.split('/').slice(2, -1);
    return map;
  }, {} as Record<string, string[]>);

  return groupByLevelAndAttr(clientData, 0, pathToLevelsMap);
}
