import { PageContent, IconCart, LandscapeCart, ButtonLink } from 'shared/ui';
import { Map, Users, API, InternationalPhone, Stand, Next, Portal, Friend } from 'shared/icons';
import { AIOverviewStyles } from './AIOverview.styles';

import storiesImage1 from 'assets/images/ai/stories-image-1.png';
import storiesImage2 from 'assets/images/ai/stories-image-2.png';

export const AIOverview = () => {
  return (
    <AIOverviewStyles>
      <PageContent className="description">
        <hgroup>
          <h1>Turn data into insights about how people actually behave</h1>
        </hgroup>
        <article>
          <p>
            With Audience Insights you’ll be able to see which postcodes or areas have the highest concentration of your target audience.
          </p>
          <br />
          <p>
            If you’re an advertiser or media agency, you can use this information to improve personalisation, increase the accuracy of your
            campaigns, optimise marketing spend and enhance ROMI. If you’re a retailer, you can use the information to make a more informed
            decision about opening new stores or local promotions.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Map />}>
            <h1>Audience at location</h1>
            <p>Insights on where a particular audience is distributed across the UK down to Postcode Sector level.</p>
          </IconCart>
          <IconCart icon={<Users />}>
            <h1>Profile at location</h1>
            <p>Insights on audience composition of an area enabling better ROI for marketing/strategic initiatives.</p>
          </IconCart>
          <IconCart icon={<Friend />}>
            <h1>Extensive taxonomy</h1>
            <p>Customers can benefit from a best-in-class behavioural classification to identify their target audiences.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Scale</h1>
            <p>The data is expanded and covers all of the UK down to Postcode Sector level.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Integrated</h1>
            <p>Available as an online dashboard or linked to existing planning tools through an API.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>The solution is readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<Stand />}>
            <h1>Historical data</h1>
            <p>Availability of 3 months historic insight pre-processed from the day.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Customer success stories</h1>
        </hgroup>
        <LandscapeCart>
          <img src={storiesImage1} />
          <article>
            <h2>Our data helps Blis, the leading location-powered programmatic advertising partner, target customers better</h2>
            <p>
              “Detailed, granular audience insight is essential for us to deliver the most effective service for our clients and O
              <sub>2</sub> Motion is the only company to bring this level of telco behavioural targeting to advertisers in the UK.”
            </p>
            <i>Charlie Smith, Managing Director Europe, Blis</i>
            <br />
            <ButtonLink>
              <a
                className="quaternary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/4797679/BLIS+O2_Business_Blis.pdf/2182bd73-37f4-e405-67ff-14c74a8bcd70?t=1647945401904"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>
              O<sub>2</sub>&apos;s mobile data and bespoke interface are helping Exterion to maximise advertising spend on the London
              Underground.
            </h2>
            <p>
              “O<sub>2</sub>’s data has been a great improvement on previous methods of collecting audience information. We can use it to
              identify the best stations and times for a particular demographic, and also to establish which demographics are best
              represented at a specific station.”
            </p>
            <i>Mick Ridley, Chief Data Officer, Exterion</i>
            <br />
            <ButtonLink>
              <a
                className="quaternary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/1066650/O2_Business_ExterionMedia_Case_Study+%281%29.pdf/8fbfff6c-f326-805f-73d8-52ae432e386c?t=1615800415122"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={storiesImage2} />
        </LandscapeCart>
      </PageContent>
    </AIOverviewStyles>
  );
};
