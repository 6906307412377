export const Download = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_455_2674)">
      <path
        d="M7.99853 13.7152L3.31373 8.9728C2.94253 8.6016 2.82733 8 3.02573 7.4848C3.22733 6.9984 3.65293 6.7136 4.13933 6.7136H5.85453V0H10.1425V6.7136H11.8577C12.3441 6.7136 12.7729 6.9984 12.9713 7.4848C13.1729 8 13.0577 8.5984 12.6865 8.9696L7.99853 13.7152Z"
        fill="white"
      />
      <path
        d="M14.2848 16H1.7152C0.7712 16 0 15.2288 0 14.2848V8.00005C0 7.68645 0.256 7.42725 0.5728 7.42725C0.8896 7.42725 1.1456 7.68325 1.1456 8.00005V14.2848C1.1456 14.5984 1.4016 14.8576 1.7184 14.8576H14.2912C14.6048 14.8576 14.864 14.6016 14.864 14.2848V8.00005C14.864 7.68645 15.12 7.42725 15.4368 7.42725C15.7536 7.42725 16.0096 7.68325 16.0096 8.00005V14.2848C16 15.2288 15.2288 16 14.2848 16Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_455_2674">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
