import { createTheme } from '@mui/material';
import { BASE_FONT_SIZE } from '../constants/theme';

// declare module '@mui/material/styles' {
//   interface Palette {
//     primaryInverted: Palette['primary'];
//     secondaryInverted: Palette['primary'];
//   }

//   interface PaletteOptions {
//     primaryInverted: PaletteOptions['primary'];
//     secondaryInverted: PaletteOptions['primary'];
//   }
// }
const MaterialTheme = createTheme({
  typography: {
    // Adjust Material UI REM calculations to match
    // the font adjustments we use globally
    htmlFontSize: BASE_FONT_SIZE,
    fontFamily: ['Frutiger', 'Arial', 'Verdana'].join(','),
  },
  palette: {
    background: {
      default: '#0090d0',
      paper: '#FFFFFF',
    },
    primary: {
      //O2 brand blue
      main: '#0019A5',
      dark: '#000066',
      light: '#808CD2',
    },
    secondary: {
      //O2 brand light blue
      main: '#0090D0',
      light: '#41B6E6',
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        input: {
          color: '#FFF',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '0px',
        },
        elevation1: {
          boxShadow: 'none',
        },
        elevation24: {
          // Modal Dialog
          minWidth: '450px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        // body1: {
        //   fontFamily: 'Frutiger',
        //   fontStyle: 'normal',
        //   fontWeight: 400,
        //   fontSize: '16px',
        //   lineHeight: '24px',
        //   letterSpacing: '-0.02em',
        //   color: '#575757',
        // },
        // h2: {
        //   fontFamily: 'Frutiger Bold',
        //   fontStyle: 'normal',
        //   fontWeight: 700,
        //   fontSize: '20px',
        //   lineHeight: '28px',
        //   letterSpacing: '-0.02em',
        //   color: '#575757',
        // },
        // h3: {
        //   margin: 'inherit',
        //   fontWeight: 700,
        //   fontSize: '20px',
        //   lineHeight: '28px',
        //   letterSpacing: '-0.02em',
        // },
        // h4: {
        //   fontWeight: 'bold',
        //   textAlign: 'center',
        // },
        gutterBottom: {
          marginBottom: '1rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          // Set to the top of the window
          zIndex: 'inherit',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: '1.2rem',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          color: '#575757',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          color: '#575757',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#DADADA',
          '&.Mui-checked': {
            color: '#0019A5',
          },
        },
      },
    },
  },
});

export default MaterialTheme;
