import { Link } from '@mui/material';
import { PortraitCart, PageHeader } from 'shared/ui';
import { Breadcrumb } from 'shared/ui/Breadcrumb/Breadcrumb';
import { ProductsStyles } from './Products.styles';
import { links } from 'shared/constants';

import headerImage from 'assets/images/products/header-image.svg';

import audienceInsights from 'assets/images/products/audience-insights.png';
import microLocationsInsights from 'assets/images/products/micro-location-insights.png';
import movementsInsights from 'assets/images/products/movements-insights.png';
import originDestinationInsights from 'assets/images/products/origin-destination-insights.png';
import realTimeLocationInsights from 'assets/images/products/real-time-location-insights.png';
import realTimeTravelInsights from 'assets/images/products/real-time-travel-insights.png';
import roadInsights from 'assets/images/products/road-travel-insights.png';
import spatialInsights from 'assets/images/products/spatial-insights.png';
import railInsights from 'assets/images/products/rail-insights.png';

export const Products = () => {
  return (
    <ProductsStyles>
      <PageHeader image={headerImage} menus={[]}>
        <h1>Products</h1>
        <p>
          O<sub>2</sub> Motion offers a new approach to traditional location intelligence. Through a <br /> suite of solutions honed over
          several years – including Smart Steps and Smart <br /> Cities – you can look at the past, evaluate the present, and help predict
          the future.
        </p>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href="/">
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" aria-current="page">
          PRODUCTS
        </Link>
      </Breadcrumb>
      <div className="products-wrap">
        <div className="products-carts">
          <PortraitCart
            link={links.products.pages.audienceInsights.link}
            image={audienceInsights}
            header={links.products.pages.audienceInsights.label}
            description="Tailor your advertising and media buying to specific audiences for maximum impact."
            learnMore={links.products.pages.audienceInsights.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.products.pages.microLocationInsights.link}
            image={microLocationsInsights}
            header={links.products.pages.microLocationInsights.label}
            description="Local, location-based insights on visitors so you can improve performance measurement."
            learnMore={links.products.pages.microLocationInsights.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.products.pages.movementInsights.link}
            image={movementsInsights}
            header={links.products.pages.movementInsights.label}
            description="Low latency journey and location data across the UK and internationally."
            learnMore={links.products.pages.movementInsights.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.products.pages.originDestination.link}
            image={originDestinationInsights}
            header={links.products.pages.originDestination.label}
            description="Travel analysis to identify how people move around different regions of the UK."
            learnMore={links.products.pages.originDestination.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.products.pages.railInsights.link}
            image={railInsights}
            header={links.products.pages.railInsights.label}
            description="Insight into how travellers in the UK move across the rail transport network."
            learnMore={links.products.pages.railInsights.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.products.pages.realTimeLocationInsights.link}
            image={realTimeLocationInsights}
            header={links.products.pages.realTimeLocationInsights.label}
            description="Real-time data on the number and profiles of people in an area."
            learnMore={links.products.pages.realTimeLocationInsights.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.products.pages.realTimeTravelInsights.link}
            image={realTimeTravelInsights}
            header={links.products.pages.realTimeTravelInsights.label}
            description="Real-time traffic monitoring across road networks using mobile network data."
            learnMore={links.products.pages.realTimeTravelInsights.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.products.pages.roadInsights.link}
            image={roadInsights}
            header={links.products.pages.roadInsights.label}
            description="Insights into how travellers in the UK move across the road network."
            learnMore={links.products.pages.roadInsights.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.products.pages.spatialInsights.link}
            image={spatialInsights}
            header={links.products.pages.spatialInsights.label}
            description="Video analytics and AI to deliver insights around customer movement and flow in a venue."
            learnMore={links.products.pages.spatialInsights.link}
            borderColor="#dadada"
          />
        </div>
      </div>
    </ProductsStyles>
  );
};
