import { NavLink } from 'react-router-dom';
import { links } from 'shared/constants';
import { IconCart, ButtonLink, PortraitCart, PageHeader, PageContent, LandscapeCart, Frame } from 'shared/ui';
import { InStore, InternationalPhone, Map, MapBoard, Stand, Users, Next, Idea, CyberSecurity } from 'shared/icons';
import { AboutStyles } from './About.styles';

import headerImage from 'assets/images/about/header-image.svg';
import transportPlanners from 'assets/images/about/transport-planners.png';
import retailBrands from 'assets/images/about/retail-brands.png';
import mediaAgencies from 'assets/images/about/media-agencies.png';
import propertyDevelopers from 'assets/images/about/property-developers.png';

export const About = () => {
  return (
    <AboutStyles>
      <PageHeader image={headerImage}>
        <PageContent className="header">
          <hgroup>
            <h1>
              What is <br />
              <strong>
                O<sub>2</sub> Motion?
              </strong>
            </h1>
          </hgroup>
          <article>
            <p>
              O<sub>2</sub> Motion brings a unique approach to location intelligence, using anonymised and aggregated data from our huge
              mobile network to generate insights. We understand population mobility, socio- demographics, interests and behaviours so you
              can make informed and effective decisions about your organisation, strategy and policies.
            </p>
            <ButtonLink>
              <NavLink className="secondary" to={links.products.link}>
                Explore products
              </NavLink>
              <NavLink className="tertiary" to={links.contactUs.link}>
                Get in touch
              </NavLink>
            </ButtonLink>
          </article>
        </PageContent>
      </PageHeader>
      <PageContent className="insights">
        <header>
          <hgroup>
            <h1>
              Our data, insights & tools can help you <strong>understand your customers</strong>
            </h1>
            <h3>Extract meaningful insights from one of the UK’s largest and most accurate data sets.</h3>
          </hgroup>
        </header>
        <article className="insights-carts">
          <IconCart icon={<InternationalPhone />}>
            <h1>Extensive coverage</h1>
            <p>
              Our insights are generated from over 25 million mobile customers and millions of registered O<sub>2</sub> Wi-Fi users; one of
              the UK’s largest and most accurate data sets.
            </p>
          </IconCart>
          <IconCart icon={<Map />}>
            <h1>Location-based intelligence</h1>
            <p>React quicker, improve efficiency, and deliver better customer service, thanks to location-based data insights.</p>
          </IconCart>
          <IconCart icon={<InStore />}>
            <h1>Understand your individual sites</h1>
            <p>
              Data from our solutions helps you to understand customer behaviour at site level. So you can increase engagement, visit
              frequency and dwell time.
            </p>
          </IconCart>
          <IconCart icon={<Users />}>
            <h1>Increase marketing accuracy</h1>
            <p>
              Combining our insights with your marketing strategy can significantly increase the accuracy of any campaign, maximising
              efficiency and ROI.
            </p>
          </IconCart>
          <IconCart icon={<MapBoard />}>
            <h1>Improve your planning</h1>
            <p>
              Our data set is based on over 1/3 of the UK population. This is extrapolated to represent the entire UK population for better
              planning across multiple sectors.
            </p>
          </IconCart>
          <IconCart icon={<Stand />}>
            <h1>Predict trends</h1>
            <p>
              Identify and track changes to be ahead of the game. Bring the insight further by combining with datasets such as weather,
              competitor activity and special events.
            </p>
          </IconCart>
        </article>
        <article className="insights-buttons">
          <ButtonLink>
            <NavLink className="center primary" to={links.products.link}>
              <span>
                O<sub>2</sub>&nbsp;Motion products&nbsp;
              </span>
              <Next />
            </NavLink>
          </ButtonLink>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="diverse grey-background">
          <header>
            <hgroup>
              <h1>
                We’ve empowered <strong>a diverse range of companies and public agencies</strong>
              </h1>
              <h3>
                O<sub>2</sub> Motion enables our customers to address current, industry-specific challenges and make better-informed
                decisions.
              </h3>
            </hgroup>
          </header>
          <article className="diverse-carts">
            <PortraitCart
              link={links.industries.pages.transportLogistics.link}
              image={transportPlanners}
              header="Transport planners"
              description="With O2 Motion, see how people move and behave at a granular level. You can segment by specific demographics to benchmark performance against competing schemes, predict passenger volumes or plan the most likely routes."
              learnMore={links.industries.pages.transportLogistics.link}
            />
            <PortraitCart
              link={links.industries.pages.mediaAdvertising.link}
              image={mediaAgencies}
              header="Media agencies"
              description="Dynamically target campaigns to the right people, in the right place, at the right time to maximise exposure. You can base your analysis on millions of data points per day to match media planning, buying and creative execution with real behaviours."
              learnMore={links.industries.pages.mediaAdvertising.link}
            />
            <PortraitCart
              link={links.industries.pages.retail.link}
              image={retailBrands}
              header="Retail brands"
              description="Base customer analysis on millions of data points per day to match store investments with real customer behaviour. Everything from accurate dwell times to socio-demographics, footfall, repeat shoppers and catchment areas."
              learnMore={links.industries.pages.retail.link}
            />
            <PortraitCart
              link={links.industries.pages.retailProperty.link}
              image={propertyDevelopers}
              header="Retail property developers"
              description="Use demographic data and demand analytics to predict customer volumes, journey modes and route choices. With granular movement and behaviour insights you can plan venues that match the needs of specific groups."
              learnMore={links.industries.pages.retailProperty.link}
            />
          </article>
          <section className="diverse-buttons">
            <ButtonLink>
              <NavLink className="primary" to="industries">
                See more industry solutions <Next />
              </NavLink>
            </ButtonLink>
          </section>
        </PageContent>
      </div>
      <PageContent className="pandemic">
        <header>
          <hgroup>
            <h1>
              <strong>
                How O<sub>2</sub> Motion supported the government during a pandemic
              </strong>
            </h1>
          </hgroup>
        </header>
        <LandscapeCart>
          <Frame url="https://www.youtube-nocookie.com/embed/HTBywK4baag" width={574} height={322} />
          <article>
            <div>
              O<sub>2</sub> Motion helped the Office for National Statistics provide robust and timely evidence for Government policy during
              the COVID-19 pandemic.
            </div>
            <p className="ons">
              <strong>
                “ONS have been very clear about the data they require and we’ve worked hard to deliver it for them. We also provided
                insights into how existing data can be used to deliver the results they’re looking for.”
              </strong>
            </p>
            <p>
              <i>
                Ian Burrows, Digital Insights Director, O<sub>2</sub>
              </i>
            </p>
            <ButtonLink>
              <a
                className="quaternary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/1074429/O2_business_ONS_1020.049+%281%29.pdf/8e310232-0db8-d322-bac5-c4d46be450b6?version=1.0&t=1615800700104"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
      </PageContent>
      <div className="grey-background">
        <PageContent className="population grey-background">
          <header>
            <hgroup>
              <h1>
                <strong>Anonymous, aggregated data</strong> generated by 35% of the UK’s population from billions of daily network events
              </h1>
            </hgroup>
          </header>
          <section className="population-carts">
            <IconCart icon={<Idea />}>
              <h1>How it works</h1>
              <p>
                Our insight is derived from 10 to 12 billion mobile network events a day produced by 35% of the UK population. Read more
                about our mobile network data collection process.
              </p>
              <ButtonLink>
                <NavLink className="quinary" to={links.about.pages.howDoesItWork.link}>
                  Learn more <Next />
                </NavLink>
              </ButtonLink>
            </IconCart>
            <IconCart icon={<CyberSecurity />}>
              <h1>Data protection</h1>
              <p>
                We take the privacy and data protection rights of our mobile network users seriously. All insights provided within our data
                solutions are compliant with data protection and privacy laws and are anonymised and aggregated.
              </p>
              <ButtonLink>
                <NavLink className="quinary" to={links.about.pages.dataPrivacy.link}>
                  Learn more <Next />
                </NavLink>
              </ButtonLink>
            </IconCart>
          </section>
        </PageContent>
      </div>
      <PageContent className="contact">
        <header>
          <hgroup>
            <h1>Want to learn more?</h1>
          </hgroup>
          <p>
            If you would like a demo of our products or have any enquiries, please don&apos;t hesitate to connect with our O<sub>2</sub>{' '}
            Motion team.
          </p>
        </header>
        <div className="contact-buttons">
          <ButtonLink>
            <NavLink to="contact-us">Get in touch</NavLink>
          </ButtonLink>
        </div>
      </PageContent>
    </AboutStyles>
  );
};
