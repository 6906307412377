import { ReactNode } from 'react';
import { MovieStyles } from './Movie.styles';

interface MovieProps {
  header?: ReactNode | string;
  movieUrl: string;
  videoHeight: number;
  videoWidth: number;
}

export const Movie = (props: MovieProps) => {
  return (
    <MovieStyles>
      <video
        width={props.videoWidth}
        height={props.videoHeight}
        controls
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
      >
        <source src={props.movieUrl} type="video/mp4" />
      </video>
    </MovieStyles>
  );
};
