import styled from 'styled-components';

export const SIOverviewStyles = styled.section`
  .description {
    padding: 100px 0;
    display: grid;
    grid-template-columns: 376px 596px;
    justify-content: space-around;

    h1 {
      margin: 0;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.02em;
      color: #0019a5;
    }

    p {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #575757;
    }

    > section {
      margin: 80px auto;
      grid-column: 1 / 3;
    }
  }

  .frame {
    display: flex;
    place-content: center;
    place-items: center;
  }

  .promotion {
    display: grid;
    grid-gap: 100px;
    justify-content: space-around;
    grid-template-columns: repeat(3, 1fr);
    padding: 100px 0;
  }

  .related-products {
    padding: 100px 122px;

    hgroup {
      h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #0019a5;
      }
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #575757;
    }
  }
`;
