import styled from 'styled-components';

export const ErrorPageStyles = styled.section`
  > section {
    padding: 167px 32px;
    display: flex;
    place-items: end;
    gap: 80px;
  }

  h1 {
    font-family: 'Frutiger Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #0019a5;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #575757;
  }
`;
