export const Report = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.75 2.99922C27.75 2.29922 27.5 1.69922 27 1.19922C26 0.199219 24.4 0.199219 23.45 1.19922L19 5.69922V0.499219H7.5L0 7.99922V27.4992H19V12.7992L27.05 4.74922C27.5 4.29922 27.75 3.69922 27.75 2.99922ZM25.95 2.29922C26.15 2.49922 26.25 2.74922 26.25 2.99922C26.25 3.24922 26.15 3.49922 26 3.69922L24.55 2.24922C24.95 1.89922 25.6 1.89922 25.95 2.29922ZM15.55 11.2492L23.5 3.29922L24.95 4.74922L17 12.6992L15.55 11.2492ZM14.8 12.5992L15.65 13.4492L14.3 13.9492L14.8 12.5992ZM7.25 3.64922V7.74922H3.15L7.25 3.64922ZM2 25.4992V9.24922H8.75V2.49922H17V7.69922L13.95 10.7492C13.85 10.8492 13.85 10.8992 13.8 10.9992L12.3 14.9992C12.2 15.2992 12.25 15.5992 12.45 15.7992C12.6 15.9492 12.8 15.9992 13 15.9992C13.1 15.9992 13.2 15.9992 13.25 15.9492L17 14.5492V25.4992H2ZM15.25 20.9992C15.25 21.3992 14.9 21.7492 14.5 21.7492H4.5C4.1 21.7492 3.75 21.3992 3.75 20.9992C3.75 20.5992 4.1 20.2492 4.5 20.2492H14.5C14.9 20.2492 15.25 20.5992 15.25 20.9992ZM3.75 15.9992C3.75 15.5992 4.1 15.2492 4.5 15.2492H10.5C10.9 15.2492 11.25 15.5992 11.25 15.9992C11.25 16.3992 10.9 16.7492 10.5 16.7492H4.5C4.1 16.7492 3.75 16.3992 3.75 15.9992Z"
        fill="#0019A5"
      />
    </svg>
  );
};
