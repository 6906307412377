export const InStore = () => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.7472 3.6384L24.7136 0.00320435H3.77921L0.752011 3.6384V9.5008H3.75361V18.9984H1.25121C0.835211 18.9984 0.502411 19.3312 0.502411 19.7472C0.502411 20.1632 0.835211 20.496 1.25121 20.496H14.4928L13.5264 21.4688L12.7776 22.2176C12.4832 22.512 12.4832 22.9856 12.7776 23.28C12.9248 23.4272 13.1168 23.4976 13.3088 23.4976C13.5008 23.4976 13.6928 23.4272 13.84 23.28L14.5888 22.5312L17.3664 19.7536L14.5888 16.976L13.84 16.2272C13.5456 15.9328 13.072 15.9328 12.7776 16.2272C12.4832 16.5216 12.4832 16.9952 12.7776 17.2896L13.5264 18.0384L14.4928 19.0112H10.5056V13.264H18.5056V26.0128H10.5056V22.7616C10.5056 22.3456 10.1728 22.0128 9.75681 22.0128C9.34081 22.0128 9.00801 22.3456 9.00801 22.7616V26.0128H5.75681V23.0112C5.75681 22.4608 5.30881 22.0128 4.75841 22.0128C4.20801 22.0128 3.76001 22.4608 3.76001 23.0112V28.0096H25.2576V9.5008H27.76V3.6384H27.7472ZM4.72001 2H23.7856L24.8224 3.248H3.67681L4.72001 2ZM23.248 26H19.9968V11.7472H9.00161V18.9984H5.75041V9.5008H23.248V26ZM2.74881 7.4976V4.7456H25.7504V7.4976H2.74881Z"
        fill="#0019A5"
      />
    </svg>
  );
};
