import { CSSProperties } from 'react';

interface NextProps {
  width?: number;
  height?: number;
  fill?: string;
  viewBox?: string;
  style?: CSSProperties;
}

export const Next = ({ width = 8, height = 14, viewBox = '0 0 8 14', fill = '#0019A5', style }: NextProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: 11, marginRight: 11, ...style }}
  >
    <path
      d="M0.777176 14C0.651176 14 0.527976 13.9496 0.427176 13.8488C0.228376 13.65 0.228376 13.3476 0.427176 13.1488L6.57878 6.99998L0.427176 0.851175C0.228376 0.652375 0.228376 0.349975 0.427176 0.151175C0.625976 -0.0476248 0.928376 -0.0476248 1.12718 0.151175L7.62598 6.64997C7.82478 6.84877 7.82478 7.15117 7.62598 7.34997L1.12718 13.8488C1.02918 13.9496 0.903176 14 0.777176 14Z"
      fill={fill}
    />
  </svg>
);
