export const links = {
  about: {
    label: 'About O2 Motion',
    link: '/',
    pages: {
      howDoesItWork: {
        label: 'How does it work?',
        link: '/about/how-does-it-work',
      },
      dataPrivacy: {
        label: 'Data Privacy',
        link: '/about/data-privacy',
      },
    },
  },
  products: {
    label: 'Products',
    link: '/products',
    pages: {
      audienceInsights: {
        label: 'Audience Insights',
        link: '/products/ai',
      },
      microLocationInsights: {
        label: 'Micro Location Insights',
        link: '/products/mli',
      },
      movementInsights: {
        label: 'Movement Insights',
        link: '/products/mi',
      },
      originDestination: {
        label: 'Origin Destination Insights',
        link: '/products/od',
      },
      railInsights: {
        label: 'Rail Insights',
        link: '/products/ri',
      },
      realTimeLocationInsights: {
        label: 'Real Time Location Insights',
        link: '/products/rtli',
      },
      realTimeTravelInsights: {
        label: 'Real Time Travel Insights',
        link: '/products/rtti',
      },
      roadInsights: {
        label: 'Road Insights',
        link: '/products/rti',
      },
      spatialInsights: {
        label: 'Spatial Insights',
        link: '/products/si',
      },
    },
  },
  industries: {
    label: 'Industries',
    link: '/industries',
    pages: {
      governmentPolicing: {
        label: 'Government & Policing',
        link: '/industries/gp',
      },
      leisureTourism: {
        label: 'Leisure & Tourism',
        link: '/industries/lt',
      },
      localAuthorities: {
        label: 'Local Authorities',
        link: '/industries/la',
      },
      mediaAdvertising: {
        label: 'Media & Advertising',
        link: '/industries/ma',
      },
      retail: {
        label: 'Retail',
        link: '/industries/r',
      },
      retailProperty: {
        label: 'Retail Property',
        link: '/industries/rp',
      },

      transportLogistics: {
        label: 'Transport & Logistics',
        link: '/industries/tl',
      },
    },
  },
  contactUs: {
    label: 'Contact Us',
    link: '/contact-us',
  },
  errorPage: {
    label: 'Something went wrong...',
    link: '/error',
  },
};
