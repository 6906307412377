import React, { PropsWithChildren, useEffect, useState } from 'react';
import { TableauDashboard } from 'shared/models/UserMetadata';
import { LoadingDataStates } from '../../shared/models/LoadingDataState';
import { AuthApi } from '../lib/AuthApi';
import { UserGroups } from '../models/userGroups';

interface UserGroupState {
  groups?: UserGroups[];
  tableauMetadata?: {
    hasTableauLicense: boolean;
    tableauDashboards: TableauDashboard[];
  };
  rtliMap?: { rtliHeatMap: boolean; rtliHexagonMap: boolean };
  request?: Promise<UserGroups[]>;
  loadingStatus?: LoadingDataStates;
}

const initialState: UserGroupState = { loadingStatus: LoadingDataStates.loading };
export const UserMetadataContext = React.createContext({
  state: initialState,
  setState: (() => {}) as Function,
});
export const UserGroupsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    if (!state.groups && !state.request) {
      const userGroupsRequest = AuthApi.getUserGroups();
      const userMetadataRequest = AuthApi.getUserMetadata();
      Promise.all([userGroupsRequest, userMetadataRequest])
        .then(([groups, metadata]) =>
          setState({
            groups,
            request: userGroupsRequest,
            loadingStatus: LoadingDataStates.loaded,
            tableauMetadata: metadata.tableauMetadata,
          }),
        )
        .catch(() => setState({ loadingStatus: LoadingDataStates.error }));
    }
  }, []);
  return <UserMetadataContext.Provider value={{ state, setState }}>{children}</UserMetadataContext.Provider>;
};
