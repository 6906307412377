import { PageContent } from 'shared/ui';
import { Error } from 'shared/icons';
import { ErrorPageStyles } from './ErrorPage.styles';

export const ErrorPage = () => {
  return (
    <ErrorPageStyles>
      <PageContent>
        <Error />
        <article>
          <h1>Something went wrong...</h1>
          <p>We’re sorry but something went wrong.</p>
          <p>
            Please try again later or contact the O<sub>2</sub> Motion team at&nbsp;
            <a href="mailto:o2motion@o2.com">o2motion@o2.com</a>
          </p>
        </article>
      </PageContent>
    </ErrorPageStyles>
  );
};
