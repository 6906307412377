export const CheckList = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_95_758)">
        <path
          d="M26.659 32.2097H4V3.58055C4 1.6414 5.6414 0 7.58055 0H23.0861C25.0252 0 26.6666 1.6414 26.6666 3.58055V32.2097H26.659ZM6.38194 29.8277H24.277V3.58055C24.277 2.97743 23.6892 2.38958 23.0861 2.38958H7.57291C6.96979 2.38958 6.38194 2.97743 6.38194 3.58055V29.8277Z"
          fill="#0019A5"
        />
        <path
          d="M11.4512 12.429L7.9241 8.90192C7.4584 8.43622 7.4584 7.68041 7.9241 7.21471C8.3898 6.74901 9.14561 6.74901 9.61131 7.21471L11.4512 9.05461L16.2762 4.22965C16.7419 3.76395 17.4977 3.76395 17.9634 4.22965C18.4291 4.69535 18.4291 5.45115 17.9634 5.91685L11.4512 12.429Z"
          fill="#0019A5"
        />
        <path
          d="M11.4512 20.659L7.92413 17.1319C7.45843 16.6662 7.45843 15.9104 7.92413 15.4447C8.38983 14.979 9.14564 14.979 9.61134 15.4447L11.4512 17.2846L16.2762 12.4596C16.7419 11.9939 17.4977 11.9939 17.9634 12.4596C18.4291 12.9253 18.4291 13.6811 17.9634 14.1468L11.4512 20.659Z"
          fill="#0019A5"
        />
        <path
          d="M11.4512 29.1253L7.92413 25.5982C7.45843 25.1325 7.45843 24.3767 7.92413 23.911C8.38983 23.4453 9.14564 23.4453 9.61134 23.911L11.4512 25.7509L16.2762 20.9259C16.7419 20.4602 17.4977 20.4602 17.9634 20.9259C18.4291 21.3916 18.4291 22.1474 17.9634 22.6131L11.4512 29.1253Z"
          fill="#0019A5"
        />
      </g>
      <defs>
        <clipPath id="clip0_95_758">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
