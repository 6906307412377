import { PageContent, IconCart } from 'shared/ui';
import { Users, API, InternationalPhone, Stand, Portal, Calendar, Search } from 'shared/icons';
import { RTIOverviewStyles } from './RTIOverview.styles';

export const RTIOverview = () => {
  return (
    <RTIOverviewStyles>
      <PageContent className="description">
        <hgroup>
          <h1>Optimise road infrastructure, reduce disruption and improve future planning decisions</h1>
        </hgroup>
        <article>
          <p>
            Road Insights is a web-based solution providing insights on usage of the UK Major and Strategic Road Networks, including;
            location, date of travel, time of the day, vehicle type (including the ability to see Electric vehicle and HGV behaviour),
            travel purpose, and traveller profile (age range, gender and spend potential).
          </p>
          <br />
          <p>
            We typically supply this insight to transport consultancies, government bodies and organisations looking to understand how
            people use the UK road network.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Users />}>
            <h1>Traveller profiles</h1>
            <p>Gain insights on the traveller composition of a road link.</p>
          </IconCart>
          <IconCart icon={<Stand />}>
            <h1>Trend analysis</h1>
            <p>Understand trends over time to predict what is likely to happen in the future.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Scale & accuracy</h1>
            <p>Our data captures a very large and representative sample of a third of the UK population.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Integrated</h1>
            <p>Easy to access data, be it web-based through an UI or linked to existing planning tools through an API.</p>
          </IconCart>
          <IconCart icon={<Search />}>
            <h1>Granular</h1>
            <p>Ability to see insights down to an individual road link.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>The solution is readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<Calendar />}>
            <h1>Recent</h1>
            <p>Receive data each month.</p>
          </IconCart>
        </PageContent>
      </div>
    </RTIOverviewStyles>
  );
};
