export const Calendar = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_95_797)">
        <path
          d="M28.5696 3.4304H23.424V1.1456C23.4304 0.512 22.912 0 22.2848 0C21.6576 0 21.1392 0.512 21.1392 1.1456V3.4304H10.8544V1.1456C10.8544 0.5184 10.3424 0 9.7088 0C9.0816 0 8.5632 0.512 8.5632 1.1456V3.4304H3.4304C1.5424 3.4304 0 4.9728 0 6.8544V32H32V6.8544C32 4.9728 30.4576 3.4304 28.5696 3.4304ZM3.4304 5.7152H8.576V8C8.576 8.6272 9.088 9.1456 9.7216 9.1456C10.3488 9.1456 10.8672 8.6336 10.8672 8V5.7152H21.152V8C21.152 8.6272 21.664 9.1456 22.2976 9.1456C22.9312 9.1456 23.4432 8.6336 23.4432 8V5.7152H28.5888C29.216 5.7152 29.7344 6.2272 29.7344 6.8608V10.8608H2.2848V6.8608C2.2848 6.2272 2.7968 5.7152 3.4304 5.7152ZM2.2848 29.7152V13.1456H29.7152V29.7152H2.2848Z"
          fill="#0019A5"
        />
        <path
          d="M12.2304 14.8032H11.2C10.5152 14.8032 9.1456 15.0912 8.3968 15.6608C7.8848 16.0576 7.8272 16.8064 8.224 17.2608C8.6272 17.7728 9.3696 17.8304 9.8816 17.4912C10.0544 17.376 10.7968 17.1456 11.1936 17.1456H12.224C13.024 17.1456 13.7088 17.8304 13.7088 18.688C13.7088 19.5456 13.0816 20.2304 12.224 20.2304H10.688C10.0608 20.2304 9.4848 20.7424 9.4848 21.4336C9.4848 22.1248 9.9968 22.6304 10.688 22.6304H12.2304C13.0304 22.6304 13.7152 23.3152 13.7152 24.1728C13.7152 25.0304 13.088 25.7152 12.2304 25.7152H11.2C10.7968 25.7152 10.0544 25.4848 9.888 25.3696C9.376 24.9664 8.6336 25.024 8.2304 25.5424C7.8272 26.0544 7.8848 26.7968 8.4032 27.2C9.1456 27.7696 10.5152 28.0576 11.2064 28.0576H12.2368C14.3488 28.0576 16.064 26.2848 16.064 24.1728C16.064 23.1424 15.6608 22.1696 14.976 21.4272C15.6608 20.7424 16.064 19.7696 16.064 18.6816C16.0576 16.5696 14.3424 14.8032 12.2304 14.8032ZM21.7152 15.0272C21.3696 14.7968 20.9152 14.7968 20.5696 15.0272L17.9456 16.512C17.376 16.8 17.2032 17.5424 17.5424 18.0544C17.8304 18.624 18.5728 18.7968 19.0848 18.4576L20 17.9456V26.8608C20 27.488 20.512 28.0064 21.1456 28.0064C21.7792 28.0064 22.2912 27.4944 22.2912 26.8608V16C22.2848 15.6032 22.0544 15.2 21.7152 15.0272Z"
          fill="#0019A5"
        />
      </g>
      <defs>
        <clipPath id="clip0_95_797">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
