import { HeaderBackground, NavMenuStyles } from './NavMenu.styles';
import o2Business from 'assets/images/o2-business.png';
import { NavLink } from 'react-router-dom';
import { UserMenu } from './UserMenu';
import { getName } from 'auth/lib/authAzure';
import { links } from 'shared/constants';
import { Menu } from 'shared/ui';

export const NavMenu = () => {
  return (
    <>
      <NavMenuStyles>
        <nav>
          <div className="left-menu">
            <NavLink to="/">
              <img src={o2Business} />
            </NavLink>
            <Menu links={links.about} />
            <Menu links={links.products} />
            <Menu links={links.industries} />
            <NavLink to="contact-us">Contact Us</NavLink>
          </div>
          <div className="right-menu">
            <UserMenu userName={getName()} />
          </div>
        </nav>
      </NavMenuStyles>
      <HeaderBackground />
    </>
  );
};
