import styled from 'styled-components';

export const GovernmentPolicingStyles = styled.section`
  .description {
    padding: 100px 0;
    display: grid;
    grid-template-columns: 376px 596px;
    justify-content: space-around;

    h1 {
      margin: 0;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.02em;
      color: #0019a5;
    }

    p {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #575757;
    }
  }

  .promotion {
    display: grid;
    grid-gap: 100px;
    justify-content: space-around;
    grid-template-columns: repeat(6, 1fr);
    padding: 100px 0;

    > section {
      grid-column: span 2;

      &:last-child:nth-child(3n-1) {
        grid-column-end: -2;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      &:last-child:nth-child(3n-2) {
        grid-column-end: 5;
      }

      .columns-wrapper {
        max-width: 100%;
      }
    }
  }

  .related-products {
    padding: 100px 243px;

    hgroup {
      h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #0019a5;
      }
    }
  }

  .read-case-study {
    padding: 100px 122px;
    display: grid;
    grid-template-columns: 466px 588px;
    grid-gap: 143px;
    align-items: baseline;

    hgroup {
      h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: #ffffff;
      }
      margin-bottom: 36px;
    }

    article {
      p {
        font-style: italic;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;

        letter-spacing: -0.02em;
      }

      span {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.02em;
      }
    }
  }
`;
