import { useState } from 'react';
import { LoadingDataStates } from 'shared/models/LoadingDataState';
import { ControlledLoader } from '../ControlledLoader';
import { IframePageStyles } from './IframePage.styles';
import { UserGroupsLoader } from '../UserGroupsLoader';

export const IframePage = ({ src }: { src: string }) => {
  const [loadingStatus, setLoadingStatus] = useState(LoadingDataStates.loading);
  const handleIframeLoad = () => {
    setLoadingStatus(LoadingDataStates.loaded);
  };

  return (
    <UserGroupsLoader>
      <IframePageStyles className="with-iframe">
        {loadingStatus === LoadingDataStates.loading && (
          <div className="content-loader-wrapper">
            <ControlledLoader loadingStatus={loadingStatus}>
              <div></div>
            </ControlledLoader>
          </div>
        )}
        <iframe src={src} onLoad={handleIframeLoad}></iframe>
      </IframePageStyles>
    </UserGroupsLoader>
  );
};
