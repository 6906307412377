import styled from 'styled-components';

export const BreadcrumbStyles = styled.section`
  width: var(--page-width);
  margin: 0 auto;

  ol {
    display: flex;
    flex-direction: row;
    place-content: center;
    place-items: start;
    gap: 15px;

    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
  }
`;
