import styled from 'styled-components';

interface PortraitCartStylesProps {
  borderColor?: string;
}

export const PortraitCartStyles = styled.section<PortraitCartStylesProps>`
  width: min-content;
  grid-column: span 2;

  &:last-child:nth-child(3n-1) {
    grid-column-end: -2;
  }

  &:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
  }

  &:last-child:nth-child(3n-2) {
    grid-column-end: 5;
  }

  img {
    display: flex;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #3c3c3c;
    margin: 16px 16px 0;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #878787;
    margin: 4px 16px 16px;
    min-height: 80px;
  }

  > a {
    border-radius: 5px;
    background-color: white;
    display: block;

    article {
      border-radius: 0 0 5px 5px;
      border: 1px solid ${(props) => props.borderColor};
      border-top-color: transparent;
    }

    :hover {
      border-radius: 5px;
      box-shadow: 0px 10px 30px rgba(0, 25, 165, 0.15);

      article {
        border: 1px solid transparent;
      }
    }
  }

  > div {
    display: flex;
    justify-content: center;

    > a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 14px;
      margin-top: 20px;
      color: #0019a5;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;

      :hover {
        border-radius: 3px;
        box-shadow: 0px 0px 2px rgba(0, 25, 165, 0.15);
        background-color: #e5e8f6;
      }
    }
  }
`;
