import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserMenuStyles } from './UserMenu.styles';

const userMenus = [{ link: '/sign-out', label: 'Sign Out' }];

export const UserMenu = (props: { userName: string }) => {
  const [menuLink, setMenuLink] = useState('');
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const handleMenuItemClick = (event: MouseEvent<HTMLElement>, index: number) => {
    const link = userMenus[index].link;
    setMenuLink(link);
    setVisible(false);
    navigate(link);
  };
  const handleClose = () => {
    setVisible(false);
  };
  const handleClickListItem = () => {
    setVisible(true);
  };

  return (
    <UserMenuStyles onMouseOver={handleClickListItem} onMouseOut={handleClose}>
      <div onClick={handleClickListItem} className="label">
        {props.userName} <ExpandMoreIcon fontSize="small" />
      </div>

      <Paper sx={{ width: 150 }} className="menu-items" hidden={!visible}>
        <MenuList>
          {userMenus.map((option, index) => (
            <MenuItem key={option.link} selected={option.link === menuLink} onClick={(event) => handleMenuItemClick(event, index)}>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </UserMenuStyles>
  );
};
