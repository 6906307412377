import styled from 'styled-components';

export const PageHeaderStyles = styled.section`
  display: flex;
  place-content: center;
  place-items: center;

  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  color: white;

  min-width: var(--min-page-width);
  height: 520px;

  header {
    h1 {
      font-family: 'Frutiger Black';
      font-weight: 400;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      letter-spacing: -0.02em;
    }

    p {
      color: white;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      text-align: center;
    }

    .header {
      display: grid;
      grid-column-gap: 80px;
      grid-template-columns: 1fr 1.5fr;
      place-content: center;
      place-items: start;

      /* display: flex;
      grid-column-gap: 81px;
      justify-content: space-between;
      justify-items: flex-start;
      align-items: flex-start; */
      text-align: start;

      hgroup {
        h1 {
          margin: 0;
          text-align: start;
          font-family: 'Frutiger';
          font-style: normal;
          font-size: 48px;
          line-height: 56px;
          color: white;

          strong {
            font-family: 'Frutiger Black';
          }
        }
      }

      article {
        p {
          margin: 0;
          text-align: start;
          font-family: 'Frutiger';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.02em;
          color: white;
          inline-size: 668px;
        }
      }
    }
  }

  article {
    .menu {
      display: flex;
      flex-direction: row;
      place-content: center;
      place-items: center;
      column-gap: 25px;
      margin-top: 40px;

      a {
        color: white;
        text-decoration: none;
        margin-right: 100px;
        height: 32px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        white-space: nowrap;

        &.active,
        &:hover {
          background: #3347b7;
          border-radius: 3px;
        }
      }
    }
  }
`;
