import { Navigate, Route, Routes } from 'react-router-dom';
import { Breadcrumb, PageHeader } from 'shared/ui';
import { links } from 'shared/constants';
import { Link } from '@mui/material';

import { AIOverview } from './components/overview/AIOverview';
import { AISpecification } from './components/specifications/AISpecification';

import { AudienceInsightsStyles } from './AudienceInsights.styles';
import headerImage from '../../assets/images/ai/header-image.png';

const AudienceInsights = () => {
  const menu = [
    { label: 'Product Overview', link: 'overview' },
    { label: 'Data Dashboard', link: process.env.REACT_APP_AI_URL! },
    { label: 'Specifications', link: 'specifications' },
  ];

  return (
    <AudienceInsightsStyles>
      <PageHeader image={headerImage} menus={menu}>
        <h1>{links.products.pages.audienceInsights.label}</h1>
        <p>
          Audience Insights allows you to tailor your advertising and media to <br /> specific audiences for maximum impact.
        </p>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.products.link}>
          {links.products.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.products.pages.audienceInsights.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <Routes>
        <Route path="overview" element={<AIOverview />} />
        <Route path="specifications" element={<AISpecification />} />
        <Route path="" element={<Navigate to="overview" replace={true} />} />
      </Routes>
    </AudienceInsightsStyles>
  );
};

export default AudienceInsights;
