export const Stand = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29 5H17V3.5C17 2.95 16.55 2.5 16 2.5C15.45 2.5 15 2.95 15 3.5V5H3C2.45 5 2 5.45 2 6C2 6.55 2.45 7 3 7H4.5V22H14.1L7.8 28.3C7.4 28.7 7.4 29.3 7.8 29.7C8 29.9 8.25 30 8.5 30C8.75 30 9 29.9 9.2 29.7L15 23.9V29C15 29.55 15.45 30 16 30C16.55 30 17 29.55 17 29V23.9L22.8 29.7C23 29.9 23.25 30 23.5 30C23.75 30 24 29.9 24.2 29.7C24.6 29.3 24.6 28.7 24.2 28.3L17.9 22H27.5V7H29C29.55 7 30 6.55 30 6C30 5.45 29.55 5 29 5ZM25.5 20H6.5V7H25.5V20ZM13 12.45L8.45 17C8.15 17.3 8.15 17.75 8.45 18.05C8.6 18.2 8.8 18.25 9 18.25C9.2 18.25 9.4 18.2 9.55 18.05L13 14.6L16 17.6L23.55 10.05C23.85 9.75 23.85 9.3 23.55 9C23.25 8.7 22.8 8.7 22.5 9L16 15.45L13 12.45Z"
        fill="#0019A5"
      />
    </svg>
  );
};
