import { ErrorBoundaryStyles } from './ErrorBoundary.styles';

export const ErrorBoundary = () => {
  return (
    <ErrorBoundaryStyles>
      <h1>Something went wrong.</h1>
      <p>We&#39;ve alerted our engineers to the problem and we&#39;ll track down the problem as soon as possible.</p>
    </ErrorBoundaryStyles>
  );
};
