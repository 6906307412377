import { PageContent, IconCart, LandscapeCart, ButtonLink } from 'shared/ui';
import { Users, API, InternationalPhone, Stand, Next, Portal, Calendar, Idea } from 'shared/icons';
import { MLIOverviewStyles } from './MLIOverview.styles';

import storiesImage1 from 'assets/images/mli/stories-image-1.png';
import storiesImage2 from 'assets/images/mli/stories-image-2.png';

export const MLIOverview = () => {
  return (
    <MLIOverviewStyles>
      <PageContent className="description">
        <hgroup>
          <h1>Establish a better understanding of your location</h1>
        </hgroup>
        <article>
          <p>
            Micro Location Insights is a service that gives you insights into visitors at a localised level, such as a street, shop or
            venue. Using this information, you can build up a richer picture of behaviours at an individual site level so you can understand
            footfall, visit frequency or dwell time and improve location planning decisions.
          </p>
          <br />
          <p>
            Sensors installed at a specific location can detect O<sub>2</sub> customers, to measure vistor volumes by hour and day, their
            loyalty, profiles by age and gender, their interests, catchment area and whether they are an international visitor.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Users />}>
            <h1>Profile at location</h1>
            <p>Insights on visitor composition for a specific location enabling better ROI for marketing/strategic initiatives.</p>
          </IconCart>
          <IconCart icon={<Calendar />}>
            <h1>Recency</h1>
            <p>We are capturing insight in real-time enabling customers to benefit from yesterday’s data today.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Scale & accuracy</h1>
            <p>
              Our sample represents 1/3 of the UK population, with an expansion algorithm for deep understanding into accurate footfall and
              trends.
            </p>
          </IconCart>
          <IconCart icon={<Stand />}>
            <h1>Trend analysis</h1>
            <p>
              As the daily insight builds up over time, you will easily be able to spot trends and predict what is likely to happen in the
              future.
            </p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Integrated</h1>
            <p>Available as an online dashboard or linked to existing planning tools through an API.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>The solution is readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<Idea />}>
            <h1>Unique</h1>
            <p>No other solution can deliver the breadth of insights and sample scale that we provide.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Customer success stories</h1>
        </hgroup>
        <LandscapeCart>
          <img src={storiesImage1} />
          <article>
            <h2>Accurate location-data insights drive a deeper understanding of crowd-level visitor volumes, profiles and patterns.</h2>
            <p>
              “O<sub>2</sub>&apos; Motion’s Micro Location Insights gave us unparalleled information about visitors and potential visitors
              to our clients’ properties. It’s given us a sound basis to optimise our marketing spend to increase visitors and helped us
              market space to potential occupiers.”
            </p>
            <i>Charles Owen, Property Director at Shaftesbury PLC</i>
            <br />
            <ButtonLink>
              <a
                className="quaternary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/1067287/O2_Business_Micro_Location_Insights_0121.055_FINAL.pdf/fe06a775-7d4e-ae23-68ed-050b28d1e419?version=1.0&t=1615800453580"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>Revealing how people use local businesses for Bath BID</h2>
            <p>
              “Developing this project in conjunction with Movement Strategies and O<sub>2</sub> has been really insightful. We have been
              overwhelmed by how much data is available, and having demographic and spend information has really helped us to understand the
              impact of the pandemic in a much more rounded way than if we had solely been working with visitor numbers.”
            </p>
            <i>Allison Herbert, Bath BID</i>
            <br />
            <ButtonLink>
              <a
                className="quaternary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/1658125/O2_Business_BathBid_0321.057_final1205.pdf/b48b639d-c021-85b0-67c1-ce324a91fac8?version=1.0&t=1623029966510"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={storiesImage2} />
        </LandscapeCart>
      </PageContent>
    </MLIOverviewStyles>
  );
};
