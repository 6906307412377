import { Link } from '@mui/material';
import { PageContent, PageHeader, IconCart, LandscapeCart, ButtonLink } from 'shared/ui';
import { Breadcrumb } from 'shared/ui/Breadcrumb/Breadcrumb';
import { MediaAdvertisingStyles } from './MediaAdvertising.styles';
import { InternationalPhone, Spend, API, Portal, Next } from 'shared/icons';

import headerImage from 'assets/images/media-advertising/header-image.png';
import audienceInsights from 'assets/images/media-advertising/audience-insights.png';
import roadInsights from 'assets/images/media-advertising/road-travel-insights.png';
import realTimeTravelInsights from 'assets/images/media-advertising/real-time-travel-insights.png';
import realTimeLocationInsights from 'assets/images/media-advertising/real-time-location-insights.png';
import railInsights from 'assets/images/media-advertising/rail-insights.png';

import greyBackground from 'assets/images/media-advertising/grey-background.png';
import { links } from 'shared/constants';

export const MediaAdvertising = () => {
  return (
    <MediaAdvertisingStyles>
      <PageHeader image={headerImage}>
        <h1>Media & Advertising</h1>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href="/">
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href="/industries">
          INDUSTRIES
        </Link>
        <Link underline="none" aria-current="page">
          MEDIA & ADVERTISING
        </Link>
      </Breadcrumb>
      <PageContent className="description">
        <hgroup>
          <h1>Improve Campaign planning with insights on behavioural audiences around the UK</h1>
        </hgroup>
        <article>
          <p>
            Using precise reporting on audience reach you can show your clients the impact of media spend. Instead of guestimating the reach
            and frequency of ads, you’ll have real data to map against performance metrics. You can also offer OOH campaigns targeted at
            certain groups of travellers just as easily as with digital channels. Plus you can dynamically serve the right ads at the right
            time to maximise overall exposure.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Spend />}>
            <h1>Better ROI for Campaign Planning</h1>
            <p>Capability to identify target audiences based on online and offline behaviours.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Easy to adopt</h1>
            <p>Data can be accessed via API, Blob storage or our Portal, for easy integration into your workflows.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>Our solutions are readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Large scale</h1>
            <p>Our data is obtained from 35% of the UK population and expanded to fairly represent the entirety of the UK’s population.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Related Products</h1>
        </hgroup>
        <LandscapeCart className="landscape">
          <img src={audienceInsights} />
          <article>
            <h2>{links.products.pages.audienceInsights.label}</h2>
            <p>Tailor your advertising and media buying to specific audiences for maximum impact.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.audienceInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart className="landscape">
          <article>
            <h2>{links.products.pages.railInsights.label}</h2>
            <p>Insight into how travellers in the UK move across the rail transport network.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.railInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={railInsights} />
        </LandscapeCart>
        <LandscapeCart className="landscape">
          <img src={realTimeLocationInsights} />
          <article>
            <h2>{links.products.pages.realTimeLocationInsights.label}</h2>
            <p>Real-time data on the number and profiles of people in an area.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.realTimeLocationInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart className="landscape">
          <article>
            <h2>{links.products.pages.realTimeTravelInsights.label}</h2>
            <p>Real-time traffic monitoring across road networks using mobile network data.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.realTimeTravelInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={realTimeTravelInsights} />
        </LandscapeCart>
        <LandscapeCart className="landscape">
          <img src={roadInsights} />
          <article>
            <h2>{links.products.pages.roadInsights.label}</h2>
            <p>Insights into how travellers in the UK move across the road network.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.roadInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
      </PageContent>
      <div className="grey-background" style={{ backgroundImage: `url(${greyBackground})` }}>
        <PageContent className="read-case-study">
          <hgroup>
            <h2>Learn how we helped Blis, leading location-powered programmatic advertising partner, target customers better.</h2>
            <ButtonLink>
              <a
                className="secondary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/4797679/BLIS+O2_Business_Blis.pdf/2182bd73-37f4-e405-67ff-14c74a8bcd70?t=1647945401904"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </hgroup>
          <article>
            <p>
              “Detailed, granular audience insight is essential for us to deliver the most effective service for our clients and O2 Motion
              is the only company to bring this level of telco behavioural targeting to advertisers in the UK.”
            </p>
            <div>
              <span>Charlie Smith</span>
              <br />
              <span>Managing Director Europe, Blis</span>
            </div>
          </article>
        </PageContent>
      </div>
    </MediaAdvertisingStyles>
  );
};
