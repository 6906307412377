import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { Loader } from 'shared/ui';
import { getAuthState } from '../lib/authAzure';
import { AuthContext } from './AuthProvider';
import { SignIn } from './SignIn';
// import { withTracking } from '../../lib/insights';

export const RequireLogin = (props: { children?: ((p: PropsWithChildren<{}>) => React.ReactNode) | React.ReactNode }) => {
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (state === undefined) {
      getAuthState().then((authState) => {
        return dispatch({ type: 'SET_AUTH', payload: authState });
      });
    }
  }, [state, dispatch]);

  if (state === undefined) {
    return <Loader />;
  }

  if (state.isAuthenticated) {
    return <>{props.children}</>;
  }

  return <SignIn onSignIn={(authState) => dispatch({ type: 'SET_AUTH', payload: authState })} />;
};

// export default withTracking(RequireLogin);
// export default RequireLogin;
