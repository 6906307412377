import { createGlobalStyle } from 'styled-components';
import frutiger45lighteot from '../../assets/fonts/frutiger-45light.eot';
import frutiger45lightwoff from '../../assets/fonts/frutiger-45light.woff';
import frutiger45lightttf from '../../assets/fonts/frutiger-45light.ttf';
import frutiger45lightsvg from '../../assets/fonts/frutiger-45light.svg';
import frutiger48blackttf from '../../assets/fonts/frutiger-48black.ttf';
import frutiger55romaneot from '../../assets/fonts/frutiger-55roman.eot';
import frutiger55romanwoff from '../../assets/fonts/frutiger-55roman.woff';
import frutiger55romanttf from '../../assets/fonts/frutiger-55roman.ttf';
import frutiger55romansvg from '../../assets/fonts/frutiger-55roman.svg';
import frutiger65boldeot from '../../assets/fonts/frutiger-65bold.eot';
import frutiger65boldwoff from '../../assets/fonts/frutiger-65bold.woff';
import frutiger65boldttf from '../../assets/fonts/frutiger-65bold.ttf';
import frutiger65boldsvg from '../../assets/fonts/frutiger-65bold.svg';

import frutigeboldregularttf from '../../assets/fonts/frutiger-boldregular.ttf';
import frutigerbqregularotf from '../../assets/fonts/frutiger-bqregular.otf';
import frutiger66boldItalicttf from '../../assets/fonts/frutiger66-boldItalic.ttf';
import motionBg from '../../assets/images/motion-bg.png';
import { BASE_FONT_SIZE } from '../constants/theme';

/*
  ----- GLOBAL STYLES -----
*/
const StyledGlobal = createGlobalStyle`
  /*
  PLEASE NOTE:

  Material UI CSSBaseline is included in /src/index.tsx to set default styles
  https://material-ui.com/components/css-baseline/

  This is similar to normalize, use /src/styles/MaterialTheme.ts to override:
  https://material-ui.com/customization/themes/
  */


  /* ---- FONTS ---- */
  @font-face {
    font-family: Frutiger;
    src: url(${frutiger45lighteot});
    src: url(${frutiger45lighteot}#iefix)
        format('embedded-opentype'),
      url(${frutiger45lightwoff}) format('woff'),
      url(${frutiger45lightttf}) format('truetype'),
      url(${frutiger45lightsvg}#Frutiger45Light)
        format('svg');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger45lighteot});
    src: url(${frutiger45lighteot}#iefix)
        format('embedded-opentype'),
      url(${frutiger45lightwoff}) format('woff'),
      url(${frutiger45lightttf}) format('truetype'),
      url(${frutiger45lightsvg}#Frutiger45Light)
        format('svg');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger45lighteot});
    src: url(${frutiger45lighteot}#iefix)
        format('embedded-opentype'),
      url(${frutiger45lightwoff}) format('woff'),
      url(${frutiger45lightttf}) format('truetype'),
      url(${frutiger45lightsvg}#Frutiger45Light)
        format('svg');
    font-weight: 300;
    font-style: normal;
  }

  /* @font-face {
    font-family: Frutiger;
    src: url(${frutiger66boldItalicttf});
    src: url(${frutiger66boldItalicttf}) format('truetype');
    font-weight: 400;
    font-style: italic;
  } */

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger55romaneot});
    src: url(${frutiger55romaneot}#iefix)
        format('embedded-opentype'),
      url(${frutiger55romanwoff}) format('woff'),
      url(${frutiger55romanttf}) format('truetype'),
      url(${frutiger55romansvg}#Frutiger55)
        format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: Frutiger;
    src: url(${frutiger65boldeot});
    src: url(${frutiger65boldeot}#iefix)
        format('embedded-opentype'),
      url(${frutiger65boldwoff}) format('woff'),
      url(${frutiger65boldttf}) format('truetype'),
      url(${frutiger65boldsvg}#Frutiger65Bold)
        format('svg');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Frutiger Bold';
    src: url(${frutigerbqregularotf}#iefix), url(${frutigeboldregularttf}) format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Frutiger Bold';
    src: url(${frutigerbqregularotf}#iefix), url(${frutigeboldregularttf}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Frutiger Bold';
    src: url(${frutigerbqregularotf}#iefix), url(${frutigeboldregularttf}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Frutiger Bold';
    src: url(${frutigerbqregularotf}#iefix), url(${frutigeboldregularttf}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Frutiger Bold';
    src: url(${frutigerbqregularotf}#iefix), url(${frutigeboldregularttf}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Frutiger Bold';
    src: url(${frutigerbqregularotf}#iefix), url(${frutigeboldregularttf}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Frutiger Bold';
    src: url(${frutigerbqregularotf}#iefix), url(${frutigeboldregularttf}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Frutiger Bold';
    src: url(${frutigerbqregularotf}#iefix), url(${frutigeboldregularttf}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Frutiger Bold';
    src: url(${frutigerbqregularotf}#iefix), url(${frutigeboldregularttf}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Frutiger Black';
    src: url(${frutiger48blackttf}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  /* ---- BASE ---- */
  html {
    /*
    Font size based on 10px simplification
    https://www.sitepoint.com/understanding-and-using-rem-units-in-css/

    10px is a much easier base value to work out rem values, e.g.
      34px = 3.4rem

    PLEASE NOTE:
    This same value is used in /src/styles/MaterialTheme.ts to apply the
    same effect to Material UI components
    */
    font-size: ${BASE_FONT_SIZE}px;

    /* For background gradiant */
    min-height: 100vh;
    scroll-behavior: smooth;
  }

  body {
    background-color: white !important;
    height: 100%;
    --page-width: clamp(1196px, 75%, 1440px);
    --min-page-width: 1196px;
    --footer-height: 116px;
  }

  sup, sub {
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    z-index: 0;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  .page-content {
    min-height: calc(100vh - var(--footer-height) + 1px);
    min-width: var(--min-page-width);
  }

  .grey-background {
    background-color: #f4f4f4;
    background-size: cover;
    color: white;
  }
  
  .motion-bg {
    background: url(${motionBg}) no-repeat bottom -11rem left -11rem;
  }

  /* Override for modal internal scrolling */
  .overrideScroll {
    /* overflow: visible; */
  }

  .dialog {
    /* Find a better way to do this */
    /* DO NOT USE A VALUE --> Higher than 1100 Anywhwere esle */
    z-index: 1100 !important;
  }

  .center {
        display: flex;
        place-content: center;
        place-items: center;
    }

  /* Stop click on markers preventing click beneath (to deselect GeoJSON region) */
  .marker-click-through {
    pointer-events: none !important;
  }
  .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-filled.MuiFormLabel-root.Mui-focused {
    color: #2393CF;
  }

  .tree-select-subheader.MuiListSubheader-root {
    font-size: 1.6rem;
  }

  .MuiList-root .MuiCheckbox-root.MuiCheckbox-colorPrimary {
    color: ${(props: any) => props.theme.palette.primary.main};
  }
  .centered {
    text-align: center;
  }
  .navigation-button-centered {
    display: flex;
    justify-content: center;
  }
  table.styled-table {
    border-collapse: collapse;
    td {
      border: 1px solid #0090d0;
      padding: 0.5rem 1rem;
    }
    tr:nth-child(odd){
      background-color: #0019a5;
    }
    tr:nth-child(even){
      background-color: rgba(0, 25, 165, 0.5);
    }

  }

  .MuiSelect-root {
    direction: rtl;
    text-align: left;
    .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.background.paper};
    }
  }

  .toast-download-progress {
    display: flex;
    align-items: center;
    .progress-wrapper {
      /* color: ${(props) => props.theme.palette.background.default}; */
    }
    .toast-download-label {
      margin-left: 0.5rem;
    }
  }

  .Toastify__toast-container {
    & > :first-child {
      margin-right: 5rem;
    }
  }
  .Toastify__progress-bar.Toastify__progress-bar--controlled.Toastify__progress-bar-theme--light {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiTabs-fixed  {
    .MuiTabs-indicator {
      background:  none;
    }
    button {
      color:#0019A5;
      text-transform: none;
      min-height: 32px;
      height: 32px;
      padding: 0 10px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      margin-right: 30px;
      &.Mui-selected:not(.Mui-disabled) {
        background: #E5E8F6;
      }
    }
  }

  .MuiButtonBase-root.MuiButton-contained.MuiButton-root {
    text-transform: none;
    font-weight: 700;
    letter-spacing: -0.02em;
    padding: 8px 24px;
    font-size: 16px;
    line-height: 24px;

    &.Mui-disabled  {
      background: #808CD2;
      color: white;
    }
  }

  .MuiButtonBase-root.MuiButton-outlinedPrimary.MuiButton-root {
    text-transform: none;
    font-weight: 700;
    letter-spacing: -0.02em;
    padding: 8px 24px;
    font-size: 14px;
    line-height: 24px;

  }

  .MuiButton-text.MuiButtonBase-root.MuiButton-root {
    padding-left: 24px;
    padding-right: 24px;
    letter-spacing: -0.02em;
    background-color: white;
    font-weight: normal;
    text-transform: none;


    &:hover {
      background: #808CD2;
      color:#000066;
    }
  }

  div.swagger-ui {
    background: ${(props) => props.theme.palette.background.paper};
    border: 1px solid transparent;

    div.wrapper button, div.model-container, td{
      text-align: left;
    }
  }

  a {
    text-decoration: none;
    color: #0019A5;

  }
  ul, ol {
    margin: 0;
  }

  .MuiAutocomplete-popper .MuiAutocomplete-paper {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    letter-spacing: -0.02em;
    color: #878787;
  }
  .MuiAutocomplete-option {
    display: flex;
    justify-content: space-between !important;

    &:hover {
      background: #f5f5f5 !important;
    }
    &[aria-selected=true] {
      color: #0019A5;
      &:after {
        content: '✖';
      }
    }
    &[aria-selected=true]:not(:hover) {
      background: white !important;
    }
  }
  .single-select .MuiAutocomplete-option[aria-selected=true]:after {
    display: none;
  }

`;

export default StyledGlobal;
