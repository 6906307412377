import { ReactNode } from 'react';
import { IconCartStyles } from './IconCart.styles';

interface IconSectionProps {
  icon: ReactNode;
  children: ReactNode | string;
  className?: string;
}

export const IconCart = (props: IconSectionProps) => {
  return (
    <IconCartStyles className={props.className}>
      <div className="columns-wrapper">
        <div className="icon">{props.icon}</div>
        <div className="content">{props.children}</div>
      </div>
    </IconCartStyles>
  );
};
