import { TreeStructure } from '../models/TreeStructure';

export interface ActionReducerParams<T> {
  checked?: boolean;
  node?: TreeStructure<T>;
  name: 'Toggle' | 'Reset';
}

function getAllFolderNodes<T>(node: TreeStructure<T>): TreeStructure<T>[] {
  const elements = node.elements || [];
  const res: TreeStructure<T>[] = [node, ...elements];
  elements.forEach((e) => res.push(...getAllFolderNodes(e)));
  return res;
}

function checkParents<T>(newState: Set<TreeStructure<T>>, node: TreeStructure<T>) {
  if (node.parent) {
    const parent = node.parent;
    const checked = parent.elements?.every((e) => newState.has(e));
    if (checked) newState.add(parent);
    else newState.delete(parent);
    checkParents(newState, parent);
  }
}
function toggleFolder<T>(state: Set<TreeStructure<T>>, checked: boolean, node: TreeStructure<T>) {
  const nodes = getAllFolderNodes(node);
  const newState = new Set(state);
  if (checked) {
    nodes.forEach((e) => newState.add(e));
  } else {
    nodes.forEach((e) => newState.delete(e));
  }
  checkParents(newState, node);
  return newState;
}

function toggleNode<T>(state: Set<TreeStructure<T>>, checked: boolean, node: TreeStructure<T>) {
  if (node.elements) {
    return toggleFolder(state, checked!, node!);
  }
  const newState = new Set(state);
  if (checked) newState.add(node!);
  else newState.delete(node!);
  checkParents(newState, node);
  return newState;
}

export const treeCheckboxReducer = <T>(state: Set<TreeStructure<T>>, { checked, node, name }: ActionReducerParams<T>) => {
  switch (name) {
    case 'Toggle':
      return toggleNode(state, checked!, node!);
    case 'Reset':
      return new Set<TreeStructure<T>>();
  }
};
