import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AuthApi } from 'auth/lib/AuthApi';
import { ComponentType } from 'react';
import { createBrowserHistory } from 'history';
import { PubSub } from './PubSub';

export const appInsightsReactPlugin = new ReactPlugin();

export const browserHistory = createBrowserHistory();
export const historyPublisher = new PubSub();
browserHistory.listen((d) => historyPublisher.emit(d));

export async function initializeAppInsights(email: string) {
  if (process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY) {
    const userType = (await AuthApi.getUserMetadata()).userType;
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
        extensions: [appInsightsReactPlugin],
        // enableAutoRouteTracking: true,
        extensionConfig: {
          [appInsightsReactPlugin.identifier]: { history: { listen: (f: (data: any) => any) => historyPublisher.subscribe(f) } },
        },
      },
    });

    appInsights.addTelemetryInitializer((e) => {
      const item = e.baseData!;
      item.properties = item.properties || {};
      item.properties.userType = userType;
    });
    appInsights.loadAppInsights();
    appInsights.setAuthenticatedUserContext(email);
  }
}
export const withTracking = <T>(Component: ComponentType<T>) =>
  appInsightsReactPlugin ? withAITracking(appInsightsReactPlugin, Component) : Component;
