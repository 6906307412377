import styled from 'styled-components';

export const AboutStyles = styled.section`
  hgroup {
    color: #0019a5;
    font-style: normal;
    text-align: center;
    letter-spacing: -0.02em;

    h1 {
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;

      strong {
        font-family: 'Frutiger Black';
      }
    }

    h3 {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #0019a5;
    }
  }

  .header {
    article > section {
      margin-top: 24px;
    }

    a.secondary {
      margin-right: 25px;
    }
  }

  .insights-carts,
  .population-carts {
    display: grid;
    grid-gap: 100px;
    justify-content: space-around;
    grid-template-columns: repeat(6, 1fr);
    padding: 100px 0;

    > section {
      grid-column: span 2;

      &:last-child:nth-child(3n-1) {
        grid-column-end: -2;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      &:last-child:nth-child(3n-2) {
        grid-column-end: 5;
      }
    }
  }

  .diverse-carts {
    display: grid;
    grid-row-gap: 100px;
    justify-content: space-around;
    padding: 100px 0;
  }

  .insights-buttons,
  .contact-buttons,
  .diverse-buttons {
    display: flex;
    place-items: center;
    place-content: center;
  }

  .pandemic article {
    > div {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }

    p.ons {
      font-family: 'Frutiger Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #575757;
    }
  }

  .contact {
    padding: 100px 0;

    p {
      margin: 40px 0;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #575757;
    }
  }
`;
