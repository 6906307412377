import styled from 'styled-components';

export const UserMenuStyles = styled.section`
  display: flex;
  align-items: center;
  color: white;
  position: relative;
  margin-left: auto;

  .label {
    padding: 35px 0px;
    width: max-content;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    & svg {
      margin-left: 1rem;
    }
  }

  .menu-items {
    position: absolute;
    left: 20px;
    top: 88px;
    z-index: 999;
  }

  ul {
    padding: 0;
  }
`;
