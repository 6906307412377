import { Link } from '@mui/material';
import { PageContent, PageHeader, IconCart, LandscapeCart, ButtonLink, Breadcrumb } from 'shared/ui';
import { InternationalPhone, Spend, API, Portal, Next, MapBoard } from 'shared/icons';
import { links } from 'shared/constants';
import { LeisureTourismStyles } from './LeisureTourism.styles';

import headerImage from 'assets/images/leisure-tourism/header-image.png';
import audienceInsights from 'assets/images/leisure-tourism/audience-insights.png';
import microLocationInsights from 'assets/images/leisure-tourism/micro-location-insights.png';
import movementInsights from 'assets/images/leisure-tourism/movement-insights.png';
import spatialInsights from 'assets/images/leisure-tourism/spatial-insights.png';
import greyBackground from 'assets/images/leisure-tourism/grey-background.png';

export const LeisureTourism = () => {
  return (
    <LeisureTourismStyles>
      <PageHeader image={headerImage}>
        <h1>{links.industries.pages.leisureTourism.label}</h1>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.industries.link}>
          {links.industries.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.industries.pages.leisureTourism.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <PageContent className="description">
        <hgroup>
          <h1>Improve planning and performance with insight on your visitors</h1>
        </hgroup>
        <article>
          <p>
            Whether you&apos;re planning to locate new venues or enhance the performance of existing venues you can understand visitor
            profiles and their true catchment area based on how far people tend to travel and where from. By modelling social demographics
            and movement trends you can reveal the affluence of different groups.
          </p>
          <br />
          <p>
            You can also plan for peak periods while also being able to understand when, where and who to direct promotions to by merging
            current and historical data.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Spend />}>
            <h1>Better ROI for Strategic Initiatives</h1>
            <p>Benefit from a better understanding of which marketing or strategic initiatives will return better ROI.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Easy to adopt</h1>
            <p>Data can be accessed via API, Blob storage or our Portal, for easy integration into your workflows.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>Our solutions are readily available to be deployed</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Large scale</h1>
            <p>Captures a fully representative sample of a third of the UK population.</p>
          </IconCart>
          <IconCart icon={<MapBoard />}>
            <h1>Case studies</h1>
            <p>Excellent case studies from customers who have been using this for over 2 years.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Related Products</h1>
        </hgroup>
        <LandscapeCart>
          <img src={audienceInsights} />
          <article>
            <h2>{links.products.pages.audienceInsights.label}</h2>
            <p>Tailor your advertising and media buying to specific audiences for maximum impact.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.audienceInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.microLocationInsights.label}</h2>
            <p>Local, location-based insights on visitors so you can improve performance measurement.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.microLocationInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={microLocationInsights} />
        </LandscapeCart>
        <LandscapeCart>
          <img src={movementInsights} />
          <article>
            <h2>Movement Insights</h2>
            <p>Low latency journey and location data across the UK and internationally.</p>
            <ButtonLink>
              <a className="quaternary" href="/products/mi">
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.spatialInsights.label}</h2>
            <p>Video analytics and AI to deliver insights around customer movement and flow in a venue.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.spatialInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={spatialInsights} />
        </LandscapeCart>
      </PageContent>
      <div className="grey-background" style={{ backgroundImage: `url(${greyBackground})` }}>
        <PageContent className="read-case-study">
          <hgroup>
            <h2>
              Learn how our travel data and credit/debit card insights offer invaluable understandings for post-Covid business recovery for
              Bath BID.
            </h2>
            <ButtonLink>
              <a
                className="secondary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/1658125/O2_Business_BathBid_0321.057_final1205.pdf/b48b639d-c021-85b0-67c1-ce324a91fac8?version=1.0&t=1623029966510"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </hgroup>
          <article>
            <p>
              “We have been overwhelmed by how much data is available, and having demographic and spend information has really helped us to
              understand the impact of the pandemic in a much more rounded way than if we had solely been working with visitor numbers.”
            </p>
            <div>
              <span>Alison Herbert</span>
              <br />
              <span>Bath BID</span>
            </div>
          </article>
        </PageContent>
      </div>
    </LeisureTourismStyles>
  );
};
