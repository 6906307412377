export const Map = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.1635 6.03671C27.4781 2.5971 24.4924 0 20.9237 0C17.355 0 14.3693 2.5971 13.6839 6.03671H0V32H32V6.03671H28.1635ZM20.9237 2.41159C23.6416 2.41159 25.8474 4.71498 25.8474 7.54396C25.8474 9.45314 23.1452 14.2686 20.9237 17.7082C18.7021 14.2763 16 9.45314 16 7.54396C16 4.71498 18.2058 2.41159 20.9237 2.41159ZM2.4579 13.1942C3.18267 13.5343 3.68685 14.2531 3.68685 15.0957V16.9043C3.68685 16.9662 3.69473 17.028 3.70261 17.0899C4.11226 19.687 6.50714 20.3826 8.43722 20.9391C10.7533 21.6116 11.6908 22.0367 11.6908 23.5517V25.6C11.6908 25.6541 11.6908 25.7082 11.6987 25.77C11.7853 27.2541 12.3447 28.5604 13.2506 29.5884H2.4579V13.1942ZM29.5421 29.5884H19.0803C16.709 29.5884 14.1566 28.2667 14.1566 25.3604V23.5517C14.1566 20.0812 11.2575 19.2386 9.13835 18.628C7.22403 18.0715 6.3417 17.7237 6.15263 16.8039V15.0957C6.15263 12.9082 4.5613 11.0763 2.4579 10.6589V8.45604H13.6366C14.3772 12.4522 19.3008 19.7256 19.9074 20.6068C20.1359 20.9391 20.5219 21.1401 20.9316 21.1401C21.3412 21.1401 21.7272 20.9391 21.9557 20.6068C22.5544 19.7256 27.486 12.4522 28.2265 8.45604H29.55V29.5884H29.5421ZM24.1615 6.94106C24.1615 5.18647 22.7041 3.76425 20.9237 3.76425C19.1354 3.76425 17.6859 5.1942 17.6859 6.94106C17.6859 8.68792 19.1354 10.1179 20.9237 10.1179C22.712 10.1179 24.1615 8.69565 24.1615 6.94106ZM20.9237 8.30918C20.1516 8.30918 19.5293 7.69855 19.5293 6.94106C19.5293 6.18357 20.1516 5.57295 20.9237 5.57295C21.6957 5.57295 22.3181 6.18357 22.3181 6.94106C22.3181 7.69855 21.6957 8.30918 20.9237 8.30918Z"
        fill="#0019A5"
      />
    </svg>
  );
};
