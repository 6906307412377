export const MapBoard = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 3.50058H21.4976V1.99658H10.5024V3.49418H8C6.3488 3.49418 4.9984 4.84458 4.9984 6.49578V29.9966H27.0016V6.50218C27.0016 4.85098 25.6512 3.50058 24 3.50058ZM11.9488 3.50058H20V6.00298H11.9488V3.50058ZM24.9984 27.9998H7.0016V16.499H8C8.2496 16.499 8.5504 16.3518 8.6528 16.1022L10.4512 12.755L13.8496 18.6046C13.9968 18.8542 14.2464 18.9566 14.5024 18.9566C14.752 18.9566 15.0016 18.8094 15.1552 18.6046L17.7024 14.2078L18.8032 16.1086C19.0016 16.3582 19.2512 16.5054 19.5008 16.5054H21.4976C21.8944 16.5054 22.2464 16.1534 22.2464 15.7566C22.2464 15.3598 21.8944 15.0078 21.4976 15.0078H19.9488L18.4 12.3518C18.2528 12.1534 17.9968 11.9998 17.7472 11.9998C17.4976 11.9998 17.248 12.147 17.0944 12.3518L14.5472 16.755L11.0976 10.8542C10.9504 10.6046 10.7008 10.5022 10.4448 10.5022C10.1888 10.5022 9.9456 10.6494 9.792 10.899L7.552 15.0014H7.0016V6.50218C7.0016 5.95178 7.4496 5.50378 8 5.50378H10.5024V7.50058H21.504V5.49738H24C24.5504 5.49738 24.9984 5.94538 24.9984 6.49578V27.9998ZM21.7472 22.5022H10.2528C9.856 22.5022 9.504 22.8542 9.504 23.251C9.504 23.6478 9.856 23.9998 10.2528 23.9998H21.7536C22.1504 23.9998 22.5024 23.6478 22.5024 23.251C22.5024 22.8542 22.1504 22.5022 21.7472 22.5022Z"
        fill="#0019A5"
      />
    </svg>
  );
};
