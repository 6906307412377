import { Navigate, Route, Routes } from 'react-router-dom';
import { Breadcrumb, PageHeader } from 'shared/ui';
import { links, SharedConsts } from 'shared/constants';
import { Link } from '@mui/material';

import { SIOverview } from './components/overview/SIOverview';
import { SISpecification } from './components/specifications/SISpecification';

import { SpatialInsightsStyles } from './SpatialInsights.styles';
import headerImage from 'assets/images/si/header-image.png';

const SpatialInsights = () => {
  const menu = [
    { label: 'Product Overview', link: 'overview' },
    { label: 'Data Dashboard', link: SharedConsts.siDashboardIframeUrl },
    { label: 'Specifications', link: 'specifications' },
  ];

  return (
    <SpatialInsightsStyles>
      <PageHeader image={headerImage} menus={menu}>
        <h1>{links.products.pages.spatialInsights.label}</h1>
        <p>
          Spatial Insights uses video analytics and Artificial Intelligence to deliver <br /> insights around customer movement and flow
          inside a venue.
        </p>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.products.link}>
          {links.products.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.products.pages.spatialInsights.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <Routes>
        <Route path="overview" element={<SIOverview />} />
        <Route path="specifications" element={<SISpecification />} />
        <Route path="" element={<Navigate to="overview" replace={true} />} />
      </Routes>
    </SpatialInsightsStyles>
  );
};

export default SpatialInsights;
