import { AuthContext } from './AuthProvider';
import { useContext } from 'react';
import { signOut } from '../lib/authAzure';

import { LoggingOutStyles } from './LoggingOut.Styles';

export const LoggingOut = () => {
  const { dispatch } = useContext(AuthContext);

  const signOutHandler = async () => {
    await signOut();

    dispatch({ type: 'SET_AUTH', payload: { isAuthenticated: false } });
  };
  signOutHandler();
  return (
    <LoggingOutStyles>
      <h1>Logging out</h1>
    </LoggingOutStyles>
  );
};
