import styled from 'styled-components';

export const RTIDashboardStyles = styled.section`
  padding: 50px 0 0;

  .dashboard {
    padding: 80px 0;
  }

  .MuiFormControl-root {
    margin: 4rem;
  }
`;
