import { ReactNode } from 'react';
import { ButtonLinkStyles } from './ButtonLink.styles';

interface ProductHeaderProps {
  children: ReactNode | string;
}

export const ButtonLink = (props: ProductHeaderProps) => {
  return <ButtonLinkStyles>{props.children}</ButtonLinkStyles>;
};
