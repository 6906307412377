import styled from 'styled-components';

export const LandscapeCartStyles = styled.section`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-column-gap: 80px;
  padding: 50px 0;

  &:nth-child(odd) {
    grid-template-columns: 1.5fr 1fr;
  }

  img {
    display: block;
  }

  h2 {
    margin: inherit;
    color: #575757;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
  }

  p,
  i {
    margin: 1em inherit;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #575757;
  }

  article {
    display: grid;

    > section {
      align-self: end;
    }
  }
`;
