export function distinct<T, V>(data: T[], callback: (value: T) => V): T[] {
  const map = data.reduce((m, v) => {
    const key = callback(v);
    if (!m.has(key)) {
      m.set(key, v);
    }
    return m;
  }, new Map<V, T>());
  return Array.from(map.values());
}
