export class PubSub<T = any> {
  private subscribers: Function[] = [];
  emit(data: T) {
    this.subscribers.forEach((c) => c(data));
  }

  subscribe(s: (data: T) => any) {
    this.subscribers.push(s);
    return () => {
      this.subscribers = this.subscribers.filter((e) => e !== s);
    };
  }

  size() {
    return this.subscribers.length;
  }
}
