import { NavLink } from 'react-router-dom';
import { Cancel, Tick } from 'shared/icons';
import { links } from 'shared/constants';
import { PageContent, Specification } from 'shared/ui';
import { MLISpecificationStyles } from './MLISpecificationStyles.styles';

export const MLISpecification = () => {
  return (
    <MLISpecificationStyles>
      <PageContent>
        <Specification>
          <hgroup>
            <h1>Data outputs</h1>
          </hgroup>
          <article>
            <h2>Total number of UK residents over the age of 12 & all international visitors (any age) at your location</h2>
            <p>
              <ul>
                <li>Age band (starting at 18, e.g. 18-24, then 10 year bands 25-34, 35-44...)</li>
                <li>Gender</li>
                <li>Spend power (high, medium, low)</li>
                <li>Resident / worker / visitor status</li>
                <li>Visit frequency</li>
                <li>International visitors by country</li>
                <li>Dwell time of visit</li>
                <li>Web interests (e.g. shopping, business)</li>
                <li>Work and home location at postcode sector, district or area</li>
              </ul>
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data features</h1>
          </hgroup>
          <article>
            <h2>Geographic coverage</h2>
            <p>Can be purchased for specific areas of the UK, or venues</p>
          </article>
          <article>
            <h2>Temporal granularity</h2>
            <p>Hour, day, week, month or year</p>
          </article>
          <article>
            <h2>Spatial granularity</h2>
            <p>Radius configurable from 2m to 50m</p>
          </article>
          <article>
            <h2>Expansion to the whole population</h2>
            <p>All outputs are expanded to fairly represent the entire UK population</p>
          </article>
          <article>
            <h2>Disclosure controls (privacy protection)</h2>
            <p>
              Any expanded profile count (e.g. number of males) below 10 will be replaced with 0. Outputs which could result in counts below
              10 being inferred will also be replaced with 0.
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data collection</h1>
          </hgroup>
          <article>
            <h2>Collection equipment</h2>
            <p>Micro-location mobile network cells installed at your location</p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data delivery</h1>
          </hgroup>
          <article>
            <h2>Frequency and latency</h2>
            <p>Refreshed every day, by 9am, for the previous day</p>
          </article>
          <article>
            <h2>Historical data</h2>
            <p>Data is available from contract signature and can be queried retrospectively</p>
          </article>
          <article>
            <h2>Channels</h2>
            <p>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      Low latency data <br /> (Weekly)
                    </th>
                    <th>Historical data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Portal visualisations <br /> (Tableau dashboard)
                    </td>
                    <td>
                      <Tick />
                    </td>
                    <td>
                      <Cancel />
                      <br /> No pre-contract history available, but the data produced in contract can be queried retrospectively for 25
                      months
                    </td>
                  </tr>
                  <tr>
                    <td>
                      REST API <br /> (JSON format)
                    </td>
                    <td>
                      <Tick />
                    </td>
                    <td>
                      <Cancel />
                      <br /> No pre-contract history available, but the data produced in contract can be queried retrospectively for 25
                      months
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Fancy trying it for yourself?</h1>
          </hgroup>
          <p>
            See sample data <NavLink to={`${links.products.pages.microLocationInsights.link}/dashboard`}>here</NavLink>. For sample CSV
            data, please contact us at{' '}
            <a target="_blank" href="mailto:o2motion@o2.com" rel="noreferrer">
              o2motion@o2.com
            </a>{' '}
            to discuss your needs.
          </p>
        </Specification>
      </PageContent>
    </MLISpecificationStyles>
  );
};
