import { Link } from '@mui/material';
import { PageContent, PageHeader, IconCart, LandscapeCart, ButtonLink, Breadcrumb } from 'shared/ui';
import { InternationalPhone, Spend, API, Portal, Idea, Next } from 'shared/icons';
import { links } from 'shared/constants';
import { RetailStyles } from './Retail.styles';

import headerImage from 'assets/images/retail/header-image.png';
import audienceInsights from 'assets/images/leisure-tourism/audience-insights.png';
import microLocationInsights from 'assets/images/leisure-tourism/micro-location-insights.png';
import movementInsights from 'assets/images/retail/movement-insights.png';
import spatialInsights from 'assets/images/retail/spatial-insights.png';
import greyBackground from 'assets/images/retail/grey-background.png';

export const Retail = () => {
  return (
    <RetailStyles>
      <PageHeader image={headerImage}>
        <h1>{links.industries.pages.retail.label}</h1>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.industries.link}>
          {links.industries.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.industries.pages.retail.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <PageContent className="description">
        <hgroup>
          <h1>Improve planning and performance with insight on the movements of people in and around your stores</h1>
        </hgroup>
        <article>
          <p>
            Whether you are planning to locate new stores or enhance the performance of existing stores, you can understand visitor profile
            and the real catchment area based on how far people tend to travel and where from. By modelling social demographics and movement
            trends you can reveal the affluence of different groups. You can also plan for peak periods while also being able to understand
            when, where and who to direct promotions to by merging current and historical data.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Spend />}>
            <h1>Better ROI for Strategic Initiatives</h1>
            <p>Benefit from a better understanding of which marketing or strategic initiatives will return better ROI.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Easy to adopt</h1>
            <p>Data can be accessed via API, Blob storage or our Portal, for easy integration into your workflows.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>Our solutions are readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Large scale</h1>
            <p>
              Captures large numbers of the visitors based on O<sub>2</sub> mobile base.
            </p>
          </IconCart>
          <IconCart icon={<Idea />}>
            <h1>Unique</h1>
            <p>No other solution can deliver the breadth of insights and sample scale.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Related Products</h1>
        </hgroup>
        <LandscapeCart>
          <img src={audienceInsights} />
          <article>
            <h2>{links.products.pages.audienceInsights.label}</h2>
            <p>Tailor your advertising and media buying to specific audiences for maximum impact.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.audienceInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.microLocationInsights.label}</h2>
            <p>Local, location-based insights on visitors so you can improve performance measurement.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.microLocationInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={microLocationInsights} />
        </LandscapeCart>
        <LandscapeCart>
          <img src={movementInsights} />
          <article>
            <h2>{links.products.pages.movementInsights.label}</h2>
            <p>Low latency journey and location data across the UK and internationally.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.movementInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.spatialInsights.label}</h2>
            <p>Video analytics and AI to deliver insights around customer movement and flow in a venue.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.spatialInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={spatialInsights} />
        </LandscapeCart>
      </PageContent>
      <div className="grey-background" style={{ backgroundImage: `url(${greyBackground})` }}>
        <PageContent className="read-case-study">
          <hgroup>
            <h2>Learn how Teneo uses our data insight to help businesses understand potential risks and plan high street recovery.</h2>
            <ButtonLink>
              <a
                className="secondary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/2099323/O2_Business_Teneo.pdf/346217ef-1bb1-a9fc-6aee-1b0b431844db?version=1.0&t=1625450704592"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </hgroup>
          <article>
            <p>
              “By combining O<sub>2</sub>’s population behaviour data with a variety of other data sets we can make informed predictions and
              build models for how high streets can recover and build the post-COVID future.”
            </p>
            <div>
              <span>Gee Lefevre</span>
              <br />
              <span>Director, Head of Transport, Hospitality and Consumer Behaviour Teneo</span>
            </div>
          </article>
        </PageContent>
      </div>
    </RetailStyles>
  );
};
