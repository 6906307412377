import { ChangeEvent, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { useGetMetadataLoadingStatus } from 'auth/lib/useGetMetadataLoadingStatus';
import { LoadingDataStates } from 'shared/models/LoadingDataState';

import { PageContent, MoviePage } from 'shared/ui';
import { IframePage } from 'shared/components/IframePage';
import { SharedConsts, movieLinks } from 'shared/constants';

import { RTIDashboardStyles } from './RTIDashboard.styles';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

enum DataType {
  road = 'road',
  routes = 'routes',
  linkViewer = 'link-viewer',
}
interface RTIDashboardProps {
  hasTableauAccess: boolean;
}

export const RTIDashboard = ({ hasTableauAccess }: RTIDashboardProps) => {
  const [dataType, setDataType] = useState<DataType | ''>('');
  const allDataTypes = [DataType.road, DataType.routes, DataType.linkViewer];
  const isMetadataLoaded = useGetMetadataLoadingStatus() === LoadingDataStates.loaded;

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const route = (event.target as any).value as DataType;
    setDataType((event.target as any).value as DataType);
    navigate(route);
  };

  useEffect(() => {
    setDataType(allDataTypes.find((e) => location.pathname.endsWith(e)) ?? '');
  }, [location.pathname]);

  if (!isMetadataLoaded) return <div>loadings...</div>;

  return (
    <RTIDashboardStyles>
      <PageContent className="dashboard">
        {hasTableauAccess ? (
          <>
            <FormControl>
              <RadioGroup row value={dataType} onChange={handleChange}>
                <FormControlLabel value={DataType.road} control={<Radio />} label="Road" />
                <FormControlLabel value={DataType.routes} control={<Radio />} label="Routes" />
                <FormControlLabel value={DataType.linkViewer} control={<Radio />} label="Link Viewer" />
              </RadioGroup>
            </FormControl>
            <Routes>
              <Route path={DataType.road} element={<IframePage src={SharedConsts.roadInsightDashboardIframeUrl} />} />
              <Route path={DataType.routes} element={<IframePage src={SharedConsts.roadInsightsRoutesDashboardIframeUrl} />} />
              <Route path={DataType.linkViewer} element={<IframePage src={SharedConsts.roadInsightsLinksDashboardIframeUrl} />} />
              <Route path="" element={<Navigate to={DataType.road} replace={true} />} />
            </Routes>
          </>
        ) : (
          <MoviePage
            description="The following video captures a few of the key functionalities of the Road Insights dashboard."
            height={676}
            width={1170}
            movieUrl={movieLinks.roadInsights}
            subtitlesUrl="/road-insights.vtt"
          />
        )}
      </PageContent>
    </RTIDashboardStyles>
  );
};
