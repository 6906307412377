import { Button } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { links } from 'shared/constants';
import { MoviePageStyles } from './MoviePage.styles';

interface MoviePageProps {
  description: string | ReactNode;
  movieUrl: string;
  subtitlesUrl: string;
  height: string | number;
  width: string | number;
}

export const MoviePage = (props: MoviePageProps) => {
  const navigate = useNavigate();
  return (
    <MoviePageStyles>
      <p className="description">{props.description}</p>
      <div className="movie-wrapper">
        <video
          width={props.width}
          height={props.height}
          controls
          autoPlay
          muted
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
        >
          <source src={props.movieUrl} type="video/mp4" />
          <track label="English" kind="subtitles" srcLang="en" src={props.subtitlesUrl} default></track>
        </video>
      </div>
      <p className="hearing-more">
        <strong>Interested in a full demo or hearing more?</strong>
      </p>
      <Button variant="contained" onClick={() => navigate(links.contactUs.link)}>
        Contact Us
      </Button>
    </MoviePageStyles>
  );
};
