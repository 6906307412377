import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { MultiSelect } from 'mi/components/MultiSelect';
import prettyBites from 'pretty-bytes';
import { Download } from 'shared/icons/Download';
import { OdConsts } from '../../lib/OdConsts';
import { UseMovementInsightResultType } from './useDataDownload';

type DataDownloadPanelProps = Pick<
  UseMovementInsightResultType,
  | 'loadingCvs'
  | 'loadingTreeData'
  | 'downloadProgress'
  | 'selectedSize'
  | 'handleDownload'
  | 'checkboxes'
  | 'products'
  | 'formData'
  | 'search'
  | 'setProduct'
>;

export const DataDownloadPanel = ({
  loadingCvs,
  loadingTreeData,
  downloadProgress,
  selectedSize,
  handleDownload,
  checkboxes,
  products,
  formData,
  setProduct,
}: DataDownloadPanelProps) => {
  const sizeTooBig = selectedSize > OdConsts.bigDownloadDataSize;
  const select = (
    <MultiSelect
      className="data-download-select"
      onChange={(e) => setProduct(e)}
      multi={false}
      placeholder="Select product"
      value={formData.product as any}
      menuItems={products}
      leftLabel="Product"
      disableCloseOnSelect={false}
      popperSx={{ width: '500px !important', paddingLeft: '150px' }}
    />
  );

  const downloadButton = (
    <Button
      variant="contained"
      startIcon={loadingCvs ? '' : <Download />}
      disabled={!checkboxes.size || loadingCvs || loadingTreeData}
      onClick={handleDownload}
      title={sizeTooBig ? 'Selected files size is too big and can cause an issue during download. Please select less files' : ''}
    >
      {loadingCvs ? (
        <span className="loading-wrapper">
          Loading
          <div className="progress-wrapper">
            <CircularProgress variant="determinate" value={downloadProgress} color="inherit" size="35px" />
            <div>{downloadProgress.toFixed(downloadProgress > 10 ? 0 : 1)}%</div>
          </div>
        </span>
      ) : (
        <>
          Download{' '}
          {!!selectedSize && (
            <Box className="selected-size" sx={sizeTooBig ? { color: 'warning.main', opacity: '1 !important' } : {}}>
              {prettyBites(selectedSize)}
            </Box>
          )}
        </>
      )}
    </Button>
  );
  return (
    <div className="filter-wrapper">
      <div className="left-side-wrapper">{select}</div>
      {downloadButton}
    </div>
  );
};
