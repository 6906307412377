import { MenuItem } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { MenuStyles } from './Menu.styles';

interface Links {
  label: string;
  link: string;
  pages: Record<string, { label: string; link: string }>;
}

export interface MenuProp {
  links: Links;
}

export const Menu = ({ links }: MenuProp) => {
  const [menuLink, setMenuLink] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setMenuLink(Object.values(links.pages).find((e) => location.pathname.startsWith(e.link))?.link ?? '');
  }, [location.pathname]);

  const handleMenuItemClick = (link: string) => {
    setMenuLink(link);
    setVisible(false);
    navigate(link);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleClickListItem = () => {
    setVisible(true);
  };

  return (
    <MenuStyles onMouseOver={handleClickListItem} onMouseOut={handleClose}>
      <NavLink to={links.link} className="label">
        {links.label}
      </NavLink>
      <Paper sx={{ width: 245 }} className="menu-items" hidden={!visible}>
        <MenuList>
          {Object.entries(links.pages).map(([key, value]) => (
            <MenuItem key={key} selected={value.link === menuLink} onClick={() => handleMenuItemClick(value.link)}>
              <ListItemText>{value.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </MenuStyles>
  );
};
