export function groupBy<T, U>(data: T[], callback: (e: T) => U): Map<U, T[]> {
  return data.reduce((a, e) => {
    const key = callback(e);
    if (a.has(key)) {
      a.get(key)!.push(e);
    } else {
      a.set(callback(e), [e]);
    }

    return a;
  }, new Map<U, T[]>());
}
