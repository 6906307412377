import { useEffect } from 'react';
import { links } from 'shared/constants';
import { PageContent } from 'shared/ui';
import { HowDoesItWorkStyles } from './HowDoesItWork.styles';

export const HowDoesItWork = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HowDoesItWorkStyles>
      <PageContent>
        <hgroup>
          <h1>
            How is the O<sub>2</sub> Motion data collected?
          </h1>
        </hgroup>
        <article>
          <h2>
            Movement Insights, Road Insights, Rail Insights, Real-Time Travel Insights, Real-Time Location Insights, Audience Insights
          </h2>
          <p>
            Our insight is derived from anonymised events and offline customer data generated by the 35% of the UK residents who are users
            of the O<sub>2</sub> network services.
          </p>
          <p>
            Each interaction between a mobile device and a mobile network cell generates an event: connecting to a new cell, sending or
            receiving a text or call or email, etc. We observe c.10-12bn events each day. By analysing the cells that events originate from,
            we can derive a user’s location and movements over time. We also use patterns and clusters of movements to identify home and
            work locations, and travel mode at an anonymised and aggregate level.
          </p>
          <p>
            The O<sub>2</sub> Motion solution also uses anonymised demographic data from O<sub>2</sub>’s customer base which is enriched
            algorithmically to derive socio-demographic profiles. Interest categories are generated from the customers’ online visits.
          </p>
          <p>
            All outputs are then combined and aggregated to the relevant geography’s level (for example, hexagon, Local Authority District,
            MSOA, postcode sector, or station) and with any direct identifiers removed.
          </p>
          <p>
            With our data representing over a third of the UK’s population, bias is limited. But we go further and expand our outputs to
            fairly represent the entire UK population, reducing geographical, age or gender bias. You will not be able to see a count of how
            many users of the O<sub>2</sub> network are in an area but a scientifically calculated and reliable estimate of the number of
            people from the total UK population who are in the area.
          </p>
          <p>
            We do all this in compliance with applicable laws, including data protection law: all our inputs are anonymised before reaching
            the O<sub>2</sub> Motion team. All outputs remain anonymised, with no personal data or direct identifiers disclosed, and are
            aggregated so that no individual user can be identified within our products. You can read more about this{' '}
            <a href={links.about.pages.dataPrivacy.link}>here</a>.
          </p>
        </article>
        <article>
          <h2>Micro-Location Insights</h2>
          <p>
            Data is obtained in a similar manner to that of our other O<sub>2</sub> Motion products, as described above. The only difference
            is that insight is provided from short-range cells installed in your venue instead of general O<sub>2</sub> network cells.
          </p>
        </article>
        <article>
          <h2>Spatial Insights</h2>
          <p>
            Spatial Insights doesn’t use the O<sub>2</sub> network. Rather, it uses Artificial Intelligence to analyse videos from your
            existing CCTV cameras and drive insight. Learn more about this product{' '}
            <a href={links.products.pages.spatialInsights.link}>here</a>.
          </p>
        </article>
      </PageContent>
    </HowDoesItWorkStyles>
  );
};
