import styled from 'styled-components';

export const IframePageStyles = styled.section`
  iframe {
    height: 100%;
    width: 100%;
    min-height: 920px;
    border: none;
  }

  &.with-iframe .layout-wrapper {
    width: 100%;
    max-width: 100%;
    min-height: 920px;
  }
  .content-loader-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
  }
`;
