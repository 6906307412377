export namespace Urls {
  export const trafficInsights = `${process.env.REACT_APP_MI_API_BASE}/v1/travel-insight/links`;
  export const subscriberInsights = `${process.env.REACT_APP_MI_API_BASE}/v1/travel-insight/demographics`;
  export const movementsInsightsProducts = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/o2motionProducts`;
  export const movementsInsightsList = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/o2motionfiles`;
  export const movementsInsightsListQuantity = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/o2motionfiles/quantity`;
  export const movementsInsightsFile = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/o2motiondownloadfiles`;
  export const userGroups = `${process.env.REACT_APP_MI_API_BASE}/v1/user/groups`;
  export const userType = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/user/type`;
  export const peopleCountsV2Permission = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/user/people-counts-v2-permissions`;
  export const ladToRegionMapping = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/ladToRegionMapping`;
  export const ladToRegionMappingV2 = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/lad-to-region-mapping-v2`;
  export const simpleLadGeometry = `${process.env.REACT_APP_MI_API_BASE}/v1/geo/lad/map`;
  export const simpleRegionGeometry = `${process.env.REACT_APP_MI_API_BASE}/v1/geo/region/map`;
  export const simpleLinksGeometry = `${process.env.REACT_APP_MI_API_BASE}/v1/geo/links/map`;
  export const simpleMsoaGeometry = `${process.env.REACT_APP_MI_API_BASE}/v1/geo/msoa/map`;
  export const simpleHexagonGeometry = `${process.env.REACT_APP_MI_API_BASE}/v1/geo/hexagons/map`;
  export const countriesList = `${process.env.REACT_APP_MI_API_BASE}/v1/geo/countries/data`;
  export const continentsList = `${process.env.REACT_APP_MI_API_BASE}/v1/geo/continents/data`;
  export const hexToMsoa = `${process.env.REACT_APP_MI_API_BASE}/v1/geo/hex-to-msoa/data`;
  export const peopleCounts = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/peopleCounts/csv`;
  export const peopleCountsV2Dates = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/peopleCountsV2/dates`;
  export const peopleCountsV2 = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/peopleCountsV2/data`;
  export const peopleCountsV2HeatMap = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/peopleCountsV2/heat-map`;
  export const originDestinationFiles = `${process.env.REACT_APP_MI_API_BASE}/v1/origin-destination/product-files`;
  export const originDestinationDownloadFile = `${process.env.REACT_APP_MI_API_BASE}/v1/origin-destination/download-file`;
  export const tripsEndsV2Dates = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/tripsEndsV2/dates`;
  export const tripsEndsV2Permission = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/user/trips-ends-v2-permissions`;
  export const tripsEndsV2Data = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/tripsEndsV2/data`;
  export const tripsEndsV2HeatMap = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/tripsEndsV2/heat-map`;
  export const inboundArrivalsMinMaxDate = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/inbound-arrivals/min-max-date`;
  export const inboundArrivalsQuantity = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/inbound-arrivals/quantity`;
  export const inboundArrivalsData = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/inbound-arrivals/data`;
  export const inboundArrivalsPermission = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/user/inbound-arrivals-permissions`;
  export const outboundMinMaxDate = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/outbound-v2/dates`;
  export const outboundData = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/outbound-v2/data`;
  export const outboundQuantity = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/outbound-v2/quantity`;
  export const outboundPermission = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/user/outbound-permissions`;

  export const originDestinationData = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/origin-destination/data`;
  export const originDestinationMap = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/origin-destination/heat-map`;
  export const originDestinationConnectionsMap = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/origin-destination/heat-connections-map`;
  export const originDestinationQuantity = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/origin-destination/quantity`;
  export const originDestinationDates = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/origin-destination/dates`;
  export const originDestinationWeekendsPermission = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/user/origin-destination-weekends-permissions`;
  export const originDestinationWeekdaysPermission = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/user/origin-destination-weekdays-permissions`;
  export const rtliHeatMap = `${process.env.REACT_APP_MI_API_BASE}/v1/location-insights/map`;
  export const rtliHexagonMap = `${process.env.REACT_APP_MI_API_BASE}/v1/location-insights/hexagon`;
  export const rtliUserPermissions = `${process.env.REACT_APP_MI_API_BASE}/v1/location-insights/user-permission`;
  export const shopperIndexData = `${process.env.REACT_APP_MI_API_BASE}/v1/movement-insight/shopper-index/data`;

  export const sendEmail =
    'https://prod-02.uksouth.logic.azure.com:443/workflows/423c51eaf79b48038e93694276b1079e/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=N7kUusXmUavHHYkNgblM1BMOrpeWwhqT9bgBQxftvGc';
}
