import { Tick } from 'shared/icons';
import { PageContent, Specification } from 'shared/ui';
import { AISpecificationStyles } from './AISpecificationStyles.styles';

export const AISpecification = () => {
  return (
    <AISpecificationStyles>
      <PageContent>
        <Specification>
          <hgroup>
            <h1>Data outputs</h1>
          </hgroup>
          <article>
            <h2>People counts and origins</h2>
            <p>
              <ul>
                <li>Total number of visitors / workers / residents / all</li>
                <li>Catchment area by postcode sector</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>Profile and behavioural data</h2>
            <p>
              <ul>
                <li>Age band (18-24, 25-34, 35-44, 45-54, 55-64, 65+)</li>
                <li>Gender</li>
                <li>Spending power</li>
                <li>Interests (Tier 1 and Tier 2 of IAB Web categories)</li>
              </ul>
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data features</h1>
          </hgroup>
          <article>
            <h2>Geographic coverage</h2>
            <p>Can be purchased for specific areas or the whole of the UK</p>
          </article>
          <article>
            <h2>Spatial granularity</h2>
            <p>
              <ul>
                <li>Postcode sector</li>
                <li>Postcode district</li>
                <li>Postcode area</li>
                <li>NUTS 1/2/3</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>Temporal granularity</h2>
            <p>
              <ul>
                <li>Hour of day</li>
                <li>3h ranges</li>
                <li>24h</li>
              </ul>
            </p>
          </article>
          <article>
            <h2>Expansion to the whole population</h2>
            <p>All outputs are expanded to fairly represent the entire UK population</p>
          </article>
          <article>
            <h2>Disclosure controls (privacy protection)</h2>
            <p>Outputs below 10 are replaced with a number between 0 and 10 derived using a &quot;stochastic rounding&quot; method</p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data delivery</h1>
          </hgroup>
          <article>
            <h2>Frequency and latency</h2>
            <p>Data refreshed every month with a latency of 2 months</p>
          </article>
          <article>
            <h2>Historical data</h2>
            <p>Data is available from Jan 2019 to present</p>
          </article>
          <article>
            <h2>Channels</h2>
            <p>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Historical data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>REST API (JSON format)</td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                  <tr>
                    <td>Portal visualisations</td>
                    <td>
                      <Tick />
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Fancy trying it for yourself?</h1>
          </hgroup>
          <p>
            See sample data <a href={process.env.REACT_APP_AI_URL!}>here</a>.
          </p>
        </Specification>
      </PageContent>
    </AISpecificationStyles>
  );
};
