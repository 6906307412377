import styled from 'styled-components';

export const MenuStyles = styled.section`
  color: white;
  position: relative;
  display: flex;

  .label {
    cursor: pointer;
  }

  svg {
    margin-left: 7px;
  }

  .menu-items {
    position: absolute;
    top: 87px;
    background-color: #000066;
    color: white;
    z-index: 999;
  }
`;
