export const Friend = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9184 14.8492C19.4368 14.2092 19.7504 13.39 19.7504 12.5004C19.7504 10.4332 18.0672 8.75 16 8.75C13.9328 8.75 12.2496 10.4332 12.2496 12.5004C12.2496 13.39 12.5632 14.2028 13.0816 14.8492C11.5264 15.5468 10.5024 16.7884 10.5024 18.2412V23.502C10.5024 24.0524 10.9504 24.5004 11.5008 24.5004C12.0512 24.5004 12.4992 24.0524 12.4992 23.502V18.2412C12.4992 17.1596 14.0992 16.2508 16 16.2508C17.9008 16.2508 19.5008 17.1596 19.5008 18.2412V23.502C19.5008 24.0524 19.9488 24.5004 20.4992 24.5004C21.0496 24.5004 21.4976 24.0524 21.4976 23.502V18.2412C21.4976 16.7884 20.48 15.5468 18.9184 14.8492ZM14.2528 12.5004C14.2528 11.534 15.04 10.7532 16 10.7532C16.9664 10.7532 17.7472 11.5404 17.7472 12.5004C17.7472 13.4604 16.96 14.2476 16 14.2476C15.0336 14.254 14.2528 13.4668 14.2528 12.5004Z"
      fill="#0019A5"
    />
    <path
      d="M11.3408 14.7764C11.6352 14.3092 11.5008 13.6948 11.04 13.394C10.8672 13.2852 10.6816 13.1892 10.4896 13.0932C11.0272 12.4468 11.3536 11.6084 11.3536 10.6996C11.3536 8.63242 9.6704 6.94922 7.6032 6.94922C5.536 6.94922 3.8528 8.63242 3.8528 10.6996C3.8528 11.6212 4.192 12.466 4.7424 13.1188C3.36 13.81 2.5024 15.0196 2.5024 16.4916V23.4932C2.5024 24.0436 2.9504 24.4916 3.5008 24.4916C4.0512 24.4916 4.4992 24.0436 4.4992 23.4932V16.498C4.4992 15.3204 5.7728 14.5012 7.5968 14.5012C8.5312 14.5012 9.3696 14.706 9.9584 15.0836C10.4256 15.378 11.0464 15.2436 11.3408 14.7764ZM7.6032 8.95882C8.5696 8.95882 9.3504 9.74602 9.3504 10.706C9.3504 11.666 8.5632 12.4532 7.6032 12.4532C6.6368 12.4532 5.856 11.666 5.856 10.706C5.856 9.74602 6.6368 8.95882 7.6032 8.95882Z"
      fill="#0019A5"
    />
    <path
      d="M27.2576 13.1344C27.8144 12.4816 28.1472 11.6368 28.1472 10.7152C28.1472 8.64804 26.464 6.96484 24.3968 6.96484C22.3296 6.96484 20.6464 8.64804 20.6464 10.7152C20.6464 11.624 20.9728 12.456 21.5104 13.1088C21.3184 13.1984 21.1328 13.2944 20.96 13.4096C20.4928 13.7104 20.3584 14.3248 20.6592 14.792C20.9536 15.2592 21.5744 15.3936 22.0416 15.0928C22.6304 14.7152 23.4688 14.5104 24.4032 14.5104C26.2272 14.5104 27.5008 15.336 27.5008 16.5072V23.5088C27.5008 24.0592 27.9488 24.5072 28.4992 24.5072C29.0496 24.5072 29.4976 24.0592 29.4976 23.5088V16.5008C29.4976 15.0352 28.64 13.8256 27.2576 13.1344ZM24.3968 8.96164C25.3632 8.96164 26.144 9.74884 26.144 10.7088C26.144 11.6688 25.3568 12.456 24.3968 12.456C23.4368 12.456 22.6496 11.6688 22.6496 10.7088C22.6496 9.74884 23.4368 8.96164 24.3968 8.96164Z"
      fill="#0019A5"
    />
  </svg>
);
