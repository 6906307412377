import styled from 'styled-components';

export const ContactUsStyles = styled.section`
  padding-top: 10vh;
  letter-spacing: -0.02em;

  h1 {
    font-size: 32px;
    line-height: 40px;
    color: #0019a5;
    font-weight: bold;
    text-align: center;
    margin: 0 0 40px;
  }

  p,
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #575757;
  }

  form {
    margin: 80px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px 100px;

    > div:last-of-type {
      margin: 20px;
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }
`;
