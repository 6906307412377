import styled from 'styled-components';

export const SpecificationStyles = styled.section`
  margin: 50px auto;

  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #575757;
  }

  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #0019a5;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #575757;
  }

  a {
    color: #0019a5;
  }

  ul {
    padding: 0;
    padding-left: 20px;
  }

  table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
    th {
      border-bottom: 1px solid #000066;
    }
    td {
      padding: 2rem 0;
      vertical-align: top;
      border-top: 1px solid #e5e8f6;
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 38%;
    }

    th:nth-child(n + 2),
    td:nth-child(n + 2) {
      text-align: center;
    }
  }

  article {
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    grid-gap: 50px;
    align-items: baseline;
    h2 {
      flex: 50%;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #0019a5;
    }
    p {
      flex: 50%;
    }
  }
`;
