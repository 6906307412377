import { NavLink } from 'react-router-dom';
import { Tick } from 'shared/icons';
import { links } from 'shared/constants';
import { PageContent, Specification } from 'shared/ui';
import { RTISpecificationStyles } from './RTISpecificationStyles.styles';

export const RTISpecification = () => {
  return (
    <RTISpecificationStyles>
      <PageContent>
        <Specification>
          <hgroup>
            <h1>Data outputs</h1>
          </hgroup>
          <article>
            <h2>Number & characteristics of trips and users on each road link</h2>
            <p>
              <ul>
                <li>Age band (10 year bands, starting at 15, e.g. 15-24, 25-34...)</li>
                <li>Gender</li>
                <li>Spend power (high, medium, low)</li>
                <li>Resident / worker / visitor status</li>
                <li>Travel purpose (commute, non-commute)</li>
                <li>Travel mode (road (car, HGV, EV) vs. non-road (&apos;other&apos;))</li>
                <li>Trip length (distance travelled)</li>
                <li>Home and work location at postcode area, district or sector level</li>
              </ul>
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data features</h1>
          </hgroup>
          <article>
            <h2>Geographic coverage</h2>
            <p>Can be purchased for specific road links or for all the roads in the UK</p>
          </article>
          <article>
            <h2>Temporal granularity</h2>
            <p>Hour, day, week, month or year</p>
          </article>
          <article>
            <h2>Spatial granularity</h2>
            <p>Road link</p>
          </article>
          <article>
            <h2>Expansion to the whole population</h2>
            <p>All outputs are expanded to fairly represent the entire UK population</p>
          </article>
          <article>
            <h2>Disclosure controls (privacy protection)</h2>
            <p>
              Any expanded profile count (e.g. number of trips) below 10 will be replaced with 0. Outputs which could result in counts below
              10 being inferred will also be replaced with 0.
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Data delivery</h1>
          </hgroup>
          <article>
            <h2>Frequency and latency</h2>
            <p>Refreshed at the middle of each month for the preceding month</p>
          </article>
          <article>
            <h2>Historical data</h2>
            <p>
              Data is available from contract signature and can be queried retrospectively. In addition, customers can purchase up to 36
              months of historical data.
            </p>
          </article>
          <article>
            <h2>Channels</h2>
            <p>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      Low latency data <br /> (weekly)
                    </th>
                    <th>Historical data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Portal visualisations <br /> (Tableau Dashboard)
                    </td>
                    <td>
                      <Tick />
                    </td>
                    <td>
                      <Tick /> <br /> 3 years of pre-contract <br /> historical data - subject <br /> to scheduling
                    </td>
                  </tr>
                  <tr>
                    <td>
                      REST API <br /> (JSON format)
                    </td>
                    <td>
                      <Tick />
                    </td>
                    <td>
                      <Tick />
                      <br /> Data from contract <br /> signature + up to 3 <br /> months of pre-contract <br /> historical data
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
          </article>
        </Specification>
        <Specification>
          <hgroup>
            <h1>Fancy trying it for yourself?</h1>
          </hgroup>
          <p>
            See a demo of our dashboard <NavLink to={`${links.products.pages.roadInsights.link}/dashboard`}>here</NavLink>. For sample CSV
            data, please contact us at{' '}
            <a target="_blank" href="mailto:o2motion@o2.com" rel="noreferrer">
              o2motion@o2.com
            </a>{' '}
            to discuss your needs.
          </p>
        </Specification>
      </PageContent>
    </RTISpecificationStyles>
  );
};
