import { Navigate, Route, Routes } from 'react-router-dom';
import { Breadcrumb, PageHeader } from 'shared/ui';
import { links } from 'shared/constants';
import { Link } from '@mui/material';

import { ODOverview } from './components/overview/ODOverview';
import { DataDownload } from 'od/components/data-download/DataDownload';
import { ODSpecification } from './components/specifications/ODSpecification';

import { OriginDestinationInsightsStyles } from './OriginDestinationInsights.styles';
import headerImage from '../../assets/images/od/header-image.png';

const OriginDestinationInsights = () => {
  return (
    <OriginDestinationInsightsStyles>
      <PageHeader
        image={headerImage}
        menus={[
          { label: 'Product Overview', link: 'overview' },
          { label: 'Datasets', link: 'datasets' },
          { label: 'Specifications', link: 'specifications' },
        ]}
      >
        <h1>{links.products.pages.originDestination.label}</h1>
        <p>
          Origin Destination Insights provides travel analysis to identify how <br /> people move around different regions of the UK.
        </p>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.products.link}>
          {links.products.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.products.pages.originDestination.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <Routes>
        <Route path="overview" element={<ODOverview />} />
        <Route path="datasets" element={<DataDownload />} />
        <Route path="specifications" element={<ODSpecification />} />
        <Route path="" element={<Navigate to="overview" replace={true} />} />
      </Routes>
    </OriginDestinationInsightsStyles>
  );
};

export default OriginDestinationInsights;
