import { Link } from '@mui/material';
import { PageContent, PageHeader, IconCart, LandscapeCart, ButtonLink, Breadcrumb } from 'shared/ui';
import { InternationalPhone, Spend, API, Portal, Stand, Next } from 'shared/icons';
import { links } from 'shared/constants';
import { GovernmentPolicingStyles } from './GovernmentPolicing.styles';

import headerImage from 'assets/images/government-policing/header-image.svg';
import movementInsights from 'assets/images/government-policing/movement-insights.png';
import originDestinationInsights from 'assets/images/government-policing/origin-destination-insights.png';
import realTimeLocationInsights from 'assets/images/government-policing/real-time-location-insights.png';
import roadInsights from 'assets/images/government-policing/road-travel-insights.png';
import railInsights from 'assets/images/government-policing/rail-insights.png';
import spatialInsights from 'assets/images/government-policing/spatial-insights.png';
import greyBackground from 'assets/images/government-policing/grey-background.png';

export const GovernmentPolicing = () => {
  return (
    <GovernmentPolicingStyles>
      <PageHeader image={headerImage}>
        <h1>Government & Policing</h1>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.industries.link}>
          {links.industries.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.industries.pages.governmentPolicing.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <PageContent className="description">
        <hgroup>
          <h1>Make data-led policy and investment decisions and improve your response to events</h1>
        </hgroup>
        <article>
          <p>
            With our detailed insights on the movements of people around the UK, you can make evidence-based infrastructure decisions.
            Insights on movements and socio-demographic profiles will help inform equitable policies and public services investment. And
            when an event or crisis happens, you can rely on our real-time data to monitor crowd sizes and quickly allocate resources to the
            right location.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Spend />}>
            <h1>Better ROI for Strategic Initiatives</h1>
            <p>Improve the success of infrastructure and public services investments with a better understanding of their users.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Easy to adopt</h1>
            <p>Data can be accessed via API, Blob storage or our Portal, for easy integration into your workflows.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>Our solutions are readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Large scale</h1>
            <p>Our data is obtained from 35% of the UK population and expanded to fairly represent the entirety of the UK’s population.</p>
          </IconCart>
          <IconCart icon={<Stand />}>
            <h1>Historical & real-time</h1>
            <p>We offer data as old as 6 years ago and as recent as 3 minutes ago to meet your exact needs.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Related Products</h1>
        </hgroup>
        <LandscapeCart>
          <img src={movementInsights} />
          <article>
            <h2>{links.products.pages.movementInsights.label}</h2>
            <p>Low latency journey and location data across the UK and internationally.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.movementInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.originDestination.label}</h2>
            <p>Travel analysis to identify how people move around different regions of the UK.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.originDestination.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={originDestinationInsights} />
        </LandscapeCart>
        <LandscapeCart>
          <img src={realTimeLocationInsights} />
          <article>
            <h2>{links.products.pages.realTimeLocationInsights.label}</h2>
            <p>Real-time data on the number and profiles of people in an area.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.realTimeLocationInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.railInsights.label}</h2>
            <p>Insight into how travellers in the UK move across the rail transport network.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.railInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={railInsights} />
        </LandscapeCart>
        <LandscapeCart>
          <img src={roadInsights} />
          <article>
            <h2>{links.products.pages.roadInsights.label}</h2>
            <p>Insights into how travellers in the UK move across the road network.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.roadInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.spatialInsights.label}</h2>
            <p>Video analytics and AI to deliver insights around customer movement and flow in a venue.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.spatialInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={spatialInsights} />
        </LandscapeCart>
      </PageContent>
      <div className="grey-background" style={{ backgroundImage: `url(${greyBackground})` }}>
        <PageContent className="read-case-study">
          <hgroup>
            <h2>Learn how we helped the Office for National Statistics support Government policy during the COVID-19 pandemic.</h2>
            <ButtonLink>
              <a
                className="secondary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/1074429/O2_business_ONS_1020.049+%281%29.pdf/8e310232-0db8-d322-bac5-c4d46be450b6?version=1.0&t=1615800700104"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </hgroup>
          <article>
            <p>
              “ONS have been very clear about the data they require and we’ve worked hard to deliver it for them. We also provided insights
              into how existing data can be used to deliver the results they’re looking for.”
            </p>
            <div>
              <span> Ian Burrows</span>
              <br />
              <span>
                Digital Insights Director, O<sub>2</sub>
              </span>
            </div>
          </article>
        </PageContent>
      </div>
    </GovernmentPolicingStyles>
  );
};
