import styled from 'styled-components';

export const IndustriesStyles = styled.section`
  .center {
    padding: 0;
  }

  .industries-carts {
    display: grid;
    grid-gap: 100px;
    grid-template-columns: repeat(6, 1fr);
    margin: 80px 0 160px 0;
  }
`;
