import JSZip from 'jszip';
import * as toastify from 'react-toastify';
import { Helpers } from '../../shared/lib/helpers';
import { LoadingDataStates } from '../../shared/models/LoadingDataState';
import { TreeStructure } from '../../shared/models/TreeStructure';
import { DataDownloadItem } from '../models/DataDownloadItemDto';
import { OdApi } from './OdApi';
import { OdConsts } from './OdConsts';

interface DownloadProgressState {
  p: Record<string, number>;
  progress: number;
}
export interface DownloadProgressResponse {
  status: LoadingDataStates;
  progress: number;
}

type LoadingDataStatusCallback = (data: DownloadProgressResponse) => void;

export namespace OdDownloadManager {
  let loadingDataStatus = LoadingDataStates.notStarted;
  let downloadSize = 0;
  let downloadState = { p: {}, progress: 0 } as DownloadProgressState;
  const getProgress = () => (downloadState.progress / downloadSize) * 100;
  let downloadCallback: LoadingDataStatusCallback | null;
  const getLoadingLabel = () => `Loading Origin Destination Data ${getProgress().toFixed(getProgress() > 10 ? 0 : 1)}%`;
  const downloadProgressReducer = (state: DownloadProgressState, e: { type: string; value?: number; path?: string }) => {
    if (e.type === 'reset') {
      return { p: {}, progress: 0 } as DownloadProgressState;
    }
    const curProgress = state.p[e.path!] || 0;
    return { p: { ...state.p, [e.path!]: e.value }, progress: state.progress - curProgress + e.value! } as DownloadProgressState;
  };

  function setSelSizeForDownload(size: number) {
    downloadSize = size;
  }

  let downloadToast: any | null;
  function showDownloadToast() {
    if ((loadingDataStatus === LoadingDataStates.loading || loadingDataStatus === LoadingDataStates.initializing) && !downloadCallback) {
      downloadToast = toastify.toast(getLoadingLabel(), {
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        progress: getProgress() / 100,
        draggable: false,
      });
    }
  }
  function hideDownloadToast() {
    if (downloadToast) {
      toastify.toast.dismiss(downloadToast);
      downloadToast = null;
    }
  }
  function updateDownloadStatus() {
    if (downloadToast && !downloadCallback) {
      toastify.toast.update(downloadToast, { progress: getProgress() / 100, render: getLoadingLabel() });
    }
  }
  function setLoadingCvsStatus(status: LoadingDataStates) {
    loadingDataStatus = status;
    downloadCallback?.({ status: loadingDataStatus, progress: getProgress() });
    if (status === LoadingDataStates.error || status === LoadingDataStates.loaded) {
      hideDownloadToast();
    }
  }
  function setDownloadedSize(e: { type: string; value?: number; path?: string }) {
    downloadState = downloadProgressReducer(downloadState, e);
    downloadCallback?.({ status: loadingDataStatus, progress: getProgress() });
    updateDownloadStatus();
  }
  export function subscribe(callback: LoadingDataStatusCallback) {
    downloadCallback = callback;
    hideDownloadToast();
    return () => {
      downloadCallback = null;
      showDownloadToast();
    };
  }
  export function handleDownload(checkboxes: Set<TreeStructure<DataDownloadItem>>) {
    setLoadingCvsStatus(LoadingDataStates.loading);
    const fileList = Array.from(checkboxes).filter((e) => e.element);
    setSelSizeForDownload(fileList.reduce((a, e) => a + (+e.element?.fileSize! || 0), 0));
    setDownloadedSize({ type: 'reset', value: 0 });
    const controller = new AbortController();

    if (fileList.length === 1) {
      const file = fileList[0];
      OdApi.loadCvs(file.element!.filePath!, controller, (e: ProgressEvent) => {
        setDownloadedSize({ type: 'add', value: +e.loaded, path: file.element?.filePath });
      })
        .then((d) => {
          setLoadingCvsStatus(LoadingDataStates.loaded);
          Helpers.saveFile(file.element!.filePath.split('/').pop()!, d);
        })
        .catch((e) => {
          setLoadingCvsStatus(LoadingDataStates.error);
          toastify.toast.error('Error happen while loading CVS data. Please select smaller number of files');
          return Promise.reject(e);
        });
    } else {
      const zip = new JSZip();
      let downloaded = false;
      const requestsPool = fileList.map((file) => () => {
        const fileRequest = OdApi.loadCvs(file.element!.filePath!, controller, (e: ProgressEvent) => {
          setDownloadedSize({ type: 'add', value: +e.loaded, path: file.element?.filePath });
        });
        fileRequest
          .then(() => setDownloadedSize({ type: 'add', value: +file.element?.fileSize!, path: file.element?.filePath }))
          .catch(() => controller.abort());
        zip.file((file.element!.filePath!.match(/(?:[^\/]+\/[^\/]+\/)(.*)/) || [])[1], fileRequest);
        return fileRequest;
      });
      const handleRequestsPool = () => {
        requestsPool.pop()?.().then(handleRequestsPool);
        if (requestsPool.length === 0 && !downloaded) {
          downloaded = true;
          zip
            .generateAsync({ type: 'blob' })
            .then((content) => {
              setLoadingCvsStatus(LoadingDataStates.loaded);
              Helpers.saveFile(OdConsts.miDataDownloadFileName, content as any);
            })
            .catch((e) => {
              setLoadingCvsStatus(LoadingDataStates.error);
              toastify.toast.error('Error happen while loading CVS data');
              return Promise.reject(e);
            });
        }
      };
      handleRequestsPool();
      handleRequestsPool();
    }
  }
}
