import { HelpOutline } from '@mui/icons-material';
import { Fade } from '@mui/material';
import { BootstrapTooltip } from './BootstrapTooltip';
import { TooltipsStyles } from './Tooltips.styles';

interface TooltipsProps {
  title: string;
  children: JSX.Element[] | JSX.Element;
}

export const Tooltips = ({ children, title }: TooltipsProps) => {
  return (
    <TooltipsStyles>
      <BootstrapTooltip
        control={children}
        title={title}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        placement="top"
        arrow
      >
        <HelpOutline color="primary" fontSize="small" />
      </BootstrapTooltip>
    </TooltipsStyles>
  );
};
