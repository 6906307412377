export const Swap = () => (
  <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.39521 13.3984L0.566406 8.59836L2.99521 6.16956L7.82401 10.9984L8.79361 10.0544C9.05281 9.79516 9.42081 9.73756 9.76321 9.88156C10.1056 10.0256 10.3072 10.3104 10.3072 10.6816V16H4.88001C4.53761 16 4.22081 15.7984 4.08001 15.456C3.93601 15.1136 4.02241 14.7392 4.25281 14.4864L5.39521 13.3984Z"
      fill="white"
    />
    <path
      d="M7.99215 9.7408L3.16335 4.912L2.10735 5.9424C1.84815 6.2016 1.48015 6.2592 1.13775 6.1152C0.79535 5.9712 0.59375 5.6864 0.59375 5.3152V0H5.99215C6.33455 0 6.65135 0.2016 6.79215 0.544C6.93295 0.8864 6.84975 1.2608 6.61935 1.5136L5.59215 2.5152L10.4209 7.344L7.99215 9.7408Z"
      fill="white"
    />
  </svg>
);
