export interface Config {
  REACT_APP_API_BASE: string;
  REACT_APP_MSAL_CLIENT_ID: string;
  REACT_APP_MSAL_AUTHORITY: string;
  REACT_APP_MSAL_OPENID_CONFIGURATION: string;
  REACT_APP_MSAL_SCOPE: string;
  REACT_APP_MSAL_PASSWORD_RESET_FLOW: string;
}

export const getConfig = (): Config => {
  const {
    REACT_APP_API_BASE,
    REACT_APP_MSAL_CLIENT_ID,
    REACT_APP_MSAL_AUTHORITY,
    REACT_APP_MSAL_OPENID_CONFIGURATION,
    REACT_APP_MSAL_SCOPE,
    REACT_APP_MSAL_PASSWORD_RESET_FLOW,
  } = process.env;

  const requiredEnv = {
    REACT_APP_API_BASE,
    REACT_APP_MSAL_CLIENT_ID,
    REACT_APP_MSAL_AUTHORITY,
    REACT_APP_MSAL_OPENID_CONFIGURATION,
    REACT_APP_MSAL_SCOPE,
    REACT_APP_MSAL_PASSWORD_RESET_FLOW,
  };

  const missingEnvVars = Object.entries(requiredEnv)
    .filter(([, val]) => val === undefined)
    .map(([envVar]) => envVar);

  if (missingEnvVars.length > 0) {
    const errorMessage = `Missing required env parameters: ${missingEnvVars.join(', ')}`;
    if (process.env.NODE_ENV === 'production') {
      throw new Error(errorMessage);
    } else {
      console.error(errorMessage);
    }
  }

  return requiredEnv as Config;
};
