import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import * as authAzure from '../../auth/lib/authAzure';
import { SharedConsts } from '../../shared/constants/SharedConsts';
import { Urls } from '../../shared/constants/urls';
import { DataResponseDto } from '../../shared/models/DataResponseDto';
import { DataDownloadItem } from '../models/DataDownloadItemDto';

axiosRetry(axios, { retries: 0 });

export namespace OdApi {
  export const getDataDownload = async (): Promise<DataResponseDto<DataDownloadItem>> => {
    const token = await authAzure.getJwtToken();
    return axios
      .get<DataResponseDto<DataDownloadItem>>(Urls.originDestinationFiles, {
        headers: {
          Authorization: token,
        },
        'axios-retry': {
          retries: SharedConsts.requestRetryCount,
        },
      } as AxiosRequestConfig)
      .then((r) => r.data);
  };

  export const loadCvs = async (cvsPaths: string, controller: AbortController, callback?: (e: ProgressEvent) => void): Promise<Blob> => {
    const token = await authAzure.getJwtToken();
    return axios
      .get<Blob>(Urls.originDestinationDownloadFile, {
        headers: {
          Authorization: token,
        },
        params: {
          filePath: cvsPaths,
        },
        signal: controller.signal,
        responseType: 'blob',
        'axios-retry': {
          retries: SharedConsts.requestRetryCount,
        },
        onDownloadProgress: callback,
      } as AxiosRequestConfig)
      .then((r) => r.data);
  };
}
