import { Breadcrumbs } from '@mui/material';
import { Next } from 'shared/icons';
import { BreadcrumbStyles } from './Breadcrumb.styles';

interface BreadcrumbProps {
  children: JSX.Element[] | JSX.Element;
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  return (
    <BreadcrumbStyles>
      <Breadcrumbs
        separator={<Next width={10} height={16} viewBox="0 0 8 14" fill={'#808CD2'} style={{ margin: 0 }} />}
        aria-label="breadcrumbs"
      >
        {props.children}
      </Breadcrumbs>
    </BreadcrumbStyles>
  );
};
