import { useGetMetadataLoadingStatus } from 'auth/lib/useGetMetadataLoadingStatus';
import { LoadingDataStates } from 'shared/models/LoadingDataState';

import { PageContent, MoviePage } from 'shared/ui';
import { IframePage } from 'shared/components/IframePage';
import { SharedConsts, movieLinks } from 'shared/constants';

import { RIDashboardStyles } from './RIDashboard.styles';

interface STIDashboardProps {
  hasTableauAccess: boolean;
}

export const RIDashboard = ({ hasTableauAccess }: STIDashboardProps) => {
  const isMetadataLoaded = useGetMetadataLoadingStatus() === LoadingDataStates.loaded;

  if (!isMetadataLoaded) return <div>loadings...</div>;

  return (
    <RIDashboardStyles>
      <PageContent className="dashboard">
        {hasTableauAccess ? (
          <IframePage src={SharedConsts.railInsightsDashboardIframeUrl} />
        ) : (
          <MoviePage
            description="The following video captures a few of the key functionalities of the Rail Insights dashboard."
            height={637}
            width={1170}
            movieUrl={movieLinks.railInsights}
            subtitlesUrl="/station-insights.vtt"
          />
        )}
      </PageContent>
    </RIDashboardStyles>
  );
};
