export const Error = () => (
  <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M129.375 25.4531V11.25H52.2844L51.4406 12.0656L11.25 52.2844V168.75H126.562C127.308 168.75 128.024 168.454 128.551 167.926C129.079 167.399 129.375 166.683 129.375 165.938C129.375 165.192 129.079 164.476 128.551 163.949C128.024 163.421 127.308 163.125 126.562 163.125H16.875V56.25H56.25V16.875H123.75V25.4531C114.325 26.1803 105.547 30.5298 99.2598 37.589C92.9723 44.6482 89.6632 53.8683 90.0271 63.3146C90.3909 72.7609 94.3994 81.6991 101.211 88.2537C108.023 94.8083 117.109 98.4698 126.562 98.4698C136.016 98.4698 145.102 94.8083 151.914 88.2537C158.726 81.6991 162.734 72.7609 163.098 63.3146C163.462 53.8683 160.153 44.6482 153.865 37.589C147.578 30.5298 138.8 26.1803 129.375 25.4531ZM20.8406 50.625L50.625 20.8406V50.625H20.8406ZM126.562 92.8125C120.444 92.8125 114.462 90.998 109.375 87.5986C104.287 84.1991 100.322 79.3674 97.98 73.7143C95.6384 68.0612 95.0257 61.8407 96.2195 55.8394C97.4132 49.8381 100.36 44.3256 104.686 39.9989C109.013 35.6722 114.526 32.7257 120.527 31.532C126.528 30.3382 132.749 30.9509 138.402 33.2925C144.055 35.6341 148.887 39.5994 152.286 44.687C155.686 49.7747 157.5 55.7561 157.5 61.875C157.5 70.0801 154.241 77.9492 148.439 83.7511C142.637 89.553 134.768 92.8125 126.562 92.8125Z"
      fill="#0019A5"
    />
    <path
      d="M139.809 48.6281C139.548 48.3645 139.237 48.1553 138.894 48.0125C138.551 47.8697 138.183 47.7962 137.812 47.7962C137.441 47.7962 137.073 47.8697 136.731 48.0125C136.388 48.1553 136.077 48.3645 135.815 48.6281L126.562 57.9094L117.309 48.6281C116.779 48.0985 116.061 47.801 115.312 47.801C114.563 47.801 113.845 48.0985 113.315 48.6281C112.786 49.1577 112.488 49.876 112.488 50.625C112.488 51.374 112.786 52.0923 113.315 52.6219L122.597 61.875L113.315 71.1281C113.052 71.3896 112.842 71.7007 112.7 72.0434C112.557 72.3861 112.483 72.7537 112.483 73.125C112.483 73.4963 112.557 73.8639 112.7 74.2066C112.842 74.5494 113.052 74.8604 113.315 75.1219C113.577 75.3855 113.888 75.5947 114.231 75.7375C114.573 75.8803 114.941 75.9538 115.312 75.9538C115.683 75.9538 116.051 75.8803 116.394 75.7375C116.737 75.5947 117.048 75.3855 117.309 75.1219L126.562 65.8406L135.815 75.1219C136.077 75.3855 136.388 75.5947 136.731 75.7375C137.073 75.8803 137.441 75.9538 137.812 75.9538C138.183 75.9538 138.551 75.8803 138.894 75.7375C139.237 75.5947 139.548 75.3855 139.809 75.1219C140.073 74.8604 140.282 74.5494 140.425 74.2066C140.567 73.8639 140.641 73.4963 140.641 73.125C140.641 72.7537 140.567 72.3861 140.425 72.0434C140.282 71.7007 140.073 71.3896 139.809 71.1281L130.528 61.875L139.809 52.6219C140.073 52.3604 140.282 52.0494 140.425 51.7066C140.567 51.3639 140.641 50.9963 140.641 50.625C140.641 50.2537 140.567 49.8861 140.425 49.5434C140.282 49.2007 140.073 48.8896 139.809 48.6281Z"
      fill="#0019A5"
    />
    <path
      d="M126.562 154.688C129.669 154.688 132.188 152.169 132.188 149.062C132.188 145.956 129.669 143.438 126.562 143.438C123.456 143.438 120.938 145.956 120.938 149.062C120.938 152.169 123.456 154.688 126.562 154.688Z"
      fill="#0019A5"
    />
    <path
      d="M129.375 132.188V106.875C129.375 106.129 129.079 105.414 128.551 104.886C128.024 104.359 127.308 104.062 126.562 104.062C125.817 104.062 125.101 104.359 124.574 104.886C124.046 105.414 123.75 106.129 123.75 106.875V132.188C123.75 132.933 124.046 133.649 124.574 134.176C125.101 134.704 125.817 135 126.562 135C127.308 135 128.024 134.704 128.551 134.176C129.079 133.649 129.375 132.933 129.375 132.188ZM101.25 129.375H39.375C38.6291 129.375 37.9137 129.671 37.3863 130.199C36.8588 130.726 36.5625 131.442 36.5625 132.188C36.5625 132.933 36.8588 133.649 37.3863 134.176C37.9137 134.704 38.6291 135 39.375 135H101.25C101.996 135 102.711 134.704 103.239 134.176C103.766 133.649 104.062 132.933 104.062 132.188C104.062 131.442 103.766 130.726 103.239 130.199C102.711 129.671 101.996 129.375 101.25 129.375ZM101.25 101.25H39.375C38.6291 101.25 37.9137 101.546 37.3863 102.074C36.8588 102.601 36.5625 103.317 36.5625 104.062C36.5625 104.808 36.8588 105.524 37.3863 106.051C37.9137 106.579 38.6291 106.875 39.375 106.875H101.25C101.996 106.875 102.711 106.579 103.239 106.051C103.766 105.524 104.062 104.808 104.062 104.062C104.062 103.317 103.766 102.601 103.239 102.074C102.711 101.546 101.996 101.25 101.25 101.25Z"
      fill="#0019A5"
    />
  </svg>
);
