import { CircularProgress } from '@mui/material';
import { LoaderStyles } from './Loader.styles';

interface LoaderProps {
  height?: string;
}

export const Loader = ({ height }: LoaderProps) => (
  <LoaderStyles style={{ height }}>
    <p className="mini-logo">
      O<sub>2</sub> Motion Portal
    </p>
    <CircularProgress color="primary" />
  </LoaderStyles>
);
