import React, { HTMLAttributes } from 'react';
import { PageContentStyles } from './PageContent.styles';

interface PageContentProps extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element[] | JSX.Element;
}

export const PageContent = (props: PageContentProps) => {
  return (
    <PageContentStyles className={props.className} style={props.style}>
      {props.children}
    </PageContentStyles>
  );
};
