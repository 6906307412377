import { ReactNode } from 'react';
import { LoadingDataStates } from '../models/LoadingDataState';
import { ErrorBoundaryStyles } from './ErrorBoundary.styles';
import { Loader } from 'shared/ui';

type ControlledLoaderProps = {
  loadingStatus: LoadingDataStates;
  messages?: Partial<Record<LoadingDataStates, string>>;
  children: JSX.Element | JSX.Element[] | ReactNode;
};

export const ControlledLoader = ({ children, loadingStatus, messages }: ControlledLoaderProps) => {
  if (loadingStatus === LoadingDataStates.loaded) {
    return children as JSX.Element;
  }
  if (loadingStatus === LoadingDataStates.error) {
    return (
      <ErrorBoundaryStyles>
        <h1>{messages?.error ?? 'Something went wrong during loading data'}</h1>
      </ErrorBoundaryStyles>
    );
  }
  return <Loader height="calc(100vh - var(--footer-height))" />;
};
