import { PageContent, IconCart, LandscapeCart, ButtonLink } from 'shared/ui';
import { Users, API, InternationalPhone, Stand, Next, Portal, Clock, CheckList } from 'shared/icons';
import { RIOverviewStyles } from './RIOverview.styles';

import storiesImage1 from 'assets/images/ri/stories-image-1.png';
import storiesImage2 from 'assets/images/ri/stories-image-2.png';
import storiesPDF2 from 'assets/documents/ri/O2_business_Transport for Wales_1120.053.pdf';

export const RIOverview = () => {
  return (
    <RIOverviewStyles>
      <PageContent className="description">
        <hgroup>
          <h1>Optimise services, reduce disruption and improve passenger communications</h1>
        </hgroup>
        <article>
          <p>
            Rail Insights is a web-based solution providing insights on usage of the UK rail network, including; location, date of travel,
            time of the day, travel purpose, traveller profile (age range, gender and spend potential), market share (rail vs. road) and web
            browsing habits.
          </p>
          <br />
          <p>
            We typically supply this insight to train operating companies, transport consultancies and government bodies looking to
            understand how people use the UK rail network over time.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Users />}>
            <h1>Traveller profiles</h1>
            <p>Gain insights on the traveller composition of a station.</p>
          </IconCart>
          <IconCart icon={<Clock />}>
            <h1>Fast & simple</h1>
            <p>Benefit from weekly data, collected passively from the network without the need for expensive equipment.</p>
          </IconCart>
          <IconCart icon={<CheckList />}>
            <h1>Accurate</h1>
            <p>We capture a fully representative sample of a third of the UK population.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Integrated</h1>
            <p>Easy to access data, be it web-based through a UI or linked to existing planning tools through an API.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Far-reaching</h1>
            <p>
              O<sub>2</sub> Motion data covers the entire UK rail network (including the London Underground).
            </p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>The solution is readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<Stand />}>
            <h1>Trend analysis</h1>
            <p>Understand trends over time to predict what is likely to happen in the future.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Customer success stories</h1>
        </hgroup>
        <LandscapeCart>
          <img src={storiesImage1} />
          <article>
            <h2>
              First TransPennine Express makes better business decisions with O<sub>2</sub> Smart Steps
            </h2>
            <p>
              “There are quite a few services on the market that can provide data, but we required a service that could match what we needed
              with the right level of detail, within our tight timescale – and within a reasonable budget.”
            </p>
            <i>James Cohen, Rail Franchise Bid Manager, First TransPennine Express</i>
            <br />
            <ButtonLink>
              <a
                className="quaternary"
                rel="noreferrer"
                target="_blank"
                href="https://img06.en25.com/Web/TELEFONICAUKLIMITEDO2/%7B9eea6b8b-b8a8-4672-9df5-e727f964dfe1%7D_O2_First_TransPennine_Case_Study.pdf"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>
              O<sub>2</sub> Motion helps Transport for Wales plan for the future
            </h2>
            <p>
              “O<sub>2</sub> Motion’s data removes the guesswork and allows us to anticipate pressure on our rail network and plan ahead for
              potential fluctuations in demand that result from COVID-19 travel restrictions.”
            </p>
            <i>Strategic Transport Analysis Manager at Transport for Wales</i>
            <br />
            <ButtonLink>
              <a className="quaternary" rel="noreferrer" target="_blank" href={storiesPDF2}>
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={storiesImage2} />
        </LandscapeCart>
      </PageContent>
    </RIOverviewStyles>
  );
};
