import styled from 'styled-components';

export const MovieStyles = styled.section`
  border-radius: 10px;
  color: white;

  video {
    border-radius: 10px;
    &::cue {
      font-size: 2.4rem;
      top: 1rem;
    }
  }
`;
