import { PortraitCartStyles } from './PortraitCart.styles';
import { NavLink } from 'react-router-dom';
import { Next } from 'shared/icons';

export interface PortraitCartProps {
  image: string;
  header: string;
  description: string;
  link: string;
  learnMore: string;
  borderColor?: string;
}

export const PortraitCart = (props: PortraitCartProps) => {
  return (
    <PortraitCartStyles borderColor={props.borderColor || 'transparent'}>
      <NavLink to={props.link}>
        <img src={props.image} />
        <article>
          <h3>{props.header}</h3>
          <p>{props.description}</p>
        </article>
      </NavLink>
      <div>
        <NavLink to={props.learnMore}>
          <div>Learn more</div>
          <Next />
        </NavLink>
      </div>
    </PortraitCartStyles>
  );
};
