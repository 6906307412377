import styled from 'styled-components';

export const IconCartStyles = styled.section`
  .columns-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon {
    height: 64px;
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    border-radius: 32px;
    background: #e5e8f6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    color: #575757;
    font-style: normal;
    text-align: center;
    letter-spacing: -0.02em;

    h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin: 1rem auto;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin: 1rem auto;
    }
  }
`;
