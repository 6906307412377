import styled from 'styled-components';

export const OriginDestinationInsightsStyles = styled.section`
  .description {
    padding: 100px 0;
    display: grid;
    grid-template-columns: 376px 596px;
    justify-content: space-around;

    h1 {
      margin: 0;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.02em;
      color: #0019a5;
    }

    p {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #575757;
    }
  }
`;
