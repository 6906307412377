import { Link } from '@mui/material';
import { PageHeader, PageContent, PortraitCart } from '../shared/ui';
import { Breadcrumb } from '../shared/ui/Breadcrumb/Breadcrumb';
import { IndustriesStyles } from './Industries.styles';

import headerImage from 'assets/images/industries/header-image.svg';

import governmentPolicing from 'assets/images/industries/government-policing.png';
import leisureTourism from 'assets/images/industries/leisure-tourism.png';
import localAuthorities from 'assets/images/industries/local-authorities.png';
import mediaAdvertising from 'assets/images/industries/media-advertising.png';
import retailProperty from 'assets/images/industries/retail-property.png';
import retail from 'assets/images/industries/retail.png';
import transportLogistics from 'assets/images/industries/transport-logistics.png';
import { links } from 'shared/constants';

export const Industries = () => {
  return (
    <IndustriesStyles>
      <PageHeader image={headerImage}>
        <h1>Industries</h1>
        <p>
          Address your current challenges with traditional methods of market research and <br /> make better-informed investment decisions.
          Explore some of the benefits in your <br /> sector to see what O<sub>2</sub> Motion could do for you.
        </p>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href="/">
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" aria-current="page">
          INDUSTRIES
        </Link>
      </Breadcrumb>
      <PageContent className="center">
        <div className="industries-carts">
          <PortraitCart
            link={links.industries.pages.governmentPolicing.link}
            image={governmentPolicing}
            header={links.industries.pages.governmentPolicing.label}
            description="Make data-led policy and investment decisions and improve your response to events."
            learnMore={links.industries.pages.governmentPolicing.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.industries.pages.leisureTourism.link}
            image={leisureTourism}
            header={links.industries.pages.leisureTourism.label}
            description="Improve planning and performance with insight on your visitors."
            learnMore={links.industries.pages.leisureTourism.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.industries.pages.localAuthorities.link}
            image={localAuthorities}
            header={links.industries.pages.localAuthorities.label}
            description="Drive evidence-based plans and policies with movement data for people in your area."
            learnMore={links.industries.pages.localAuthorities.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.industries.pages.mediaAdvertising.link}
            image={mediaAdvertising}
            header={links.industries.pages.mediaAdvertising.label}
            description="Improve campaign planning with insights on behavioural audiences around the UK."
            learnMore={links.industries.pages.mediaAdvertising.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.industries.pages.retail.link}
            image={retail}
            header={links.industries.pages.retail.label}
            description="Improve planning and performance with insight on the movements of people in and around your stores."
            learnMore={links.industries.pages.retail.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.industries.pages.retailProperty.link}
            image={retailProperty}
            header={links.industries.pages.retailProperty.label}
            description="Improve planning and performance with insight on the movements of people in and around your locations."
            learnMore={links.industries.pages.retailProperty.link}
            borderColor="#dadada"
          />
          <PortraitCart
            link={links.industries.pages.transportLogistics.link}
            image={transportLogistics}
            header={links.industries.pages.transportLogistics.label}
            description="Improve transport planning and modelling with movement insights around the UK."
            learnMore={links.industries.pages.transportLogistics.link}
            borderColor="#dadada"
          />
        </div>
      </PageContent>
    </IndustriesStyles>
  );
};
