import { Link } from '@mui/material';
import { PageContent, PageHeader, IconCart, LandscapeCart, ButtonLink } from 'shared/ui';
import { Breadcrumb } from 'shared/ui/Breadcrumb/Breadcrumb';
import { LocalAuthoritiesStyles } from './LocalAuthorities.styles';
import { InternationalPhone, Spend, API, Portal, Stand, Next } from 'shared/icons';

import headerImage from 'assets/images/local-authorities/header-image.png';
import microLocationInsights from 'assets/images/local-authorities/micro-location-insights.png';
import movementInsights from 'assets/images/local-authorities/movement-insights.png';
import originDestinationInsights from 'assets/images/local-authorities/origin-destination-insights.png';
import realTimeLocationInsights from 'assets/images/local-authorities/real-time-location-insights.png';
import realTimeTravelInsights from 'assets/images/local-authorities/real-time-travel-insights.png';
import roadInsights from 'assets/images/local-authorities/road-travel-insights.png';
import spatialInsights from 'assets/images/local-authorities/spatial-insights.png';
import greyBackground from 'assets/images/local-authorities/grey-background.png';
import { links } from 'shared/constants';

export const LocalAuthorities = () => {
  return (
    <LocalAuthoritiesStyles>
      <PageHeader image={headerImage}>
        <h1>{links.industries.pages.localAuthorities.label}</h1>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.industries.link}>
          {links.industries.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.industries.pages.localAuthorities.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <PageContent className="description">
        <hgroup>
          <h1>Drive evidence-based plans and policies with movements data for people in your area</h1>
        </hgroup>
        <article>
          <p>
            With our detailed insights on the movements of people in your area you can improve transport models or understand the catchment
            area of your city centre to make evidence-based infrastructure decisions. Insights on movements and socio-demographic profiles
            will help inform equitable policies and public services investment. And when an event or crisis happens, you can rely on our
            real-time data to monitor crowd sizes and quickly allocate resources in the right location.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Spend />}>
            <h1>Better ROI for Strategic Initiatives</h1>
            <p>Improve the success of infrastructure and public services investments with a better understanding of their users.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Easy to adopt</h1>
            <p>Data can be accessed via API, Blob storage or our Portal, for easy integration into your workflows.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>Our solutions are readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Large scale</h1>
            <p>Our data is obtained from 35% of the UK population and expanded to fairly represent the entirety of the UK’s population.</p>
          </IconCart>
          <IconCart icon={<Stand />}>
            <h1>Historical & real-time</h1>
            <p>We offer data as old as 6 years ago and as recent as 3 minutes ago to meet your exact needs.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Related Products</h1>
        </hgroup>
        <LandscapeCart>
          <img src={microLocationInsights} />
          <article>
            <h2>{links.products.pages.microLocationInsights.label}</h2>
            <p>Local, location-based insights on visitors so you can improve performance measurement.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.microLocationInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.movementInsights.label}</h2>
            <p>Low latency journey and location data across the UK and internationally.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.movementInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={movementInsights} />
        </LandscapeCart>
        <LandscapeCart>
          <img src={originDestinationInsights} />
          <article>
            <h2>{links.products.pages.originDestination.label}</h2>
            <p>Travel analysis to identify how people move around different regions of the UK.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.originDestination.label}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.realTimeLocationInsights.label}</h2>
            <p>Real-time data on the number and profiles of people in an area.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.realTimeLocationInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={realTimeLocationInsights} />
        </LandscapeCart>
        <LandscapeCart>
          <img src={realTimeTravelInsights} />
          <article>
            <h2>{links.products.pages.realTimeTravelInsights.label}</h2>
            <p>Real-time traffic monitoring across road networks using mobile network data.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.realTimeTravelInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.roadInsights.label}</h2>
            <p>Insights into how travellers in the UK move across the road network.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.roadInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={roadInsights} />
        </LandscapeCart>
        <LandscapeCart>
          <img src={spatialInsights} />
          <article>
            <h2>{links.products.pages.spatialInsights.label}</h2>
            <p>Video analytics and AI to deliver insights around customer movement and flow in a venue.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.spatialInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
      </PageContent>
      <div className="grey-background" style={{ backgroundImage: `url(${greyBackground})` }}>
        <PageContent className="read-case-study">
          <hgroup>
            <h2>
              Learn how O<sub>2</sub> Mobile Recording helps GLA meet financial compliance requirements
            </h2>
            <ButtonLink>
              <a
                className="secondary"
                rel="noreferrer"
                target="_blank"
                href="https://static-www.o2.co.uk/sites/default/files/2020-03/O2_GLA_Customer_Story_v1%20%281%29.pdf"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </hgroup>
          <article>
            <p>
              “In common with all public sector procurements, we’re looking for economy, proficiency and effectiveness. The solution from O
              <sub>2</sub>
              worked very well for us and enabled us to deliver the secure solution that we needed.”
            </p>
            <div>
              <span>Luke Webster</span>
              <br />
              <span>Chief Investment Officer, Greater London Authority</span>
            </div>
          </article>
        </PageContent>
      </div>
    </LocalAuthoritiesStyles>
  );
};
