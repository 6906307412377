import { HTMLAttributes } from 'react';
import { LandscapeCartStyles } from './LandscapeCart.styles';

export interface LandscapeCartProps extends HTMLAttributes<HTMLElement> {
  children: JSX.Element[] | JSX.Element;
}

export const LandscapeCart = (props: LandscapeCartProps) => {
  return (
    <LandscapeCartStyles className={props.className} style={props.style}>
      {props.children}
    </LandscapeCartStyles>
  );
};
