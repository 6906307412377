import * as msal from '@azure/msal-browser';
import { getConfig } from '../../shared/lib/config';
import { initializeAppInsights } from '../../shared/lib/insights';

const config = getConfig();

const msalConfig: msal.Configuration = {
  auth: {
    clientId: config.REACT_APP_MSAL_CLIENT_ID,
    authority: config.REACT_APP_MSAL_AUTHORITY,
    knownAuthorities: [config.REACT_APP_MSAL_OPENID_CONFIGURATION],
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const msalInstance: msal.PublicClientApplication =
  process.env.NODE_ENV === 'test' ? (null as any) : new msal.PublicClientApplication(msalConfig);

const LOCAL_STORAGE_USERNAME_KEY = 'media-planning-dashboard:username';

export interface Authorised {
  isAuthenticated: true;
  username: string;
}

export type AuthState = { isAuthenticated: false } | Authorised;

async function tryGetCurrentSession() {
  try {
    const redirectResult = await msalInstance.handleRedirectPromise();
    if (redirectResult) {
      return redirectResult;
    }
    const account = await msalInstance.getAllAccounts()[0];
    return await msalInstance.acquireTokenSilent({
      scopes: [config.REACT_APP_MSAL_SCOPE],
      account,
    });
  } catch (err) {
    if (err instanceof msal.AuthError) {
      if (err.errorMessage.startsWith('AADB2C90118:')) {
        const passwordResetUrl = new URL(config.REACT_APP_MSAL_PASSWORD_RESET_FLOW);
        passwordResetUrl.searchParams.set('redirect_uri', window.location.origin);
        window.location.assign(passwordResetUrl.href);
        return undefined;
      }
    }
    console.log('tryGetCurrentSession failed with', err);
    return undefined;
  }
}

export const getEmail = () => {
  return msalInstance.getAllAccounts()?.[0]?.username;
};
export const getName = () => {
  return msalInstance.getAllAccounts()?.[0]?.name ?? getEmail() ?? '';
};

export const getAuthState = async (): Promise<AuthState> => {
  const session = await tryGetCurrentSession();
  initializeAppInsights(getEmail());

  if (session === undefined) {
    return { isAuthenticated: false };
  }
  return {
    isAuthenticated: true,
    username: session!.account!.username,
  };
};

export const getJwtToken = async () => {
  const account = await msalInstance.getAllAccounts()[0];
  const accessTokenReq = await msalInstance.acquireTokenSilent({
    scopes: [config.REACT_APP_MSAL_SCOPE],
    account,
  });
  if (accessTokenReq.accessToken !== '') {
    return 'Bearer ' + accessTokenReq.accessToken;
  }

  await msalInstance.acquireTokenRedirect({
    scopes: [config.REACT_APP_MSAL_SCOPE],
    account,
  });

  throw new Error('Unreachable - we should now have navigated away');
};

export interface LoginArgs {
  username: string;
  password: string;
}

export type LoginResult =
  | { type: 'ERROR'; message: string; originalError: Error }
  | { type: 'CANCELLED' }
  | { type: 'NEW_PASSWORD_REQUIRED'; user: any }
  | { type: 'SUCCESS'; user: any };

export const login = async (): Promise<LoginResult> => {
  try {
    await msalInstance.loginRedirect({
      scopes: [config.REACT_APP_MSAL_SCOPE],
      loginHint: localStorage.getItem(LOCAL_STORAGE_USERNAME_KEY) ?? undefined,
    });
    throw new Error('Unreachable');
  } catch (err: any) {
    if (err instanceof msal.AuthError) {
      if (err.errorCode === 'user_cancelled') {
        return {
          type: 'CANCELLED',
        };
      }
      if (err.errorMessage.startsWith('AADB2C90118:')) {
        const passwordResetUrl = new URL(config.REACT_APP_MSAL_PASSWORD_RESET_FLOW);
        passwordResetUrl.searchParams.set('redirect_uri', window.location.origin);
        window.location.assign(passwordResetUrl.href);
        return { type: 'CANCELLED' };
      }
    }
    return {
      type: 'ERROR',
      message: err.message,
      originalError: err,
    };
  }
};

export const signOut = async () => {
  localStorage.removeItem(LOCAL_STORAGE_USERNAME_KEY);
  await msalInstance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
};
