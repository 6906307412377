import styled from 'styled-components';

export const LoaderStyles = styled.section`
  color: ${(props) => props?.theme?.palette?.background?.paper};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 3rem;

  height: 100vh;

  color: #ffffff;
  p {
    text-align: center;
    font-size: 6rem;
    line-height: 6rem;
    color: #0019a5;
    margin: 20px;
  }
  > * {
    flex: 0 0 auto;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
`;
