import { FrameStyles } from './Frame.styles';

interface FrameProps {
  title?: string;
  url: string;
  width: number;
  height: number;
}

export const Frame = ({ url, width, height, title = 'YouTube video player' }: FrameProps) => {
  return (
    <FrameStyles>
      <iframe
        src={url}
        title={title}
        width={width}
        height={height}
        allow="web-share; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        frameBorder="0"
        allowFullScreen
      />
    </FrameStyles>
  );
};
