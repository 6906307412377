import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { lazy, Suspense, useLayoutEffect, useState } from 'react';
import { Navigate, Route, Router, Routes } from 'react-router-dom';

import { Footer } from 'shared/components/Footer/Footer';
import { NavMenu } from 'shared/components/NavMenu';

import { appInsightsReactPlugin, browserHistory, historyPublisher } from 'shared/lib/insights';
import { AuthProvider } from './auth/components/AuthProvider';
import { LoggingOut } from './auth/components/LoggingOut';
import { RequireLogin } from './auth/components/RequireLogin';
import { UserGroupsProvider } from './auth/components/UserMetadataProvider';
import { ErrorBoundary } from './shared/components/ErrorBoundary';
import { Loader } from 'shared/ui';
import { links } from 'shared/constants';

import { ErrorPage } from 'error-page/ErrorPage';
import { ContactUs } from 'contact-us/ContactUs';
import { About, DataPrivacy, HowDoesItWork } from 'about';
import { Products, MicroLocationInsights, SpatialInsights, RailInsights } from 'products';
import {
  Industries,
  GovernmentPolicing,
  MediaAdvertising,
  LeisureTourism,
  RetailProperty,
  LocalAuthorities,
  Retail,
  TransportLogistics,
} from 'industries';
import { CssBaseline } from '@mui/material';

const MovementInsightsLazy = lazy(() => import('./mi/components/Mi'));
const RttiLazy = lazy(() => import('./rtti/components/rtti'));
const AudienceInsightsLazy = lazy(() => import('./products/audience-insights/AudienceInsights'));
const OriginDestinationLazy = lazy(() => import('./products/origin-destination-insights/OriginDestinationInsights'));
const RoadTravelInsightsLazy = lazy(() => import('./products/road-insights/RoadTravelInsights'));
const RtliLazy = lazy(() => import('./rtli/components/Rtli'));

function App(): JSX.Element {
  const [, setState] = useState();
  useLayoutEffect(() => {
    historyPublisher.subscribe(setState);
  }, [browserHistory]);

  return (
    <AppInsightsErrorBoundary onError={ErrorBoundary} appInsights={appInsightsReactPlugin}>
      <AuthProvider>
        <RequireLogin>
          <UserGroupsProvider>
            <CssBaseline />
            <Router location={browserHistory.location} navigationType={browserHistory.action} navigator={browserHistory}>
              <NavMenu />
              <div className="page-content">
                <Routes>
                  <Route
                    path={`${links.products.pages.realTimeTravelInsights.link.replace(links.products.link, '')}/*`}
                    element={<Navigate to={links.products.pages.realTimeTravelInsights.link} replace={true} />}
                  />
                  <Route
                    path={`${links.products.pages.realTimeTravelInsights.link}/*`}
                    element={
                      <Suspense fallback={<Loader height="calc(100vh - var(--footer-height))" />}>
                        <RttiLazy />
                      </Suspense>
                    }
                  />
                  <Route
                    path={`${links.products.pages.movementInsights.link.replace(links.products.link, '')}/*`}
                    element={<Navigate to={links.products.pages.movementInsights.link} replace={true} />}
                  />
                  <Route
                    path={`${links.products.pages.movementInsights.link}/*`}
                    element={
                      <Suspense fallback={<Loader height="calc(100vh - var(--footer-height))" />}>
                        <MovementInsightsLazy />
                      </Suspense>
                    }
                  />
                  <Route
                    path={`${links.products.pages.audienceInsights.link.replace(links.products.link, '')}/*`}
                    element={<Navigate to={links.products.pages.audienceInsights.link} replace={true} />}
                  />
                  <Route
                    path={`${links.products.pages.audienceInsights.link}/*`}
                    element={
                      <Suspense fallback={<Loader height="calc(100vh - var(--footer-height))" />}>
                        <AudienceInsightsLazy />
                      </Suspense>
                    }
                  />
                  <Route
                    path={`${links.products.pages.spatialInsights.link.replace(links.products.link, '')}/*`}
                    element={<Navigate to={links.products.pages.spatialInsights.link} replace={true} />}
                  />
                  <Route path={`${links.products.pages.spatialInsights.link}/*`} element={<SpatialInsights />} />
                  <Route
                    path={`${links.products.pages.railInsights.link.replace(links.products.link, '')}/*`}
                    element={<Navigate to={links.products.pages.railInsights.link} replace={true} />}
                  />
                  <Route path={`${links.products.pages.railInsights.link}/*`} element={<RailInsights />} />
                  <Route
                    path={`${links.products.pages.microLocationInsights.link.replace(links.products.link, '')}/*`}
                    element={<Navigate to={links.products.pages.microLocationInsights.link} replace={true} />}
                  />
                  <Route path={`${links.products.pages.microLocationInsights.link}/*`} element={<MicroLocationInsights />} />
                  <Route
                    path={`${links.products.pages.roadInsights.link}/*`}
                    element={
                      <Suspense fallback={<Loader height="calc(100vh - var(--footer-height))" />}>
                        <RoadTravelInsightsLazy />
                      </Suspense>
                    }
                  />
                  <Route
                    path={`${links.products.pages.originDestination.link.replace(links.products.link, '')}/*`}
                    element={<Navigate to={links.products.pages.originDestination.link} replace={true} />}
                  />
                  <Route
                    path={`${links.products.pages.originDestination.link}/*`}
                    element={
                      <Suspense fallback={<Loader height="calc(100vh - var(--footer-height))" />}>
                        <OriginDestinationLazy />
                      </Suspense>
                    }
                  />
                  <Route
                    path={`${links.products.pages.realTimeLocationInsights.link.replace(links.products.link, '')}/*`}
                    element={<Navigate to={links.products.pages.realTimeLocationInsights.link} replace={true} />}
                  />
                  <Route
                    path={`${links.products.pages.realTimeLocationInsights.link}/*`}
                    element={
                      <Suspense fallback={<Loader height="calc(100vh - var(--footer-height))" />}>
                        <RtliLazy />
                      </Suspense>
                    }
                  />
                  <Route path="/sign-out" element={<LoggingOut />} />
                  <Route path={links.products.link} element={<Products />} />
                  <Route path={links.industries.link} element={<Industries />} />
                  <Route path={links.industries.pages.governmentPolicing.link} element={<GovernmentPolicing />} />
                  <Route path={links.industries.pages.mediaAdvertising.link} element={<MediaAdvertising />} />
                  <Route path={links.industries.pages.leisureTourism.link} element={<LeisureTourism />} />
                  <Route path={links.industries.pages.retailProperty.link} element={<RetailProperty />} />
                  <Route path={links.industries.pages.localAuthorities.link} element={<LocalAuthorities />} />
                  <Route path={links.industries.pages.retail.link} element={<Retail />} />
                  <Route path={links.industries.pages.transportLogistics.link} element={<TransportLogistics />} />
                  <Route path={links.contactUs.link} element={<ContactUs />} />
                  <Route path={links.about.pages.howDoesItWork.link} element={<HowDoesItWork />} />
                  <Route path={links.about.pages.dataPrivacy.link} element={<DataPrivacy />} />
                  <Route path={links.about.link} element={<About />} />
                  <Route path={links.errorPage.link} element={<ErrorPage />} />
                  <Route path="*" element={<Navigate to={links.about.link} replace={true} />} />
                </Routes>
              </div>
              <Footer />
            </Router>
          </UserGroupsProvider>
        </RequireLogin>
      </AuthProvider>
    </AppInsightsErrorBoundary>
  );
}

export default App;
