import { FooterStyles } from './Footer.styles';
import { NavLink } from 'react-router-dom';
import { links } from 'shared/constants';

export const Footer = () => {
  return (
    <FooterStyles>
      <ul>
        <NavLink to={links.about.link}>{links.about.label}</NavLink>
        <NavLink to={links.products.link}>{links.products.label}</NavLink>
        <NavLink to={links.industries.link}>{links.industries.label}</NavLink>
        <NavLink to={links.contactUs.link}>{links.contactUs.label}</NavLink>
      </ul>
    </FooterStyles>
  );
};
