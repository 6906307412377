import { ButtonLink, Frame, IconCart, LandscapeCart, PageContent } from 'shared/ui';
import { Stand, Friend, InStore, Success, Video, Escalator, Next } from 'shared/icons';
import { frameLinks } from 'shared/constants';

import { SIOverviewStyles } from './SIOverview.styles';
import storiesImage1 from 'assets/images/si/stories-image-1.png';

export const SIOverview = () => {
  return (
    <SIOverviewStyles>
      <PageContent className="description">
        <hgroup>
          <h1>Accurate customer insights from any space</h1>
        </hgroup>
        <article>
          <p>
            Spatial Insights uses video analytics and Artificial Intelligence to deliver real-time and historical insights around customer
            movement and flow inside a venue.
          </p>
          <br />
          <p>
            It enables you to make informed decisions around space and capacity management, as well as improve customer experience and staff
            productivity by reducing service and transaction times, and monitoring queue lengths, dwell times and conversion rates on
            certain products and services.
          </p>
        </article>
        <Frame url={frameLinks.spatialInsights} width={960} height={539} />
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Stand />}>
            <h1>Real-time & historical</h1>
            <p>Rich insights from a dashboard to support decision making and improvements.</p>
          </IconCart>
          <IconCart icon={<Success />}>
            <h1>Improve customer experience</h1>
            <p>Optimise resources and support staff efficiency by reducing service and transaction times.</p>
          </IconCart>
          <IconCart icon={<Friend />}>
            <h1>Capacity management</h1>
            <p>Monitor capacity and social distancing.</p>
          </IconCart>
          <IconCart icon={<InStore />}>
            <h1>Optimise venue layouts</h1>
            <p>Accurate insights on footfall, dwell time and conversion to optimise venue layouts and points of sale.</p>
          </IconCart>
          <IconCart icon={<Video />}>
            <h1>Easy to adopt</h1>
            <p>An easy-to-install box connected to your existing CCTV network.</p>
          </IconCart>
          <IconCart icon={<Escalator />}>
            <h1>Analyse passersby</h1>
            <p>Measure the number and profile of passersby to optimise window displays.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Customer success stories</h1>
        </hgroup>
        <LandscapeCart>
          <img src={storiesImage1} />
          <article>
            <h2>Using the power of AI and sophisticated analytics to enhance customer experience</h2>
            <p>
              “As a retail business ourselves, our number one priority is delivering the best possible in-store experience – for customers
              and our staff.”
            </p>
            <i>
              Sergio Budkin, Director of Market Development at Virgin Media O<sub>2</sub>
            </i>
            <br />
            <ButtonLink>
              <a
                className="quaternary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/0/O2+Spatial+Insights+Customer+Story.pdf/3e868462-cac9-2cd2-72ed-011adc60ea5e?t=1652189209333"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
      </PageContent>
    </SIOverviewStyles>
  );
};
