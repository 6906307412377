import styled from 'styled-components';

export const MoviePageStyles = styled.section`
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  color: #575757;

  .description {
    margin: 0 0 40px;
  }

  .hearing-more {
    font-size: 18px;
    line-height: 24px;
    margin-top: 80px;
    margin-bottom: 16px;
  }

  video {
    border-radius: 10px;
  }

  button {
    text-transform: none;
    font-weight: normal;
  }
`;
