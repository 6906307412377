import styled from 'styled-components';

export const DataDownloadStyles = styled.section`
  color: #575757;
  font-weight: 400;
  background: white;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;

  .data-download-wrapper {
    padding-top: 0;
  }
  .MuiTreeView-root {
    margin-top: 2rem;
    width: 100%;
    min-height: calc(100vh - 650px);

    .MuiTreeItem-label {
      display: flex;
      align-items: center;

      .folder-icon {
        margin-top: 5px;
        margin-right: 16px;
      }
    }
  }
  b {
    display: block;
    margin: 0 0 0.5rem;

    .MuiCheckbox-root {
      padding-left: 0;
    }
  }

  .MuiCollapse-root {
    margin-bottom: 0.5rem;
  }

  .MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiButtonBase-root.MuiButton-root {
    height: 40px;
    font-weight: normal;
    letter-spacing: -0.02em;
    margin-bottom: 2px;
    font-size: 14px;

    &:last-child {
    }
  }

  .loading-wrapper {
    padding-right: 1rem;
    display: flex;
    align-items: center;
  }

  .tree-loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20rem;
  }
  .button-wrapper {
    position: relative;
  }
  .progress-wrapper {
    position: relative;
    color: white;
    margin-left: 15px;
    margin-top: 6px;
    > div {
      top: 5px;
      width: 100%;
      text-align: center;
      position: absolute;
      font-size: 12px;
    }
  }
  .selected-size {
    padding-left: 1rem;
    font-size: 1.5rem;
    align-items: baseline;
    padding-top: 0.45rem;
    font-weight: normal;
    opacity: 0.3;
  }

  .search-button-pending {
    outline: 3px solid ${(props) => props.theme.palette.background.paper};
  }
  .result-cell-wrapper {
    margin-top: 3rem;
  }

  .MuiTreeItem-content {
    height: 56px;
    padding-left: 53px;
    background: #f4f4f4;
    margin-bottom: 8px;
    position: relative;
    &.Mui-focused {
      background: #f4f4f4;
    }
    &:hover {
      background: #ededed;
    }

    .MuiCheckbox-root {
      position: absolute;
      left: -67px;
      top: -8.5px;
      display: none;

      &:after {
        height: 13.5px;
        width: 13.5px;
        position: absolute;
        left: 14.5px;
        top: 14.4px;
        content: ' ';
        display: block;
        background: white;
        border-radius: 2px;
      }
    }

    &:hover .MuiCheckbox-root {
      display: inline-flex;
    }
    & .Mui-checked {
      display: inline-flex;
      &:after {
        display: none;
      }
    }
  }

  .filter-wrapper {
    display: flex;
    align-items: end;
    padding-top: 80px;
    margin-bottom: 50px;
    justify-content: space-between;
    .left-side-wrapper {
      display: flex;
      align-items: end;
    }
  }
  .data-download-select {
    width: 200px;
  }
`;
