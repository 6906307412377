import { useGetMetadataLoadingStatus } from 'auth/lib/useGetMetadataLoadingStatus';
import { LoadingDataStates } from 'shared/models/LoadingDataState';

import { PageContent, MoviePage } from 'shared/ui';
import { IframePage } from 'shared/components/IframePage';
import { SharedConsts } from 'shared/constants/SharedConsts';
import { movieLinks } from 'shared/constants/movieLinks';
import { MLIDashboardStyles } from './MLIDashboard.styles';

interface MLIDashboardProps {
  hasTableauAccess: boolean;
}

export const MLIDashboard = ({ hasTableauAccess }: MLIDashboardProps) => {
  const isMetadataLoaded = useGetMetadataLoadingStatus() === LoadingDataStates.loaded;

  if (!isMetadataLoaded) return <div>loadings...</div>;

  return (
    <MLIDashboardStyles>
      {hasTableauAccess ? (
        <IframePage src={SharedConsts.mliDashboardIframeUrl} />
      ) : (
        <PageContent>
          <MoviePage
            description="The following video captures a few of the key functionalities of the Micro Location Insights dashboard."
            height={735}
            width={1170}
            movieUrl={movieLinks.mliDemo}
            subtitlesUrl="/mli-demo.vtt"
          />
        </PageContent>
      )}
    </MLIDashboardStyles>
  );
};
