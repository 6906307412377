import { Navigate, Route, Routes } from 'react-router-dom';
import { useUserHasTableauAccess } from 'auth/lib/useUserHasTableauAccess';
import { Breadcrumb, PageHeader } from 'shared/ui';
import { Link } from '@mui/material';
import { links } from 'shared/constants';

import { RIOverview } from './components/overview/RIOverview';
import { RIDashboard } from './components/dashboard/RIDashboard';
import { RISpecification } from './components/specifications/RISpecification';

import { RailInsightsStyles } from './RailInsights.styles';
import headerImage from 'assets/images/ri/header-image.png';

const railInsights = () => {
  const hasTableauAccess = useUserHasTableauAccess();
  const menu = [
    { label: 'Product Overview', link: 'overview' },
    { label: 'Data Dashboard', link: 'dashboard' },
    { label: 'Specifications', link: 'specifications' },
  ];

  return (
    <RailInsightsStyles>
      <PageHeader image={headerImage} menus={menu}>
        <h1>{links.products.pages.railInsights.label}</h1>
        <p>
          Rail Insights provides insight into how travellers in <br /> the UK move across the rail transport network.
        </p>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href={links.about.link}>
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href={links.products.link}>
          {links.products.label.toUpperCase()}
        </Link>
        <Link underline="none" aria-current="page">
          {links.products.pages.railInsights.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <Routes>
        <Route path="overview" element={<RIOverview />} />
        <Route path="dashboard/*" element={<RIDashboard hasTableauAccess={hasTableauAccess} />}></Route>
        <Route path="specifications" element={<RISpecification />}></Route>
        <Route path="" element={<Navigate to="overview" replace={true} />} />
      </Routes>
    </RailInsightsStyles>
  );
};

export default railInsights;
