import styled from 'styled-components';

export const ProductsStyles = styled.section`
  .products-wrap {
    min-width: 100vh;
    display: grid;
    place-items: center;

    .products-carts {
      display: grid;
      grid-gap: 100px;
      grid-template-columns: repeat(6, 1fr);
      margin: 80px 0 160px 0;
    }
  }
`;
