export const CyberSecurity = () => {
  return (
    <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.7088 11.4239V9.71515C23.7088 4.36475 19.3568 0.00634766 14 0.00634766C8.64319 0.00634766 4.29119 4.35835 4.29119 9.71515V11.4239C2.40319 11.4239 0.860794 12.9663 0.860794 14.8543V28.5631C0.860794 30.4511 2.40319 31.9935 4.29119 31.9935H23.7152C25.6032 31.9935 27.1456 30.4511 27.1456 28.5631V14.8543C27.1264 12.9663 25.6032 11.4239 23.7088 11.4239ZM6.58239 9.71515C6.58239 5.62555 9.91039 2.29755 14 2.29755C18.0896 2.29755 21.4176 5.62555 21.4176 9.71515V11.4239H6.58239V9.71515ZM24.848 28.5567C24.848 29.1903 24.3296 29.6959 23.7088 29.6959H4.29119C3.65759 29.6959 3.15199 29.1775 3.15199 28.5567V14.8607C3.15199 14.2271 3.67039 13.7215 4.29119 13.7215H23.7152C24.3488 13.7215 24.8544 14.2399 24.8544 14.8607V28.5567H24.848Z"
        fill="#0019A5"
      />
    </svg>
  );
};
