import { TooltipProps, Tooltip, tooltipClasses } from '@mui/material';
import styled from 'styled-components';

interface BootstrapTooltipProps extends TooltipProps {
  control: JSX.Element[] | JSX.Element;
}

export const BootstrapTooltip = styled(({ className, control, ...props }: BootstrapTooltipProps) => (
  <>
    {control}
    <Tooltip {...props} arrow classes={{ popper: className }} />
  </>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid #999',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 16,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3C3C3C',
    letterSpacing: '-0.02em',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}));
