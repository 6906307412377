import { Autocomplete, Chip, FormControl, SxProps, TextField } from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import { MultiSelectStyles } from './MultiSelect.styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/system/createTheme';

interface MultiSelectProps {
  className?: string;
  onChange: (value: any) => void;
  value: string[] | string;
  menuItems: readonly any[];
  leftLabel: string;
  rightLabel?: string;
  getLabel?: (e: any) => string;
  limitWhenClosed?: number;
  limitWhenOpen?: number;
  multi?: boolean;
  selectAll?: boolean;
  disableCloseOnSelect?: boolean;
  getOptionDisabled?: (v: any) => boolean;
  noValueLabel?: string;
  maxSymbols?: number;
  filterOptions?: Function;
  disabled?: boolean;
  maxSelectedElements?: number;
  error?: boolean;
  disableClearable?: boolean;
  placeholder?: string;
  popperSx?: SxProps<Theme>;
}
const all = 'All';
export function MultiSelect({
  onChange,
  leftLabel,
  rightLabel = '',
  value,
  menuItems,
  getLabel = (v) => v,
  limitWhenClosed = 4,
  limitWhenOpen = 2,
  multi = true,
  selectAll = true,
  disableCloseOnSelect = true,
  noValueLabel = '',
  getOptionDisabled = () => false,
  maxSymbols = 0,
  filterOptions,
  disabled,
  maxSelectedElements,
  error = false,
  disableClearable = false,
  className = '',
  placeholder = '',
  popperSx = {},
}: MultiSelectProps) {
  const hasEnabledOptions = useMemo(() => {
    return menuItems.some((i) => !getOptionDisabled(i));
  }, [getOptionDisabled, menuItems]);
  const getOptionDisabledEffective = useMemo(() => {
    return (v: any) =>
      (v !== all && getOptionDisabled(v)) ||
      (multi && !!maxSelectedElements && maxSelectedElements <= value?.length && !value?.includes(v));
  }, [getOptionDisabled, maxSelectedElements]);

  const items: readonly any[] = useMemo(() => {
    if (!multi) {
      return menuItems;
    }
    if (selectAll && menuItems.length && hasEnabledOptions) {
      return [all, ...[...menuItems].sort((a, b) => +getOptionDisabled(a) - +getOptionDisabled(b))];
    } else if (!selectAll && menuItems.length) {
      return [...menuItems].sort((a, b) => +getOptionDisabled(a) - +getOptionDisabled(b));
    }
    return [];
  }, [menuItems]);
  const renderedValue = useMemo(() => {
    if (!multi) {
      return value;
    }
    if (selectAll && menuItems.length && menuItems.filter((i: any) => !getOptionDisabled(i)).length === value.length) {
      return [all, ...value];
    } else if (menuItems.length !== value.length) {
      return value;
    }
    return value;
  }, [value, menuItems]);

  function onChangeHandler(e: SyntheticEvent, v: any[]) {
    if (!multi) {
      onChange(v);
      return;
    }
    const allClicked = (e.target as HTMLElement).innerText === all;
    const allSelected = v.includes(all);
    if (allClicked && allSelected) {
      onChange(menuItems.filter((i) => !getOptionDisabled(i)));
    } else if (allClicked && !allSelected) {
      onChange([]);
    } else {
      onChange(v.filter((i) => i !== all && !getOptionDisabled(i)));
    }
  }
  const effectiveLimitWhenOpen = useMemo(() => {
    if (maxSymbols && multi) {
      let s = 0;
      for (let i = 0; i < value.length; i++) {
        s += getLabel(value[i]).length + 8;
        if (s > maxSymbols) {
          return i || 1;
        } else if (i === value.length - 1) {
          return i + 1;
        }
      }
    }
    return limitWhenOpen;
  }, [value]);

  const renderOption = useMemo(() => {
    const getOptionLabel = (v: any) => (v === all ? all : getLabel(v));
    const AutoOption = (props: any, option: any) => {
      return <li {...props}>{getOptionLabel(option)}</li>;
    };
    return AutoOption;
  }, [getLabel]);
  return (
    <MultiSelectStyles className={error ? 'multiselect-error ' + className : className}>
      <FormControl fullWidth variant="standard">
        <Autocomplete
          limitTags={limitWhenClosed}
          getLimitTagsText={() => (value.length > limitWhenClosed ? `+ ${value.length - limitWhenClosed}` : '')}
          size="small"
          options={items}
          popupIcon={<KeyboardArrowDownIcon />}
          multiple={multi}
          onChange={(e, v) => onChangeHandler(e, v)}
          disabled={menuItems.length === 0 || disabled}
          disableCloseOnSelect={disableCloseOnSelect}
          openOnFocus
          disableClearable={disableClearable}
          filterOptions={filterOptions as any}
          renderOption={renderOption}
          componentsProps={{
            popper: { sx: popperSx, className: multi ? '' : 'single-select' },
          }}
          getOptionDisabled={getOptionDisabledEffective}
          value={multi ? (renderedValue.length ? renderedValue : noValueLabel ? [all] : []) : value}
          renderTags={(val, getTagProps) => {
            const effectiveValue = value as any[];
            const d = effectiveValue
              .slice(0, effectiveLimitWhenOpen)
              .map((v, index) => (
                <Chip
                  label={getLabel(v)}
                  size="small"
                  deleteIcon={<CloseIcon />}
                  {...getTagProps({ index: index + (val[0] === 'All' ? 1 : 0) })}
                  key={index}
                />
              ));
            return !effectiveValue.length
              ? noValueLabel
              : effectiveValue.length > effectiveLimitWhenOpen
              ? [
                  ...d,
                  <div key={effectiveLimitWhenOpen} className="MuiAutocomplete-tag MuiAutocomplete-tagSizeSmall">
                    + {effectiveValue.length - effectiveLimitWhenOpen}
                  </div>,
                ]
              : d;
          }}
          renderInput={(params) => (
            <TextField
              {...{ ...params, InputLabelProps: { ...params.InputLabelProps, shrink: true } }}
              placeholder={value && value.length ? '' : placeholder}
              variant="filled"
              label={
                <>
                  <div>{leftLabel}</div>
                  <div>{rightLabel}</div>
                </>
              }
            />
          )}
        />
      </FormControl>
    </MultiSelectStyles>
  );
}
