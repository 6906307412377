import styled from 'styled-components';

export const NavMenuStyles = styled.section`
  width: var(--page-width);
  margin: 0 auto;

  nav {
    padding: 0 32px;
    left: auto;
    position: absolute;
    width: inherit;
    display: inline-flex;
    align-items: center;
    align-content: end;
    justify-content: space-between;
  }

  .left-menu {
    float: left;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 40px;
  }

  a {
    color: white;
    font-size: 1.6rem;
    text-decoration: none;
    line-height: 3.2rem;
    display: block;
    padding: 30px 0px;
    width: max-content;
  }

  a:hover,
  a.active {
    text-decoration: underline solid white 2px;
    text-underline-offset: 10px;
  }

  li:hover {
    background-color: #808cd2;
  }
`;

export const HeaderBackground = styled.section`
  min-width: var(--min-page-width);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 96px;
  background: #0019a5;
  z-index: -1;
`;
