import styled from 'styled-components';

export const MultiSelectStyles = styled.section`
  &&.multiselect-error {
    outline: 1px solid ${(props) => props.theme?.palette?.warning?.main};
  }
  .MuiAutocomplete-root {
    .MuiChip-root {
      border: 1px solid #dadada;
      border-radius: 20px;
      background: transparent;
      & > *,
      & > .MuiSvgIcon-root.MuiChip-deleteIcon {
        color: #3c3c3c;
      }
    }
    .MuiFilledInput-root.MuiInputBase-root {
      flex-wrap: nowrap;
      overflow: hidden;
      padding-top: 35px;
      background: none;
      position: relative;

      &:before {
        position: absolute;
        left: 0;
        right: 0;
        top: 35px;
        bottom: 0;
        border: 1px solid #dadada;
        border-radius: 6px;
      }
      &:after {
        display: none;
      }

      &.Mui-focused {
        max-height: 10rem;
        overflow-y: auto;
      }
    }
    .MuiFilledInput-input {
      box-sizing: border-box;
      height: 44px;
      color: #575757;
    }
    .MuiButtonBase-root.MuiIconButton-root,
    .MuiAutocomplete-tag {
      color: #0019a5;

      white-space: nowrap;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }

  .MuiAutocomplete-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    [aria-selected='true'] {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  .MuiInputLabel-root.MuiInputLabel-formControl.MuiFormLabel-root {
    width: 100%;
    transform: none;
    /* padding-right: 3rem; */
    display: flex;
    justify-content: space-between;
    color: #0019a5 !important;

    div:last-child {
      font-size: 1.2rem;
    }
    &.Mui-disabled {
      color: #dadada !important;
    }
    &:not(.Mui-disabled) div:last-child {
      color: #808cd2;
    }
  }

  .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-formControl {
    color: ${(props) => props.theme?.palette?.background?.paper};
  }

  .MuiFilledInput-root .MuiAutocomplete-endAdornment {
    top: 44px;
  }
`;
