import { Link } from '@mui/material';
import { PageContent, PageHeader, IconCart, LandscapeCart, ButtonLink } from 'shared/ui';
import { Breadcrumb } from 'shared/ui/Breadcrumb/Breadcrumb';
import { RetailPropertyStyles } from './RetailProperty.styles';
import { InternationalPhone, Spend, API, Portal, Next, Idea } from 'shared/icons';

import headerImage from 'assets/images/retail-property/header-image.png';
import microLocationInsights from 'assets/images/retail-property/micro-location-insights.png';
import movementInsights from 'assets/images/retail-property/movement-insights.png';
import greyBackground from 'assets/images/retail-property/grey-background.png';
import { links } from 'shared/constants';

export const RetailProperty = () => {
  return (
    <RetailPropertyStyles>
      <PageHeader image={headerImage}>
        <h1>{links.industries.pages.retailProperty.label.toUpperCase()}</h1>
      </PageHeader>
      <Breadcrumb>
        <Link underline="none" color="inherit" href="/">
          O<sub>2</sub> MOTION
        </Link>
        <Link underline="none" color="inherit" href="/industries">
          INDUSTRIES
        </Link>
        <Link underline="none" aria-current="page">
          {links.industries.pages.retailProperty.label.toUpperCase()}
        </Link>
      </Breadcrumb>
      <PageContent className="description">
        <hgroup>
          <h1>Improve planning and performance with insight on the movements of people in and around your locations</h1>
        </hgroup>
        <article>
          <p>
            When attracting new organisations to a site, you can use demographic data and demand analytics to prove the business case. This
            forecast data can help you predict customer volumes, journey modes, route choices and other factors that you can add to your
            evidence base. That way you’ll have the granular insights to plan facilities and understand future requirements.
          </p>
          <br />
          <p>
            You can also plan for peak periods while also being able to understand when, where and who to direct promotions to by merging
            current and historical data.
          </p>
        </article>
      </PageContent>
      <div className="grey-background">
        <PageContent className="promotion">
          <IconCart icon={<Spend />}>
            <h1>Better ROI for Location Planning</h1>
            <p>Benefit from a better understanding of which venue locations will return better ROI.</p>
          </IconCart>
          <IconCart icon={<API />}>
            <h1>Easy to adopt</h1>
            <p>Data can be accessed via API, Blob storage or our Portal, for easy integration into your workflows.</p>
          </IconCart>
          <IconCart icon={<Portal />}>
            <h1>Off-the-shelf</h1>
            <p>Our solutions are readily available to be deployed.</p>
          </IconCart>
          <IconCart icon={<InternationalPhone />}>
            <h1>Large scale</h1>
            <p>
              Captures large numbers of the visitors based on O<sub>2</sub> mobile base.
            </p>
          </IconCart>
          <IconCart icon={<Idea />}>
            <h1>Unique</h1>
            <p>No other solution can deliver the breadth of insights and sample scale.</p>
          </IconCart>
        </PageContent>
      </div>
      <PageContent className="related-products">
        <hgroup>
          <h1>Related Products</h1>
        </hgroup>
        <LandscapeCart>
          <img src={microLocationInsights} />
          <article>
            <h2>{links.products.pages.microLocationInsights.label}</h2>
            <p>Local, location-based insights on visitors so you can improve performance measurement.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.microLocationInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
        </LandscapeCart>
        <LandscapeCart>
          <article>
            <h2>{links.products.pages.movementInsights.label}</h2>
            <p>Low latency journey and location data across the UK and internationally.</p>
            <ButtonLink>
              <a className="quaternary" href={links.products.pages.movementInsights.link}>
                Learn more <Next />
              </a>
            </ButtonLink>
          </article>
          <img src={movementInsights} />
        </LandscapeCart>
      </PageContent>
      <div className="grey-background" style={{ backgroundImage: `url(${greyBackground})` }}>
        <PageContent className="read-case-study">
          <hgroup>
            <h2>
              Learn how accurate location-data insights drive a deeper understanding of crowd-level visitor volumes, profiles and patterns
              for Shaftesbury PLC.
            </h2>
            <ButtonLink>
              <a
                className="secondary"
                rel="noreferrer"
                target="_blank"
                href="https://www.o2.co.uk/documents/456036/1067287/O2_Business_Micro_Location_Insights_0121.055_FINAL.pdf/fe06a775-7d4e-ae23-68ed-050b28d1e419?version=1.0&t=1615800453580"
              >
                Read case study <Next />
              </a>
            </ButtonLink>
          </hgroup>
          <article>
            <p>
              “O<sub>2</sub> Motion’s Micro Location Insights gave us unparalleled information about visitors and potential visitors to our
              clients’ properties. It’s given us a sound basis to optimise our marketing spend to increase visitors, and helped us market
              space to potential occupiers.”
            </p>
            <div>
              <span>Charles Owen</span>
              <br />
              <span>Property Director, Shaftesbury PLC</span>
            </div>
          </article>
        </PageContent>
      </div>
    </RetailPropertyStyles>
  );
};
