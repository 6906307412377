import axios from 'axios';
import { Urls } from '../../shared/constants/urls';
import { UserGroupsDto } from '../models/UserGroupsDto';
import * as authAzure from './authAzure';
import { UserGroups } from '../models/userGroups';
import axiosRetry from 'axios-retry';
import { SharedConsts } from '../../shared/constants/SharedConsts';
import { DataResponseDto } from 'shared/models/DataResponseDto';
import { UserMetadata } from 'shared/models/UserMetadata';

axiosRetry(axios, { retries: 0 });
export namespace AuthApi {
  export const getUserGroups = async (): Promise<UserGroups[]> => {
    const token = await authAzure.getJwtToken();
    return axios
      .get<UserGroupsDto>(Urls.userGroups, {
        headers: {
          Authorization: token,
        },
        'axios-retry': {
          retries: SharedConsts.requestRetryCount,
        },
      })
      .then((r) => r.data.rows);
  };

  function getEmptyUserMetadata(): UserMetadata {
    return {
      userType: 'NA',
      tableauMetadata: {
        hasTableauLicense: false,
        tableauDashboards: [],
      },
    };
  }

  let userMetadata: Promise<UserMetadata>;
  export const getUserMetadata = async (): Promise<UserMetadata> => {
    try {
      if (userMetadata) {
        return userMetadata;
      }
      const token = await authAzure.getJwtToken();
      return (userMetadata = axios
        .get<DataResponseDto<UserMetadata>>(Urls.userType, {
          headers: {
            Authorization: token,
          },
          'axios-retry': {
            retries: SharedConsts.requestRetryCount,
          },
        })
        .then((r) => r.data.rows[0] ?? getEmptyUserMetadata()));
    } catch {
      return getEmptyUserMetadata();
    }
  };
}
