export const EyeSmall = () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.98776 9.57768C9.96361 9.57768 11.5654 7.97594 11.5654 6.00009C11.5654 4.02423 9.96361 2.42249 7.98776 2.42249C6.0119 2.42249 4.41016 4.02423 4.41016 6.00009C4.41016 7.97594 6.0119 9.57768 7.98776 9.57768Z"
      fill="white"
    />
    <path
      d="M16.0007 6.11523V6.05763V6.00003V5.94243V5.88483V5.82723C16.0007 5.79843 16.0007 5.79843 15.9719 5.76963V5.74083C14.4839 2.50883 11.5079 0.563232 7.98791 0.563232C4.46791 0.563232 1.49191 2.50883 0.0327062 5.76963V5.79843C0.0327062 5.82723 0.0327062 5.82723 0.00390625 5.85603V6.20163C0.00390625 6.23043 0.00390625 6.23043 0.0327062 6.25923V6.28803C1.49191 9.49443 4.46791 11.44 7.98791 11.44C11.5079 11.44 14.4839 9.49443 15.9431 6.23043V6.20163C15.9431 6.17283 15.9431 6.17283 15.9719 6.14403C15.9719 6.14403 15.9719 6.11523 16.0007 6.11523ZM7.98791 10.2912C5.01191 10.2912 2.49351 8.68803 1.17831 6.00003C2.49351 3.30883 5.01191 1.70883 7.98791 1.70883C10.9639 1.70883 13.4823 3.30883 14.7975 6.00003C13.4823 8.69123 10.9639 10.2912 7.98791 10.2912Z"
      fill="white"
    />
  </svg>
);
