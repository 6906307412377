import { Reducer, useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { stringComparator } from '../../../shared/lib/stringComparator';
import { ActionReducerParams, treeCheckboxReducer } from '../../../shared/lib/treeCheckboxReducer';
import { LoadingDataStates } from '../../../shared/models/LoadingDataState';
import { TreeStructure } from '../../../shared/models/TreeStructure';
import { OdApi } from '../../lib/OdApi';
import { OdConsts } from '../../lib/OdConsts';
import { DownloadProgressResponse, OdDownloadManager } from '../../lib/OdDownloadManager';
import * as treeTransformer from '../../lib/treeTransformer';
import { DataDownloadItem } from '../../models/DataDownloadItemDto';

export interface MiFormData {
  product: string;
}

export const useDataDownload = () => {
  const [treeData, setData] = useState<TreeStructure<DataDownloadItem>[] | null>(null);
  const [visibleTreeData, setVisibleTreeData] = useState<TreeStructure<DataDownloadItem>[] | null>(null);
  const [loadingCvs, setLoadingCvs] = useState<DownloadProgressResponse>({ progress: 0, status: LoadingDataStates.notStarted });
  const [loadingTreeData, setLoadingTreeData] = useState(false);
  const [formData, setFormData] = useState<MiFormData>({ product: '' });
  const [products, setProducts] = useState<string[]>([]);
  const [selectedSize, setSelectedSize] = useState(0);
  function setProduct(product: string) {
    const newFormData: MiFormData = { ...formData, product };
    setFormData(newFormData);
    setVisibleTreeData(product ? treeData!.filter((e) => e.label === product) : treeData);
  }
  const [checkboxes, toggle] = useReducer<Reducer<Set<TreeStructure<DataDownloadItem>>, ActionReducerParams<DataDownloadItem>>>(
    treeCheckboxReducer,
    new Set<TreeStructure<DataDownloadItem>>(),
  );
  useEffect(() => {
    setSelectedSize(Array.from(checkboxes).reduce((s, e) => (+e.element?.fileSize! || 0) + s, 0));
  }, [checkboxes]);

  function search() {
    setLoadingTreeData(true);
    OdApi.getDataDownload()
      .then((d) => {
        toggle({ name: 'Reset' });
        const preparedTreeData = treeTransformer.groupData(d.rows);
        setData(preparedTreeData);
        setVisibleTreeData(preparedTreeData);
        setLoadingTreeData(false);
        setProducts(preparedTreeData.map((e) => e.label).sort(stringComparator((a) => a)));
      })
      .catch((e) => {
        setLoadingTreeData(false);
        toast.error(OdConsts.dataDownloadErrorLoadingFilesList);
        return Promise.reject(e);
      });
  }

  useEffect(() => {
    search();
    return OdDownloadManager.subscribe(setLoadingCvs);
  }, []);

  function handleDownload() {
    OdDownloadManager.handleDownload(checkboxes);
  }
  return {
    treeData: visibleTreeData,
    formData,
    checkboxes,
    toggle,
    loadingCvs: loadingCvs.status === LoadingDataStates.loading,
    loadingTreeData,
    handleDownload,
    search,
    downloadProgress: loadingCvs.progress,
    selectedSize,
    products,
    setProduct,
  };
};

export type UseMovementInsightResultType = ReturnType<typeof useDataDownload>;
