import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, FormEvent, useReducer, useState, useEffect } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, TextField } from '@mui/material';
import { links, Urls } from 'shared/constants';
import { PageContent } from 'shared/ui';
import { ContactUsStyles } from './ContactUs.styles';

type Field = {
  value: string;
  error?: boolean;
};

type Product = {
  label: string;
  checked: boolean;
};

type FormInputState = {
  name: Field;
  email: Field;
  message: Field;
  products: Record<string, Product>;
};

const initialState: FormInputState = {
  name: { value: '', error: false },
  email: { value: '', error: false },
  message: { value: '', error: false },
  products: {
    audienceInsights: { label: 'Audience Insights', checked: false },
    microLocationInsights: { label: 'Micro Location Insights', checked: false },
    movementInsights: { label: 'Movement Insights', checked: false },
    originDestination: { label: 'Origin Destination Insights', checked: false },
    railInsights: { label: 'Rail Insights', checked: false },
    realTimeLocationInsights: { label: 'Real Time Location Insights', checked: false },
    realTimeTravelInsights: { label: 'Real Time Travel Insights', checked: false },
    roadInsights: { label: 'Road Insights', checked: false },
    spatialInsights: { label: 'Spatial Insights', checked: false },
  },
};

export const ContactUs = () => {
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formInput, setFormInput] = useReducer(
    (state: FormInputState, newState: any): FormInputState => ({ ...state, ...newState }),
    initialState,
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { name, email, message, products } = formInput;
    name.error = !name.value;
    email.error = !email.value;
    message.error = !message.value;

    setFormInput({ name, email, message });

    if (formInput.name.value && formInput.email.value && formInput.message.value) {
      const selectedProducts = Object.values(products)
        .filter((product) => product.checked)
        .flatMap((product) => ' - ' + product.label)
        .join(' <br />');

      const requestBody = {
        name: name.value,
        email: email.value,
        message: message.value,
        selectedProducts,
      };

      axios
        .post(Urls.sendEmail, JSON.stringify(requestBody), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          setFormInput(initialState);
          setShowSuccessMessage(true);
        })
        .catch(() => navigate(links.errorPage.link));
    }
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    const error = !value;
    setFormInput({ [name]: { value, error } });
    setShowSuccessMessage(false);
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const name = event.target.name;
    const label = formInput.products[name].label;
    const products = { ...formInput.products, [name]: { label, checked } };
    setFormInput({ products });
    setShowSuccessMessage(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContactUsStyles>
      <PageContent>
        <h1>Get in touch</h1>
        <p>
          We would love to hear from you. Send us a message using the form below, or write to us at&nbsp;
          <a href="mailto:o2motion@o2.com">o2motion@o2.com</a>
        </p>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel sx={{ marginBottom: 1 }}>Name:</FormLabel>
            <TextField
              required
              name="name"
              label="Enter your full name"
              error={formInput.name.error}
              value={formInput.name.value}
              onChange={handleInput}
              onBlur={handleInput}
              onFocus={handleInput}
              variant="outlined"
              fullWidth
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: 'GrayText' } }}
            />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ marginBottom: 1 }}>Email address:</FormLabel>
            <TextField
              required
              name="email"
              type="email"
              label="Enter your email address"
              error={formInput.email.error}
              value={formInput.email.value}
              onChange={handleInput}
              onBlur={handleInput}
              onFocus={handleInput}
              variant="outlined"
              fullWidth
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: 'GrayText' } }}
            />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ marginBottom: 1 }}>Is your enquiry about a specific product? (Optional)</FormLabel>
            {Object.entries(formInput.products).map(([name, { label, checked }]) => (
              <FormControlLabel key={name} label={label} control={<Checkbox name={name} checked={checked} onChange={handleCheckbox} />} />
            ))}
          </FormControl>
          <FormControl>
            <FormLabel sx={{ marginBottom: 1 }}>How can we help you?</FormLabel>
            <TextField
              required
              name="message"
              label="Enter your message"
              value={formInput.message.value}
              error={formInput.message.error}
              onChange={handleInput}
              onBlur={handleInput}
              onFocus={handleInput}
              variant="outlined"
              fullWidth
              multiline
              minRows={15}
              size="medium"
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15, color: 'GrayText' } }}
            />
          </FormControl>
          <FormControl className="center">
            {showSuccessMessage && (
              <p>Thank you for your enquiry. Your message has been sent to our team and someone will be in touch with you shortly.</p>
            )}
            <Button className="center" type="submit" variant="contained" color="primary">
              Send
            </Button>
          </FormControl>
        </form>
      </PageContent>
    </ContactUsStyles>
  );
};
